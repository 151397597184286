import React, { useEffect, useState } from "react";
import { Container, Table, Button, Form, Alert, Spinner } from "react-bootstrap";
import { getProjects, getBudget, getBudgetItems } from "../services/api";
import Pagination from "../components/Pagination";
import ScrollToTopButton from "../components/ScrollTop";

const RemainingBudgetPage = ({userId}) => {
  const [projects, setProjects] = useState([]);
  const [budgets, setBudgets] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedBudget, setSelectedBudget] = useState("");
  const [remainingBudgetData, setRemainingBudgetData] = useState([]);
  const [remainingBudgeTotal, setRemainingBudgeTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // State for collapse/expand by category:
  const [collapsedCategories, setCollapsedCategories] = useState({});

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    if (selectedProject) {
      fetchRemainingBudget(currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    if (selectedBudget) {
      const selectedBudgetObj = budgets.find(
        (budget) => budget.budget_id === parseInt(selectedBudget)
      );
      if (selectedBudgetObj) {
        setRemainingBudgeTotal(selectedBudgetObj.remaining_budget);
      }
    } else {
      setRemainingBudgeTotal(0);
    }
  }, [selectedBudget, budgets]);

  const fetchProjects = async () => {
    try {
      const projectsData = await getProjects({user_id:userId});
      setProjects(projectsData.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const fetchBudgets = async (projectId) => {
    setSelectedBudget("");
    try {
      const budgetsData = await getBudget(projectId);
      setBudgets(budgetsData.data || []);
    } catch (error) {
      console.error("Error fetching budgets:", error);
    }
  };

  const fetchRemainingBudget = async (page) => {
    if (!selectedProject || !selectedBudget) {
      setAlert("Por favor seleccione un proyecto y un presupuesto.");
      return;
    }
    setLoading(true);
    setAlert(null);
    
    try {
      const queryParams = { remainingBudget: 1, page: page };
      const budgetData = await getBudgetItems(selectedProject, selectedBudget, queryParams);
      setRemainingBudgetData(budgetData.data || []);
      setTotalPages(budgetData.pagination.total_pages);
    } catch (error) {
      console.error("Error fetching remaining budget:", error);
      setAlert("Error al obtener el presupuesto restante.");
    } finally {
      setLoading(false);
    }
  };

  const totalAmount = remainingBudgetData.reduce(
    (sum, item) => sum + ((item.quantity - item.POQuantity) * item.budget_cost),
    0
  );

  // Group remainingBudgetData by category:
  const groupedData = remainingBudgetData.reduce((groups, item) => {
    const category = item.category || "Sin Categoría";
    if (!groups[category]) {
      groups[category] = [];
    }
    groups[category].push(item);
    return groups;
  }, {});

  // Toggle collapse state for a given category
  const handleToggleCategory = (category) => {
    setCollapsedCategories(prev => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  return (
    <Container className="mt-4">
      <h2>Presupuesto Restante</h2>

      {alert && <Alert variant="warning">{alert}</Alert>}

      {/* Project & Budget Selection */}
      <Form className="mb-3">
        <Form.Group>
          <Form.Label>Seleccionar Proyecto</Form.Label>
          <Form.Select
            value={selectedProject}
            onChange={(e) => {
              setSelectedProject(e.target.value);
              fetchBudgets(e.target.value);
            }}
          >
            <option value="">-- Seleccionar Proyecto --</option>
            {projects.map((project) => (
              <option key={project.id} value={project.id}>
                {project.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mt-3">
          <Form.Label>Seleccionar Presupuesto</Form.Label>
          <Form.Select
            value={selectedBudget}
            onChange={(e) => setSelectedBudget(e.target.value)}
            disabled={!selectedProject}
          >
            <option value="">-- Seleccionar Presupuesto --</option>
            {budgets.map((budget) => (
              <option key={budget.budget_id} value={budget.budget_id}>
                {budget.budgetName} (Presupuesto: {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(budget.TotalAmount )}) / Restante: {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(budget.remaining_budget )}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Button className="mt-3" onClick={() => { setCurrentPage(1); fetchRemainingBudget(1); }}>
          Consultar Presupuesto Restante
        </Button>
      </Form>

      {/* Remaining Budget Table */}
      {loading ? (
        <Spinner animation="border" className="mt-4" />
      ) : remainingBudgetData.length > 0 ? (
        <div>
          <Table striped bordered hover className="mt-4 compressedTable no-border-table">
            <thead>
              <tr>
                <th colSpan="3"></th>
                <th colSpan="3">Presupuesto</th>
                <th colSpan="4">Ejecutado</th>
                <th></th>
              </tr>
              <tr>
                <th>Código de Costo</th>
                <th>Concepto</th>
                <th>Unidad</th>
                <th>Cantidad</th>
                <th>Precio</th>
                <th>Importe</th>
                <th>Cantidad</th>
                <th>Costo Promedio</th>
                <th>Importe</th>
                <th>Cantidad Restante</th>
                <th>Importe Restante</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(groupedData).map((category) => {
                const items = groupedData[category];
                const subtotal = items.reduce(
                  (sum, item) => sum + ((item.quantity - item.POQuantity) * item.budget_cost),
                  0
                );
                const isCollapsed = collapsedCategories[category];

                return (
                  <React.Fragment key={category}>
                    {/* Category header with double-click to toggle */}
                    <tr
                      className="category-header"
                      onDoubleClick={() => handleToggleCategory(category)}
                      style={{ backgroundColor: "#f0f0f0", fontWeight: "bold", cursor: "pointer" }}
                    >
                      <td colSpan="11">
                        {isCollapsed ? "► " : "▼ "}
                        {category} 
                        <div style={{ float: "right", color: "grey" }}>
                          restante {new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(subtotal)}
                        </div>
                      </td>
                    </tr>
                    {/* Render detail rows only if not collapsed */}
                    {!isCollapsed &&
                      items.map((item) => {
                        const remainingQuantity = item.quantity - item.POQuantity;
                        return (
                          <tr key={item.budget_item_id}>
                            <td data-label="Código">{item.costCode}</td>
                            <td data-label="Concepto">{item.description}</td>
                            <td data-label="Unidad">{item.unitOfMeasure}</td>
                            <td data-label="Cantidad" className="text-right">
                              {new Intl.NumberFormat("es-MX", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(item.quantity)}
                            </td>
                            <td data-label="P/U presupuesto" className="text-right">
                              {new Intl.NumberFormat("es-MX", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(item.budget_cost)}
                            </td>
                            <td data-label="Importe" className="text-right">
                              {new Intl.NumberFormat("es-MX", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(item.quantity * item.budget_cost)}
                            </td>
                            <td data-label="Cantidad Orden" className="text-right">
                              {new Intl.NumberFormat("es-MX", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(item.POQuantity)}
                            </td>
                            <td data-label="Costo Promedio" className={item.AveragePOCost > item.budget_cost ? "text-right text-danger" : "text-right"}>
                              {new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(item.AveragePOCost)}
                            </td>
                            <td data-label="Importe" className="text-right">
                              {new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(item.POQuantity * item.unit_cost)}
                            </td>
                            <td data-label="Cantidad restante" className={remainingQuantity < 0 ? "text-right text-danger" : "text-right"}>
                              {new Intl.NumberFormat("es-MX", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(remainingQuantity)}
                            </td>
                            <td data-label="Importe restante" className="text-right">
                              {new Intl.NumberFormat("es-MX", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(remainingQuantity * item.budget_cost)}
                            </td>
                          </tr>
                        );
                      })}
                  </React.Fragment>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="10" className="text-end">
                  <strong>SubTotal:</strong>
                </td>
                <td className="text-right">
                  <strong>
                    {new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(totalAmount)}
                  </strong>
                </td>
              </tr>
              <tr>
                <td colSpan="10" className="text-end">
                  <strong>Total:</strong>
                </td>
                <td className="text-right">
                  <strong>
                    {new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(remainingBudgeTotal)}
                  </strong>
                </td>
              </tr>
            </tfoot>
          </Table>
          <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
        </div>
      ) : (
        <p className="mt-4">No hay datos disponibles.</p>
      )}
      <ScrollToTopButton />
    </Container>
  );
};

export default RemainingBudgetPage;
