import React, { useEffect, useState } from "react";
import { Table, Button, Form, Modal, Alert, Container } from "react-bootstrap";
import {
  getSystemConfigurations,
  updateSystemConfiguration,
} from "../services/api";

const SystemConfigurationPage = () => {
  const [configurations, setConfigurations] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentConfig, setCurrentConfig] = useState({
    config_id: null,
    config_key: "",
    config_value: "",
    description: "",
  });
  const [alert, setAlert] = useState(null);

  // Fetch all configurations on page load
  useEffect(() => {
    fetchConfigurations();
  }, []);

  const fetchConfigurations = async () => {
    try {
      const data = await getSystemConfigurations();
      setConfigurations(data.data);
    } catch (error) {
      console.error("Error fetching configurations:", error);
      setAlert({ type: "danger", message: "Error al cargar configuraciones." });
    }
  };

  // Open modal for adding or editing
  const openModal = (config = null) => {
    if (config) {
      setCurrentConfig(config);
    } else {
      setCurrentConfig({ config_id: null, config_key: "", config_value: "", description: "" });
    }
    setShowModal(true);
  };

  // Handle modal form submission
  const handleSubmit = async () => {
    try {
      if (currentConfig.config_id) {
        await updateSystemConfiguration({...currentConfig,config_id:currentConfig.config_id});
        setAlert({ type: "success", message: "Configuración actualizada correctamente." });
      } else {
        setAlert({ type: "danger", message: "Configuración invalida." });
        
      }
      setShowModal(false);
      fetchConfigurations();
    } catch (error) {
      console.error("Error saving configuration:", error);
      setAlert({ type: "danger", message: "Error al guardar configuración." });
    }
  };


  return (
    <Container className="mt-4">
      <h2>Configuración del Sistema</h2>

      {/* Alert Messages */}
      {alert && <Alert variant={alert.type}>{alert.message}</Alert>}


      {/* Configurations Table */}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Clave</th>
            <th>Valor</th>
            <th>Descripción</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {configurations.map((config) => (
            <tr key={config.config_id}>
              <td>{config.config_id}</td>
              <td>{config.config_key}</td>
              <td>{config.config_value}</td>
              <td>{config.description || "-"}</td>
              <td>
                <Button variant="warning" size="sm" className="me-2" onClick={() => openModal(config)}>
                  Editar
                </Button>               
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for Adding/Editing Configurations */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Editar Configuración
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Clave</Form.Label>
              <Form.Control
                type="text"
                value={currentConfig.config_key}
                onChange={(e) => setCurrentConfig({ ...currentConfig, config_key: e.target.value })}
                placeholder="Clave de la configuración"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Valor</Form.Label>
              <Form.Control
                type="text"
                value={currentConfig.config_value}
                onChange={(e) => setCurrentConfig({ ...currentConfig, config_value: e.target.value })}
                placeholder="Valor de la configuración"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                value={currentConfig.description}
                onChange={(e) => setCurrentConfig({ ...currentConfig, description: e.target.value })}
                placeholder="Descripción (opcional)"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Guardar Cambios
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default SystemConfigurationPage;
