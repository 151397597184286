import React from "react";
import { Button } from "react-bootstrap";

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const MAX_VISIBLE_PAGES = 10; // Set maximum visible pages

  const getPaginationItems = () => {
    let pages = [];
    const halfVisible = Math.floor(MAX_VISIBLE_PAGES / 2);

    if (totalPages <= MAX_VISIBLE_PAGES) {
      // Case 1: Show all pages if total pages are within the limit
      pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      // Case 2: If total pages exceed limit, dynamically adjust the range
      let startPage = Math.max(2, currentPage - halfVisible);
      let endPage = Math.min(totalPages - 1, currentPage + halfVisible);

      if (currentPage <= halfVisible) {
        // Shift right when near the beginning
        endPage = MAX_VISIBLE_PAGES - 1;
      } else if (currentPage >= totalPages - halfVisible) {
        // Shift left when near the end
        startPage = totalPages - MAX_VISIBLE_PAGES + 2;
      }

      pages.push(1); // Always include first page

      if (startPage > 2) {
        pages.push("..."); // Show ellipsis when skipping pages
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (endPage < totalPages - 1) {
        pages.push("..."); // Show ellipsis before last page
      }

      pages.push(totalPages); // Always include last page
    }

    return pages;
  };

  return (
    <div className="pagination-container">
      <Button
        variant="outline-primary"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        {"<"}
      </Button>

      {getPaginationItems().map((page, index) =>
        page === "..." ? (
          <span key={index} className="pagination-ellipsis">...</span>
        ) : (
          <Button
            key={index}
            variant={page === currentPage ? "primary" : "outline-primary"}
            onClick={() => onPageChange(page)}
          >
            {page}
          </Button>
        )
      )}

      <Button
        variant="outline-primary"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        {">"}
      </Button>
    </div>
  );
};

export default Pagination;
