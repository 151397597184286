import React from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmationDialog = ({ show, onConfirm, onCancel,title= "Confirmación de Eliminación", message = "¿Estás seguro de que deseas eliminar este elemento?" , confirmButton ="Eliminar"}) => {
  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={onConfirm}>          
          {confirmButton}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationDialog;
