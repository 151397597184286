import React, { useEffect, useState,useRef } from "react";
import { Container, Form, Button, Table, Alert,Row, Col} from "react-bootstrap";
import { Pie ,Bar,Line} from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { getProjects, getCategories, getCashflows,getBudget } from "../services/api";
import {calculateDateRange} from "../utils/dateUtils.js";
import { transactionTypeMap,operationTypeMap } from "../types/CashflowModuleTypes";
import { useAlert } from "../components/Alerts";
import useIsMobile from "../hooks/useIsMobile";

import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    LineController,
    PointElement,
    LineElement,
    
  } from "chart.js";

  

// Register required Chart.js components
ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LineElement,
    BarElement,
    CategoryScale,
    LinearScale,
    ChartDataLabels,
    PointElement,
    LineController,
    
  );

 

const CashflowChart = ({ cashflows ,userId}) => {
  const isMobile = useIsMobile(); // Use the custom hook
  const chartRef = useRef(null);
  
  if (!cashflows || cashflows.length === 0) return null;

  const labels = cashflows.map((flow) =>
    flow.operation_type !== "income" ? flow.category_name : ""
  );
  const data = cashflows.map((flow) =>
    flow.operation_type !== "income" ? parseFloat(flow.totalAmount) : 0
  );

  if (data == 0) return null;
  const colorPalette = ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40", "#C9CBCF"];
  const dynamicColors = cashflows.map((_, index) => colorPalette[index % colorPalette.length]);
  
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Flujo de Efectivo",
        data: data,
        backgroundColor: dynamicColors,
        borderWidth: 1,
        borderColor: "#fff",
      },
    ],
  };

  

 
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: !isMobile,
    plugins: {
        datalabels: {
            color: "black", // Text color
            font: {
              weight: "bold",
              size: 13,
            },
            formatter: (value, context) => {
              const total = context.dataset.data.reduce((sum, val) => ((typeof sum === "number" && !isNaN(sum))? sum:0)  + val, 0);
              const percentage = ((value / total) * 100).toFixed(2);
              if (percentage > 10)
              {
                return `${percentage}%`; // Show percentage
              }
              else{
                return '';
              }
            },
            anchor: "center", // Position text in the center
            align: "center",
          },
      tooltip: {
      
        callbacks: {
          label: (context) => {
            const dataset = context.dataset;
            const currentValue = dataset.data[context.dataIndex];
            const total = dataset.data.reduce((sum, value) => sum + value, 0);
            const percentage = ((currentValue / total) * 100).toFixed(2);
            
          return `${context.label}: ${new Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
          }).format(currentValue)}`
            },  
        },
      },
      legend: { display:!isMobile, position: "right" },
      scales: {
        x: {
          ticks: {
            display:!isMobile, // Show X-axis labels only on larger screens
          },
        },
        y: {
          ticks: {
            display:!isMobile, // Show Y-axis labels only on larger screens
          },
        },
      },
    },
  };

  return <div className="PieChart">
    <Pie ref={chartRef}  data={chartData} options={chartOptions} />
  </div>;
};

const formatCurrencyShort = (value,precision=1) => {
    if (precision > 4)precision = 4;
    if (Math.abs(value) >= 1_000_000) {
      return `${(value / 1_000_000).toFixed(precision)}M`; // Format to 1 decimal and add 'M'
    } else if (Math.abs(value) >= 1_000) {
      return `${(value / 1_000).toFixed(precision)}K`; // Format to 1 decimal and add 'K'
    } else {
      return `${value}`; // If less than 1,000, return as is
    }
  };

const formatShortDate = (dateString) => {
  console.log(dateString);
    const date = new Date(dateString);
    if (!isNaN(date)) {
      return `${date.getMonth() + 1}/${date.getDate()}`; // Format as MM/DD
    }
    return dateString; // Return the original string if parsing fails
};
  

const ExpenseTypeChart= ({ AccountantExpenses,FiscalExpenses}) => {  
  const isMobile = useIsMobile(); // Use the custom hook
  const chartRef = useRef(null);
  if (!AccountantExpenses && !FiscalExpenses) return null;
   
  const chartData = {
    labels: [ "Gastos Fiscales vs Contables"],
    datasets: [
      {
        label: "Fiscales",
        data: [FiscalExpenses],
        backgroundColor: "red",
      },
      {
          label: "Contables",
          data: [AccountantExpenses],
          backgroundColor: "orange",
      },     
    ],
  };

  const chartOptions = {
    indexAxis: "x",
    responsive: true,
    scales: {

      y:{
        beginAtZero:true,
        ticks:{
          callback: (value) => !isMobile?
            `${new Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(value)}`:formatCurrencyShort(value,0)
          }                  
      },
      x: {
        beginAtZero: true,
        
      },
    },
    plugins: {
      
          legend: { display: true, position: "right" },
          datalabels: {
            anchor: "center", // Position label inside the bar
            align: "center",
            color: (context) => {
              // Dynamically change the color based on the dataset
              return context.dataset.backgroundColor === "red"
                ? "#fff" // White for Gasto Actual
                : "#000"; // Black for other labels
            },
            font: {
              weight: "bold",
              size: 10,
            },
             formatter: (value) => formatCurrencyShort(value),
          },
      
      legend: { display: isMobile, position: isMobile?"bottom":"right" },
    },
  };

  return (
    <div className="bar-chart">
      <Bar ref={chartRef} data={chartData} options={chartOptions} />
    </div>
  );
};

// Gauge-like Stacked Bar Chart Component
const GaugeChart = ({ approvedBudget, currentExpenses, currentInflow}) => {
  const isMobile = useIsMobile(); // Use the custom hook
  const chartRef = useRef(null);


    const chartData = {
      labels: ["Presupuesto Aprobado vs Gasto Actual"],
      datasets: [
        {
          label: "Gasto Actual",
          data: [currentExpenses],
          backgroundColor: "red",
        },
        {
            label: "Ingresos",
            data: [currentInflow],
            backgroundColor: "orange",
        },
        {
            label: "Presupuesto",
            data: [approvedBudget],
            backgroundColor: "blue",
        },
        {
          label: "Restante",
          data: [(approvedBudget || currentExpenses) - currentExpenses],
          backgroundColor: "green",
        },
      ],
    };
  
    const chartOptions = {
      indexAxis: "x",
      responsive: true,
      scales: {
        y:
        {
          beginAtZero: true,
          ticks: {            
            callback: (value) => !isMobile?
            `${new Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(value)}`:formatCurrencyShort(value,0),
          }
        },
        x: {
          beginAtZero: true,          
        },
      },
      plugins: {
        
            legend: { display: true, position: "right" },
            datalabels: {
              anchor: "center", // Position label inside the bar
              align: "center",
              color: (context) => {
                // Dynamically change the color based on the dataset
                return context.dataset.backgroundColor === "red"
                  ? "#fff" // White for Gasto Actual
                  : "#000"; // Black for other labels
              },
              font: {
                weight: "bold",
                size: 10,
              },
               formatter: (value) => formatCurrencyShort(value),
            },
        
        legend: { display: true, position: isMobile?"bottom":"right" },
      },
    };
  
    return (
      <div className="bar-chart">
        <Bar data={chartData} options={chartOptions} ref={chartRef}/>
      </div>
    );
  };

  const CashflowByMonthChart = ({ budget, cashflow }) => {
    const isMobile = useIsMobile(); // Use the custom hook
    const chartRef = useRef(null);

    // Step 1: Combine and Sort Unique Dates
    const allDates = [
      ...new Set([
        ...cashflow.map((entry) => entry.month),
        ...budget.map((entry) => entry.month),
      ]),
    ]
      .filter((date) => date) // Ensure no null/undefined dates
      .sort((a, b) => new Date(`${a}-01`) - new Date(`${b}-01`));


    const desktopLabels = allDates.map((date) => {
      const [year, month] = date.split("-");
      return `${new Date(year, month - 1).toLocaleString("es-MX", {
        month: "long",
      })} ${year}`; // Full month name for desktop
    });
    
    const mobileLabels = allDates.map((date) => {
      const [year, month] = date.split("-");
      return `${month}/'${year.slice(-2)}`; // MM/YYYY format for mobile
    });

    
    const labels = isMobile ? mobileLabels : desktopLabels;

    // Step 2: Initialize Cumulative Sums
    let cashflowSum = 0;
    let budgetSum = 0;
    let incomeSum = 0;
    let cashFlowSum = 0;
  
    const barDataExpenses = allDates.map((date) => {
        // Filter all relevant entries for the current date
        const relevantExpenses = cashflow.filter(
          (entry) => entry.month === date && entry.operation_type === "expense"
        );
        // Sum up the entry_count values for this date
        const monthlyExpenseSum = relevantExpenses.reduce(
          (sum, entry) => sum - parseFloat(entry.entry_count),
          0
        );
        cashflowSum += monthlyExpenseSum; // Update cumulative expense sum
        return cashflowSum; // Return cumulative value
      });
      
      const barDataIncomes = allDates.map((date) => {
        // Filter all relevant entries for the current date
        const relevantIncomes = cashflow.filter(
          (entry) => entry.month === date && entry.operation_type === "income"
        );
        // Sum up the entry_count values for this date
        const monthlyIncomeSum = relevantIncomes.reduce(
          (sum, entry) => sum + parseFloat(entry.entry_count),
          0
        );
        incomeSum += monthlyIncomeSum; // Update cumulative income sum
        return incomeSum; // Return cumulative value
      });
      
   
      
  
    const lineData = allDates.map((date) => {
      const relevantBudget = budget.find((entry) => entry.month === date);
      if (relevantBudget) {
        budgetSum += parseFloat(relevantBudget.entry_count);
      }
      return budgetSum; // Return cumulative budget
    });

    const linearCashFlow = allDates.map((date) => {
        // Filter all relevant cashflow entries for the current date
        const relevantCashflows = cashflow.filter((entry) => entry.month === date);
      
        // Process each entry for the current date
        relevantCashflows.forEach((entry) => {
          if (entry.operation_type === "income") {
            cashFlowSum += parseFloat(entry.entry_count); // Subtract income
          } else if (entry.operation_type === "expense") {
            cashFlowSum -= parseFloat(entry.entry_count); // Add expense
          }
        });
      
        return cashFlowSum; // Return cumulative cashflow for the current date
      });
      
   
  
    // Step 5: Define Chart Data and Options
    const chartData = {
      labels, // Month-Year labels
      datasets: [
        {
          label: "Gastos por Mes",
          data: barDataExpenses,
          type: "bar",
          backgroundColor: "#FF6384", // Bar color
          yAxisID: "y",
          stack: "cashflow",
          categoryPercentage: 0.6, // Reduce category width
        barPercentage: 0.8,
          
        },
        {
            label: "Ingresos por Mes",
            data: barDataIncomes,
            type: "bar",
            backgroundColor: "#36A2EB", // Bar color for incomes
            stack: "cashflow", // Stack group name
            yAxisID: "y",
            stack: "cashflow",
            categoryPercentage: 0.6, // Reduce category width
        barPercentage: 0.8,
          },
        {
          label: "Presupuesto Acumulados",
          data: lineData,
          type: "line",
          borderColor: "#36A2EB", // Line color
          borderWidth: 2,
          fill: false,
          yAxisID: "y",
          categoryPercentage: 0.6, // Reduce category width
        barPercentage: 0.8,
        },
        {
            label: "Cashflow",
            data: linearCashFlow,
            type: "line",
            borderColor: "green", // Line color for budget
            borderWidth: 2,
            fill: false,
            yAxisID: "y2",
            categoryPercentage: 0.6, // Reduce category width
           barPercentage: 0.8,
          },
      ],
    };
  
    const chartOptions = {
      responsive: true,
      plugins: {
        datalabels: {
          anchor: "end", // Position label inside the bar
          offset: "70",
          align: "center",
          color: "#000",
          font: {
            weight: "light",
            size: 10,
          },
          formatter: (value) => '',// formatCurrencyShort(value),
        },
        tooltip: {
          callbacks: {
            label: (context) =>
              `${context.dataset.label}: ${new Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(context.raw)}`,
          },
        },
        legend: { display: true, position: "top" },
      },
     
      scales: {
        y: {
          beginAtZero: true,
          position: "right", // Right axis for line chart
          stacked: true, 
          ticks: {
            callback: (value) => !isMobile?
              `${new Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(value)}`:formatCurrencyShort(value,0),
          },
        },

        y2: {
          beginAtZero: true,
          position: "left", // Right axis for line chart
          stacked: true, 
          ticks: {
            callback: (value) => !isMobile?
              `${new Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(value)}`:formatCurrencyShort(value,0),
          },
        },

        x: {
          stacked: true, 
          ticks: {            
            callback: (value, index) => labels[index] || "", // Use preformatted labels
            maxRotation: isMobile ? 0 : 45,
            minRotation: 0,
          },
        },
      },
    };
  
    return (
      <div className="bar-chart total-cash-flow">
        <Bar data={chartData} options={chartOptions} ref={chartRef} />
      </div>
    );
  };
  
  

  const CashflowByProjectChart = ({ cashflow }) => {
    const chartRef = useRef(null);
  
    const processData = (data) => {
      const groupedData = {};
  
      data.forEach(({ project_id, operation_type, entry_count, project_name }) => {
        if (!groupedData[project_id]) {
          groupedData[project_id] = { income: 0, expense: 0, project_name: "" };
        }
        groupedData[project_id][operation_type] += parseFloat(entry_count) || 0;
        groupedData[project_id]["project_name"] = project_name || "";
      });
  
      return groupedData;
    };
  
    const groupedData = processData(cashflow);
    const projectIds = Object.keys(groupedData);
  
    // Calculate total income and expenses
    const totalIncome = projectIds.reduce((sum, id) => sum + groupedData[id].income, 0);
    const totalExpense = projectIds.reduce((sum, id) => sum + groupedData[id].expense, 0);
  
    // Filter significant projects (above 10%)
    const threshold = 0.1; // 10%
    const significantProjects = [];
    let othersIncome = 0;
    let othersExpense = 0;
  
    projectIds.forEach((id) => {
      const incomePercentage = (groupedData[id].income / totalIncome) || 0;
      const expensePercentage = (groupedData[id].expense / totalExpense) || 0;
  
      if (incomePercentage > threshold || expensePercentage > threshold) {
        significantProjects.push({
          label: groupedData[id]["project_name"],
          data: [groupedData[id].income, groupedData[id].expense],
          backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
            Math.random() * 255
          )}, ${Math.floor(Math.random() * 255)}, 0.7)`,
          borderWidth: 1,
        });
      } else {
        othersIncome += groupedData[id].income;
        othersExpense += groupedData[id].expense;
      }
    });
  
    // Add "Others" category if applicable
    if (othersIncome > 0 || othersExpense > 0) {
      significantProjects.push({
        label: "Otros",
        data: [othersIncome, othersExpense],
        backgroundColor: "rgba(128, 128, 128, 0.7)", // Grey color for "Others"
        borderWidth: 1,
      });
    }
  
    const chartData = {
      labels: ["Ingresos", "Gastos"],
      datasets: significantProjects,
    };
  
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false, // Allows height adjustments
      plugins: {
        legend: { position: "bottom" },
        datalabels: {
          anchor: "end",
          align: "top",
          formatter: (value) =>
            value > 10000000 ? `$${value.toLocaleString()}` : "",
          font: { weight: "bold" },
        },
        tooltip: {
          callbacks: {
            footer: (tooltipItem) => {
              const dataset = chartData.datasets[tooltipItem[0].datasetIndex];
              const value = dataset.data[tooltipItem[0].dataIndex];
              const total = tooltipItem[0].dataIndex === 0 ? totalIncome : totalExpense;
              const percentage = total ? ((value / total) * 100).toFixed(2) : 0;
              return `$${value.toLocaleString()} (${percentage}%)`;
            },
          },
        },
      },
      scales: {
        x: {
          stacked: true,
          barPercentage: 1, // Increase bar width
          categoryPercentage: 0.6, // Reduce spacing
          
          
        },
        y: {
          stacked: true,
          suggestedMax: totalIncome * 1.2, // Dynamically scale y-axis
          ticks: { stepSize: Math.round(totalIncome / 5) },
        },
      },
    };
  
    return (
      <div style={{ width: "100%", height: "900px", margin: "auto" }}>
        <h2>Flujo de Efectivo por Proyecto</h2>
        <p style={{ textAlign: "center", fontWeight: "bold" }}>
          Ingreso Total: ${totalIncome.toLocaleString()} | Gasto Total: ${totalExpense.toLocaleString()}
        </p>
        <Bar data={chartData} options={chartOptions} />
      </div>
    );
  };
  
  

  const ExpensesByMonthChart = ({ cashflows }) => {
    const isMobile = useIsMobile(); // Use the custom hook
    const chartRef = useRef(null);
    if (!cashflows || cashflows.length === 0) return null;
  
    // Prepare monthly expense data
    const expensesByMonth = cashflows.reduce((acc, flow) => {
      if (flow.operation_type === "expense") {
        const [year, month] = flow.month.split("-"); 
        const formattedMonth = new Date(year, month - 1).toLocaleString("default", {
          month: "long",
          year: "numeric",
        });
        
        acc[formattedMonth] = (acc[formattedMonth] || 0) + parseFloat(flow.entry_count);
      }
      return acc;
    }, {});
  
    
    const labels = Object.keys(expensesByMonth); // Months
    const data = Object.values(expensesByMonth); // Totals
    
    const monthNameToNumber = {
      January: "01",
      February: "02",
      March: "03",
      April: "04",
      May: "05",
      June: "06",
      July: "07",
      August: "08",
      September: "09",
      October: "10",
      November: "11",
      December: "12",
    };

    const desktopLabels = labels.map((date) => {
      const [monthString,year] = date.split(" ");
      const month = monthNameToNumber[monthString];
      return `${new Date(year, month - 1).toLocaleString("es-ES", {
        month: "long",
      })} ${year}`;
    });

    const mobileLabels = labels.map((date) => {
      const [monthName, year] = date.split(" ");
      const month = monthNameToNumber[monthName];
      const shortYear = year.slice(-2); // Get the last 2 digits of the year
      return `${month}/${shortYear}`; // Format as MM/YY
    });

    const chartData = {
      labels,
      datasets: [
        {
          label: "Gastos por Mes",
          data,
          backgroundColor: "#36A2EB",
        },
      ],
    };
  
    const chartOptions = {
      responsive: true,
      plugins: {
        tooltip: {
          callbacks: {
            label: (context) =>
              `${context.label}: ${new Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(context.raw)}`,
          },
        },
        datalabels: {
            anchor: "center", // Position label inside the bar
            align: "center",
            color: (context) => {
              // Dynamically change the color based on the dataset
              return context.dataset.backgroundColor === "red" 
                ? "#fff" // White for Gasto Actual
                : "#000"; // Black for other labels
            },
            font: {
              weight: "bold",
              size: 10,
            },
             formatter: (value) => formatCurrencyShort(value),
          },
      
      },
      scales: {
        x: {
          beginAtZero: true,
          grid: {
            display: true, // Optionally hide the grid lines
          },
          barThickness: 3, // Set a fixed thickness for the bars
          maxBarThickness: 20, // Limit the maximum bar thickness
          categoryPercentage: 0.1, // Adjust space between bars (lower = more space)
          barPercentage: 0.9, // Adjust individual bar width within its category
          ticks: {
            callback: (value,index) => !isMobile?
            desktopLabels[index]:mobileLabels[index]
          },
        },

        y: {
          beginAtZero: true,
          ticks: {
            callback: (value) => !isMobile?
            `${new Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(value)}`:formatCurrencyShort(value,0)
          },
        },
      },
    };
  
    return  <div className="bar-chart">
              <Bar data={chartData} options={chartOptions}  ref={chartRef}/>
            </div>
  };

const CashFlowReportsPage = ({userId}) => {
  const { alert, showAlert, clearAlert } = useAlert();
  const [cashflows, setCashflows] = useState([]);  
  const [projects, setProjects] = useState([]);
  const [categories, setCategories] = useState([]);

  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [dateFilter, setDateFilter] = useState("currentMonth"); // Default filter type
  const [transactionType, setTransactionType] = useState("");
  const [operationType, setOperationType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [approvedBudget,setApprovedBudget] = useState(0); // Example approved budget
  const [currentExpenses, setCurrentExpenses] = useState(0);
  const [currentInflow, setCurrentInflow] = useState(0);
  const [budget,setBudget] = useState([]);
  const [projectOverall,setProjectOverall] = useState([]);
  const [budgetByMonth,setBudgetByMonth] = useState([]);
  const [cashFlowByMonth,setCashFlowByMonth] = useState([]);
  const [fiscalExpenses,setFiscalExpenses] = useState(0);
  const [cashExpenses,setCashExpenses] = useState(0);
  
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const projectsData = await getProjects({user_id:userId});
        const categoriesData = await getCategories();
        setProjects(projectsData.data || []);
        setCategories(categoriesData.data || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching initial data:", error);
        showAlert("Error al obtener los proyectos y categorías.");
      }
    };
    fetchInitialData();
  }, []);




  const handleDateFilterChange = (e) => {
    const filter = e.target.value;
    setDateFilter(filter);

    const { start, end } = calculateDateRange(filter);
    setStartDate(start);
    setEndDate(end);
  };




  const fetchFilteredCashflows = async () => {
    if (!selectedProjectId) {
      showAlert("Por favor, selecciona un proyecto antes de aplicar los filtros.");
      return;
    }

    // Clear the alert message if validation passes
    showAlert(null);
    try {
      const queryParams = {
        project_id: selectedProjectId,
        category_id: selectedCategoryId,
        transaction_type: transactionType,
        operation_type: operationType,
        start_date: startDate,
        end_date: endDate,
        summarize: 1,
        status:['valid']
      };

      Object.keys(queryParams).forEach(
        (key) => queryParams[key] === "" && delete queryParams[key]
      );
      

      
      const response = await getCashflows(queryParams);
      
      if (response.length <= 0 )  {
          showAlert("Projecto sin datos",'warning');
          setBudgetByMonth([]);
          setCashFlowByMonth([]);
          setCashflows ([]);
          setBudget([]);
          setApprovedBudget(0);
          return;
      } 
       const queryParamsMontlyBudget = {
          budget_id: 10,
          project_id:selectedProjectId,
          transaction_type: transactionType,
          operation_type: operationType,
          start_date: startDate,
          end_date: endDate,
          monthlyBudget: 1,
          overall_budget: 1,
        };
        
  
        Object.keys(queryParamsMontlyBudget).forEach(
          (key) => queryParamsMontlyBudget[key] === "" && delete queryParamsMontlyBudget[key]
        );
        
  
        try{
            const { cashflows: cfByMonth, budget: monthlyBudgetResponse ,projectCashflows:projectOverallData} = await getCashflows(queryParamsMontlyBudget);                        
            setBudgetByMonth(monthlyBudgetResponse || []);
            setProjectOverall(projectOverallData || [])
            setCashFlowByMonth( cfByMonth || []);
        } catch (err) {
            setError("Failed to fetch budget");
            setLoading(false);
        }

      
    try{
        const budgetData = await getBudget(selectedProjectId); // Call the Lambda function        
        setBudget(budgetData.data); 
        setApprovedBudget(budgetData[0]?.TotalAmount || 0);
    } catch (err) {
            setError("Failed to fetch budget");
            setLoading(false);
        }

      //setCashflows(response || []);
      
      const filteredData = response || [];
      setCashflows(filteredData);

      const fiscalExpenses = filteredData.reduce(
        (sum, flow) =>
          flow.operation_type === "expense" && flow.transaction_type === "fiscal" ? sum + parseFloat(flow.totalAmount) : sum,0
        
      );
      
      const accountantExpenses = filteredData.reduce(
        (sum, flow) =>
          flow.operation_type === "expense" && flow.transaction_type === "cash" ? sum + parseFloat(flow.totalAmount) : sum,0
        
      );
      
      setFiscalExpenses (fiscalExpenses);
      setCashExpenses (accountantExpenses);

      const expenses = filteredData.reduce(
        (sum, flow) =>
          flow.operation_type === "expense" ? sum + parseFloat(flow.totalAmount) : sum,0
        
      );
      setCurrentExpenses(expenses);
      
      const incomes = filteredData.reduce(
        (sum, flow) =>
          flow.operation_type === "income" ? sum + parseFloat(flow.totalAmount) : sum,
        0
      );

      setCurrentInflow(incomes);

      showAlert(null);
    } catch (error) {
      console.error("Error fetching filtered cashflows:", error);
      showAlert("Error al obtener los datos de flujo de efectivo.");
      setCashflows([]);
    }
  };


  const fetchBudgetByMonth = async () => {
    try {
      const queryParams = {
        budget_id: 10,
        transaction_type: transactionType,
        operation_type: operationType,
        start_date: startDate,
        end_date: endDate,
        monthlyBudget: 1,
      };

      Object.keys(queryParams).forEach(
        (key) => queryParams[key] === "" && delete queryParams[key]
      );
      

      
      const response = await getCashflows(queryParams);

      //setCashflows(response || []);
      
      const filteredData = response || [];
      setBudgetByMonth(filteredData);    

      showAlert(null);
    } catch (error) {
      console.error("Error fetching filtered cashflows:", error);
      showAlert("Error al obtener los datos de flujo de efectivo.");
      setBudgetByMonth([]);
    }
  };


  // Handle loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Handle error state
  if (error) {
    return <div>{error}</div>;
  }
 

  return (
    <Container className="mt-4">
      <h1>Reportes de Flujo de Efectivo</h1>
        
       {/* Alert */}
       {alert.message && (
        <Alert variant={alert.variant} onClose={clearAlert} dismissible>
          {alert.message}
        </Alert>
      )}
  
      <Form className="mb-4">
        <Form.Group className="mb-3">
          <Form.Label>Filtrar por Obra</Form.Label>
          <Form.Select value={selectedProjectId} onChange={(e) => setSelectedProjectId(e.target.value)}>
            <option value="">-- Seleccionar Proyecto --</option>
            {projects.map((project) => (
              <option key={project.id} value={project.id}>{project.name}</option>
            ))}
          </Form.Select>
        </Form.Group>
  
        <Form.Group className="mb-3">
          <Form.Label>Filtrar por Categoría</Form.Label>
          <Form.Select value={selectedCategoryId} onChange={(e) => setSelectedCategoryId(e.target.value)}>
            <option value="">-- Seleccionar Categoría --</option>
            {categories.map((category) => (
              <option key={category.category_id} value={category.category_id}>{category.category_name}</option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
        <Form.Label>Filtrar por Tipo de Transacción</Form.Label>
        <div>
            <Form.Check
            inline
            type="radio"
            id="transaction-type-fiscal"
            name="transaction_type"
            label="Fiscal"
            value="fiscal"
            checked={transactionType === "fiscal"}
            onChange={(e) => setTransactionType(e.target.value)}
            />
            <Form.Check
            inline
            type="radio"
            id="transaction-type-cash"
            name="transaction_type"
            label="Efectivo"
            value="cash"
            checked={transactionType === "cash"}
            onChange={(e) => setTransactionType(e.target.value)}
            />
            <Form.Check
            inline
            type="radio"
            id="transaction-type-all"
            name="transaction_type"
            label="Todos"
            value=""
            checked={transactionType === ""}
            onChange={(e) => setTransactionType(e.target.value)}
            />
        </div>
        </Form.Group>
  
        {/* Date Filters */}
        <Form.Group className="mb-3">
          <Form.Label>Filtrar por Fecha</Form.Label>
          <Form.Select value={dateFilter} onChange={handleDateFilterChange}>
            <option value="custom">Rango Personalizado</option>
            <option value="currentMonth">Mes Actual</option>
            <option value="lastMonth">Mes Anterior</option>
            <option value="thisYear">Este Año</option>
          </Form.Select>
        </Form.Group>
  
        {dateFilter === "custom" && (
          <>
            <Form.Group className="mb-3">
              <Form.Label>Fecha de Inicio</Form.Label>
              <Form.Control
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Form.Group>
  
            <Form.Group className="mb-3">
              <Form.Label>Fecha de Fin</Form.Label>
              <Form.Control
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Form.Group>
          </>
        )}
  
        <Button variant="primary" onClick={fetchFilteredCashflows}>
          Aplicar Filtros
        </Button>
      </Form>
  
      {projectOverall.length > 0 && (
        <>
          <div className="mb-5">
            <h3>Gráfico Concentrado de Proyectos</h3>
            <div className="graph-container">
            
            <CashflowByProjectChart cashflow={projectOverall} />
            </div>
          </div>
          </>
      )}
      {cashflows.length > 0 && (
        <>
          <div className="mb-5">
            <h3>Gráfico de Flujo de Efectivo</h3>
            <div className="graph-container">
            <CashflowChart cashflows={cashflows} />
            </div>
          </div>
  
          <h3 className="mt-4">Detalles de Flujo de Efectivo</h3>
          <Table bordered hover id="cashflow-report">
            <thead>
              <tr>
                <th>Proyecto</th>
                <th>Categoría</th>
                <th>Cuenta</th>
                <th>Tipo de Transacción</th>
                <th>Tipo de Operación</th>
                <th>Monto</th>
              </tr>
            </thead>
            <tbody>
              {cashflows.map((flow, index) => (
                <tr key={index}>
                  <td data-label="Proyecto">{flow.project_name}</td>
                  <td data-label="Categoría">{flow.category_name}</td>
                  <td data-label="Cuenta">{flow.bank_account}</td>
                  <td data-label="Transaccion">{transactionTypeMap[flow.transaction_type] || "N/A"}</td>
                  <td data-label="Operacion">{operationTypeMap[flow.operation_type] || "N/A"}</td>                  
                  <td data-label="Monto" className="text-right">
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                    }).format(flow.totalAmount)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="mb-5">
            <h3>{  (dateFilter === "currentMonth" ? "Gastos fiscales vs contables del Mes" : "Gastos fiscales vs contables")}</h3>
            <ExpenseTypeChart              
              AccountantExpenses={cashExpenses}
              FiscalExpenses={fiscalExpenses}
              dateFilter={dateFilter }
             
            />
          </div>
          <div className="mb-5">
            <h3> {(dateFilter === "currentMonth" ? "Presupuesto aprobado vs gastos Actuales del Mes" : "Presupuesto aprobado vs gastos Actuales")}</h3>
            <GaugeChart
              approvedBudget={approvedBudget}
              currentExpenses={currentExpenses}
              currentInflow={currentInflow}
            />
          </div>
  
          <div className="mb-5">
            <h3>Flujo de Efectivo del Proyecto</h3>
            <CashflowByMonthChart budget={budgetByMonth} cashflow={cashFlowByMonth} />
          </div>

          <div className="mb-5">
            {cashFlowByMonth.length > 0 && (
            <>
            <h3>Gastos por Mes</h3>
            <ExpensesByMonthChart cashflows={cashFlowByMonth} />
            
            </>
            )}
        </div>
        </>
      )}

    </Container>
  );
  
};

export default CashFlowReportsPage;
