import { useState } from "react";

const useBankValidation = () => {
  const [isValidClabe, setIsValidClabe] = useState(null);

  // Lista de bancos con sus códigos y nombres
  const bankData = {
    "002": { name: "Banamex", logo: require("../assets/bbva.png") },
    "012": { name: "BBVA", logo: require("../assets/bbva.png") },
    "014": { name: "Santander", logo: require("../assets/bbva.png") },
    "021": { name: "HSBC", logo: require("../assets/bbva.png") },
    "036": { name: "Inbursa", logo: require("../assets/bbva.png") },
  };

  // Algoritmo de validación de CLABE
  const validateClabe = (clabe) => {
    if (!/^\d{18}$/.test(clabe)) return false; // Verifica que tenga 18 dígitos

    const weights = [3, 7, 1]; // Pesos usados en el cálculo del dígito de verificación
    let sum = 0;

    for (let i = 0; i < 17; i++) {
      sum += (parseInt(clabe[i]) * weights[i % 3]) % 10;
    }

    const checkDigit = (10 - (sum % 10)) % 10;
    const isValid = checkDigit === parseInt(clabe[17]);

    setIsValidClabe(isValid);
    return isValid;
  };

  // Obtener el nombre y logo del banco
  const getBankFromClabe = (clabe) => {
    if (clabe.length < 3) return { name: "Desconocido", logo: null };
    return bankData[clabe.substring(0, 3)] || { name: "Banco no reconocido", logo: null };
  };

  return { validateClabe, getBankFromClabe, isValidClabe };
};

export default useBankValidation;
