import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Form, Container, Alert } from "react-bootstrap";
import { useAlert } from "../components/Alerts";
import { formatDateToISO } from "../utils/dateUtils";
import Pagination from "../components/Pagination";

import {
  getDispersionRules,
  getCategories,
  getProjects,
  saveDispersionRule,
  deleteDispersionRule,
  calculateDispersion,
  applyDispersion,
  deleteDispersion,
  getDispersionLogs, // ✅ New API function
  getDispersionCycles,
} from "../services/api";

const DispersionPage = ({ userId }) => {
  const [rules, setRules] = useState([]);
  const [categories, setCategories] = useState([]);
  const [projects, setProjects] = useState([]);
  const [dispersionLogs, setDispersionLogs] = useState([]); // ✅ New state for logs
  const [showModal, setShowModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedRule, setSelectedRule] = useState(null);
  const [calculatedDispersion, setCalculatedDispersion] = useState(0);
  const { alert, showAlert, clearAlert } = useAlert();
  const [dispersionContainers, setDispersionContainers] = useState([]);  
  const [calculatedDispersionByProject, setCalculatedDispersionByProject] = useState(0);
  const [selectedContainer, setSelectedContainer] = useState(null);
  /*Pagination*/
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    
    const [detailedCurrentPage, setDetailedCurrentPage] = useState(1);    
    const [detailedTotalPages,setDetailedTotalPages] = useState(1)
    
  const today = formatDateToISO(new Date());
  const [formData, setFormData] = useState({
    general_project_id: "",
    target_project_id: "",
    dispersion_type: "percentage",
    dispersion_value: "",
  });

  const [dateFilter, setDateFilter] = useState({
    start_date: today,
    end_date: today,
  });



  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (showDetailsModal && selectedContainer) {
      handleViewDetails(selectedContainer,detailedCurrentPage);
    }
  }, [detailedCurrentPage, showDetailsModal]);


  const fetchData = async (page) => {
    try {
      const [rulesData, accountsData, projectsData,dispersionCycleData] = await Promise.all([
        getDispersionRules(),
        getCategories(),
        getProjects({user_id:userId}),       
        getDispersionCycles(),
      ]);
      setRules(rulesData.data || []);
      setCategories(accountsData.data || []);
      setProjects(projectsData.data || []);      
      setDispersionContainers(dispersionCycleData.data || [])     
      setTotalPages(dispersionCycleData.pagination.total_pages);
    } catch (error) {
      console.error("Error fetching data:", error);
      showAlert("Error al obtener los datos.", "danger");
    }
  };

  const handleSubmit = async () => {
    try {
      if (selectedRule) {
        await saveDispersionRule(selectedRule.rule_id, formData);
        showAlert("Regla de dispersión actualizada.","success");
      } else {
        await saveDispersionRule(null,formData);
        showAlert("Nueva regla de dispersión agregada.","success");
      }
      setShowModal(false);
      fetchData();
    } catch (error) {
      console.error("Error saving rule:", error);
      showAlert("Error al guardar la regla.","danger");
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteDispersionRule(id);
      setRules(rules.filter((rule) => rule.rule_id !== id));
      showAlert("Regla de dispersión eliminada.","success");
    } catch (error) {
      console.error("Error deleting rule:", error);
      showAlert("Error al eliminar la regla.","danger");
    }
  };

  const handleShowModal = (rule = null) => {
    setSelectedRule(rule);
    setFormData(
      rule || {
        general_project_id: "",
        target_project_id: "",
        dispersion_type: "percentage",
        dispersion_value: "",
      }
    );
    setShowModal(true);
  };

  const handleCalculateDispersion = async (accountId) => {
    if (!dateFilter.start_date || !dateFilter.end_date) {
      showAlert("Por favor, seleccione un rango de fechas.", "warning");
      return;
    }
    try {
      const dispersionAmount = await calculateDispersion({start_date:dateFilter.start_date,end_date:dateFilter.end_date,project_id:16});
      setCalculatedDispersion(dispersionAmount.data[0].Total || 0);
      setCalculatedDispersionByProject(dispersionAmount.data[0].TotalByProject || 0);
      showAlert("Cálculo de dispersión completado.","success");
    } catch (error) {
      console.error("Error calculating dispersion:", error);
      showAlert("Error al calcular la dispersión.","danger");
    }
  };

  const handleDeleteContainer = async (cycle_id) => {
    try {
      const queryParams = {cycle_id:cycle_id,user_id: userId } 
      await deleteDispersion(queryParams);
      setDispersionContainers(dispersionContainers.filter((container) => container.cycle_id !== cycle_id));
      showAlert("Proceso de dispersión eliminado.", "success");
    } catch (error) {
      console.error("Error deleting dispersion container:", error);
      showAlert("Error al eliminar el proceso de dispersión.", "danger");
    }
  };

  const handleApplyDispersion = async (ruleId) => {
    if (!dateFilter.start_date || !dateFilter.end_date) {
      showAlert("Por favor, seleccione un rango de fechas antes de aplicar la dispersión.", "warning");
      return;
    }
    try {
      await applyDispersion({
        start_date:  dateFilter.start_date,
        end_date:  dateFilter.end_date,
        rule_id: ruleId,
        user_id: parseInt(userId),
      });
      showAlert("Dispersión aplicada exitosamente.", "success");
      fetchData(); // ✅ Refresh logs after applying dispersion
    } catch (error) {
      if (error.status === 412){
          showAlert("No hay elementos nuevos para dispersión.", "warning");          
      }
      else{
        console.error("Error applying dispersion:", error);
        showAlert("Error al aplicar la dispersión.", "danger");
      }
    }
  };

  const dispersionTypes = {
    percentage: "Porcentaje",
    fixed: "Fijo",
    even: "Distribuido",
  };
  const handleViewDetails = async (container,page = 1) => {
    const queryParams = {
      cycle_id:container.cycle_id,
      page : page
    };

    try {
      const details = await getDispersionLogs(queryParams);
      setDispersionLogs(details.data);     
      setSelectedContainer(container);
      setDetailedTotalPages(details.pagination.total_pages);
      setShowDetailsModal(true);
    } catch (error) {
      console.error("Error fetching dispersion details:", error);
      showAlert("Error al obtener los detalles de la dispersión.", "danger");
    }
  };

  
  return (
    <Container className="mt-4">
      <h2>Gestión de Dispersión</h2>
      {alert.message && (
        <Alert variant={alert.variant} onClose={clearAlert} dismissible>
          {alert.message}
        </Alert>
      )}

      <Button variant="primary" onClick={() => setShowModal(true)}>
        Agregar Nueva Regla
      </Button>

        {/* Date Range Filter */}
        <Form className="mb-3  gap-3">
        <Form.Group>
          <Form.Label>Fecha Inicio</Form.Label>
          <Form.Control
            type="date"
            value={dateFilter.start_date}
            onChange={(e) => setDateFilter({ ...dateFilter, start_date: e.target.value })}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Fecha Fin</Form.Label>
          <Form.Control
            type="date"
            value={dateFilter.end_date}
            onChange={(e) => setDateFilter({ ...dateFilter, end_date: e.target.value })}
          />
        </Form.Group>
        
      {/* Rules Table */}
      <Table striped bordered hover className="mt-3" id="dispersion-table">
        <thead>
          <tr>
            <th>Cuenta Origen</th>
            <th>Cuenta Destino</th>
            <th>Tipo</th>
            <th>Valor</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {rules.map((rule) => (
            <tr key={rule.rule_id}>
              <td>{projects.find((a) => a.id === rule.general_project_id)?.name}</td>
              <td>{rule.target_project_id === null ? "Todas" : projects.find((a) => a.id === rule.target_project_id)?.name}</td>
              <td>{dispersionTypes[rule.dispersion_type]}</td>
              <td>{rule.dispersion_type === "even" ? "-" : rule.dispersion_value}</td>
              <td>
                <Button variant="warning"  size="sm" className="me-2" onClick={() => handleCalculateDispersion(formData.general_project_id)}>
                  Calcular
                </Button>
                <Button variant="warning" size="sm" className="me-2" onClick={() => handleApplyDispersion(rule.rule_id)}>
                  Aplicar Dispersión
                </Button>
                <Button variant="warning" size="sm" onClick={() => handleShowModal(rule)} className="me-2">
                  Editar
                </Button>
                <Button variant="danger" size="sm" onClick={() => handleDelete(rule.rule_id)}>
                  Eliminar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      </Form>
      {calculatedDispersion !== null && (
        <Alert variant="success">
          Monto Calculado: {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(calculatedDispersion)} - {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(calculatedDispersionByProject)} por proyecto
        </Alert>
      )}

<h3>Historial de Procesos de Dispersión</h3>
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>ID</th>
            <th>Fecha</th>
            <th>Cuenta Origen</th>
            <th>Cuenta Destino</th>
            <th>Monto Total</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {dispersionContainers.map((container) => (
            <tr key={container.cycle_id}>
              <td>{container.cycle_id}</td>
              <td>{new Date(container.created_at)?.toLocaleString()}</td>
              <td>{projects.find((a) => a.id === container.general_project_id)?.name}</td>
              <td>{projects.find((a) => a.id === container.target_project_id)?.name || "Todas"}</td>
              <td>${container.total_dispersed?.toLocaleString("es-MX")}</td>
              <td>
                <Button variant="info" size="sm" className="me-2" onClick={() => handleViewDetails(container)}>
                  Ver Detalles
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => handleDeleteContainer(container.cycle_id)}
                >
                  Eliminar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
    



      {/* Dispersion Details Modal */}
      <Modal show={showDetailsModal} onHide={() => setShowDetailsModal(false)} size="md" detailedCurrentPage ={detailedCurrentPage}>
        <Modal.Header closeButton>
          <Modal.Title>Detalles de Dispersión - ID {selectedContainer?.container_id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover className="compressedTable">
            <thead>
              <tr>
                <th>ID</th>
                <th>Fecha</th>
                <th>Cuenta Origen</th>
                <th>Cuenta Destino</th>
                <th>Monto</th>
              </tr>
            </thead>
            <tbody>
              {dispersionLogs.length > 0 ? (
                dispersionLogs.map((detail) => (
                  <tr key={detail.log_id}>
                    <td >{detail.log_id}</td>
                    <td data-label="Fecha">{new Date(detail.executed_at).toLocaleString()}</td>
                    <td data-label="Origen">{projects.find((a) => a.id === detail.general_account_id)?.name}</td>
                    <td data-label="Destino">{projects.find((a) => a.id === detail.target_account_id)?.name || "Todas"}</td>
                    <td data-label="Monto">${detail.dispersed_amount.toLocaleString("es-MX")}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">
                    No hay detalles disponibles.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <Pagination currentPage={detailedCurrentPage} totalPages={detailedTotalPages} onPageChange={setDetailedCurrentPage} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDetailsModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default DispersionPage;
