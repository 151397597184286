import React, { useEffect, useState } from "react";
import { Accordion, Form, Button, Container } from "react-bootstrap";
import Select from "react-select";
const FilterInquiry = ({
  filterProject,
  setFilterProject,
  filterDescription,
  setFilterDescription,
  filterRequestor,
  setFilterRequestor,
  filterStatus,
  setFilterStatus,
  filterFollowStatus,
  setFilterFollowStatus,
  projects,
  users,
  applyFilters
}) => {

             /* <Form.Group>
              <Form.Label>Selecciona un Proyecto:</Form.Label>
              
              <Form.Select
                value={filterProject || ""}
                onChange={(e) => setFilterProject(e.target.value)}
              >
                <option value="">-- Seleccionar Proyecto --</option>
                {projects.map((project) => (
                  <option key={project.id} value={project.id}>
                    {project.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            */

  return (
    <Accordion defaultActiveKey= {null}className="mb-4">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Filtros de Búsqueda</Accordion.Header>
        <Accordion.Body>
          <Form className="mb-4">     



            {/* Select Project */}
            <div>
              <p>Selecciona una Obra:</p>
            <Select
              options={projects                        
                .map((project) => ({ value: project.id, label: project.name }))
              }
              value={projects.find((p) => p.id === filterProject) 
                ? { value: filterProject , label: projects.find((p) => p.id === filterProject)?.name || "" } 
                : null} // Ensures correct value handling
              onChange={(e)=>setFilterProject(e?.value)}
              placeholder="Buscar Projecto..."
              className="fixed-autocomplete"
              menuPortalTarget={document.body} // Renders dropdown at root level
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensures dropdown is always on top
                menu: (base) => ({ ...base, zIndex: 9999 }) // Extra safeguard
              }}
              isClearable
              noOptionsMessage={() => "Sin presupuesto aprobado"}
            />
            </div>     



            <Form.Group controlId="filterDescription" className="mb-2">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                type="text"
                placeholder="Buscar descripción..."
                value={filterDescription} 
                onChange={(e) => setFilterDescription(e.target.value)}
              />
              <Form.Text muted>
                Ingrese al menos 3 caracteres para buscar.
              </Form.Text>
            </Form.Group>

            <Form.Group>
              <Form.Label>Comprador</Form.Label>
              <Form.Select
                value={filterRequestor}
                onChange={(e) =>
                  setFilterRequestor(e.target.value)
                }
              >
                <option value="">-- Seleccionar --</option>
                {users.map((user) => (
                  <option key={user.user_id} value={user.user_id}>
                    {user.user_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            
             <Form.Group>
              <Form.Label>Estatus</Form.Label>
              <Form.Select
                value={filterStatus}
                onChange={(e) =>
                  setFilterStatus(parseInt(e.target.value))
                }
              >
                <option value="">-- Seleccionar --</option>
                {[{"id":2,"name":"Aprobado"},{"id":3,"name":"Rechazado"},{"id":1,"name":"Pendiente"}].map((status) => (
                  <option key={status.id} value={status.id}>
                    {status.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            
            
              <Form.Group>
                <Form.Label>Envio y Pago</Form.Label>
                <Form.Select
                  value={filterFollowStatus}
                  onChange={(e) =>
                    setFilterFollowStatus(parseInt(e.target.value))
                  }
                >
                  <option value="">-- Seleccionar --</option>
                  {[{"id":1,"name":"Pagado"},{"id":2,"name":"Enviado"}].map((status) => (
                    <option key={status.id} value={status.id}>
                      {status.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

            <br></br>       
            <Button variant="primary" onClick={applyFilters}>
              Aplicar Filtros
            </Button>
         </Form>                       
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default FilterInquiry;
