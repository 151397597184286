import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Container, Alert, InputGroup, FormControl } from "react-bootstrap";
import { getCatalog,getVendors, getAccounts, addCatalogItem, updateCatalogItem, deleteCatalogItem } from "../services/api";
import { useAlert } from "../components/Alerts";
import { ToastContainer, toast } from 'react-toastify';
import {translate} from "../types/language-es";
import useBankValidation from "../hooks/useBankValidation";
import useCardValidation from "../hooks/useCardValidation";
import { Image } from "react-bootstrap"; 


const CatalogPage = ({ catalogType,userId }) => {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // For filtering
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { alert, showAlert, clearAlert } = useAlert();
  const [expandedRow, setExpandedRow] = useState(null); // For row expansion
  const [paymentSelection,setPaymentSelection] = useState("payment-account");
  const [filters, setFilters] = useState({      
    name: "",
    status: ['active','inactive','blacklisted'],
    
  });
  const mexicanBanks = [
    { code: "002", name: "Banamex" },
    { code: "012", name: "BBVA" },
    { code: "014", name: "Santander" },
    { code: "021", name: "HSBC" },
    { code: "030", name: "BANBAJIO" },
    { code: "036", name: "Inbursa" },
    { code: "044", name: "Scotiabank" },
    { code: "058", name: "Banregio" },
    { code: "137", name: "Intercam Banco" },
    { code: "019", name: "Bancomext" },
    { code: "106", name: "Bank of America" },
    { code: "059", name: "Invex Banco" },
    { code: "127", name: "Banco Azteca" },
    { code: "134", name: "Banco Afirme" },
    { code: "135", name: "Banco BASE" },
    { code: "136", name: "Banco Finterra" },
    { code: "138", name: "UBS Bank México" },
    { code: "140", name: "Banco Compartamos" },
    { code: "143", name: "Consubanco" },
    { code: "145", name: "Banco PagaTodo" },
  ];
  const { validateLuhn, getCardType } = useCardValidation();
  const { validateClabe, getBankFromClabe } = useBankValidation();
  const [clabeValid, setClabeValid] = useState(null);
  const [accountValid, setAccountValid] = useState(null);
  
  const [bankData, setBankData] = useState({ name: "", logo: null });

  const catalogs = {'vendors':{dataStructure : 
      {
        vendor_number: "",
        vendor_name: "",
        contact_name: "",
        phone_number: "",
        email: "",
        address: "",
        status: "active",
        payment_terms :"efectivo",
        vendor_type: "supplier",
        category:"",
        bank_name: "",
        bank_account:"",
        CLABE:"",
        attributes: "{\"díaDePago\":\"\"}"        
      },
      fetchFunction :getVendors
    },
      'accounts':{ dataStructure: {category_name: "",description:""},
      fetchFunction : getAccounts
    },
    'customers':{ dataStructure: {category_name: ""},
      fetchFunction : getAccounts
    }
  }
  const [formData, setFormData] = useState(catalogs[catalogType].dataStructure);

    
  const showMessage = (message,type = "success") => {
    // Display a success toast message
    switch (type){
      case "error":
          toast.error(message);
      break;
      case "info":
          toast.info(message);
      break;
      case "warn":
          toast.warn(message);
      break;
      default: toast.success(message);
    }
  };

  useEffect(() => {
    const fetchCatalog = async () => {
      try {
        showAlert(null);
        
        const data = await catalogs[catalogType].fetchFunction(filters);
        setItems(data.data);
        setFilteredItems(data.data);
      } catch (error) {
        console.error("Error fetching catalog:", error);
      }
    };
    fetchCatalog();
  }, [catalogType]);

  useEffect(() => {
    if (selectedItem) {
      setFormData({
        ...selectedItem,
        //attributes: selectedItem.attributes ? JSON.stringify(selectedItem.attributes, null, 2) : "{}"
      });
    }
  }, [selectedItem]);


  // Handle filtering
  useEffect(() => {
    const filtered = items.filter((item) =>
      Object.values(item)
        .join(" ")
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredItems(filtered);
  }, [searchTerm, items]);

  const handleShowModal = (item = null) => {
    setSelectedItem(item);
    setFormData(
      item || catalogs[catalogType].dataStructure
    );
    setShowModal(true);
  };

  const handleClabeChange = (e) => {
    const clabe = e.target.value;
    
  
    // Validar CLABE y obtener banco
    const isValid = validateClabe(clabe);
    setClabeValid(isValid);
    const bankResult  = getBankFromClabe(clabe);
    setBankData(bankResult);
    setFormData((prev) => ({...prev,bank_name:bankResult.name,CLABE: clabe  }));
  };


  const handleAccountChange = (e) => {
    const account = e.target.value;
    
  
    // Validar CLABE y obtener banco
    const isValid = validateLuhn(account);
    setAccountValid(isValid);
    const bankResult  = getCardType(account);
    setBankData(bankResult);
    setFormData((prev) => ({...prev,bank_name:bankResult.name,bank_account: account  }));
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name.startsWith("attributes.")) {
      const key = name.split(".")[1]; // Extract key from "attributes.díaDePago"
      setFormData((prev) => {
        try {
          const parsedAttributes = JSON.parse(prev.attributes || "{}");
          parsedAttributes[key] = value;
          return { ...prev, attributes: JSON.stringify(parsedAttributes) };
        } catch (error) {
          return prev;
        }
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

    const handleSubmit = async () => {
      try {
      

      
        if (selectedItem) {
          const updatedData = {
            ...formData,
            attributes: formData.attributes
          };
          await updateCatalogItem(catalogType, updatedData);
          showAlert("Elemento actualizado con éxito.",'success');        
          setItems((prev) =>
            prev.map((prev) =>
              prev.vendor_id === formData.vendor_id ? { ...prev, ...formData } : prev
            )
          );
        } else {
          await addCatalogItem(catalogType, formData);
          setItems((prev)=> [...prev,formData]);   
          showAlert("Elemento agregado con éxito.","success");
        }
      // setFilteredItems(formData);
        setShowModal(false);
        
        setTimeout(() => clearAlert(), 3000);
      } catch (error) {
        console.error("Error saving item:", error);
        showMessage(translate (error.response.data.error),"error");
      }
    };

  

  const handleDelete = async (id) => {
    try {
      await deleteCatalogItem(catalogType, {vendor_id:id,user_id:userId});
      showAlert("Elemento eliminado con éxito.","success");
      setItems(items.filter((item) => item.vendor_id !== id));
    } catch (error) {
      console.error("Error deleting item:", error);
      
      showMessage(translate (error.response.data.error),"error");
    }
  };

  const getCatalogTitle = () =>
  {
    switch (catalogType) {
      case  "vendors":
        return "Proveedores";        
      case  "customers":
        return "Clientes" 
      case  "accounts":
          return "Cuentas";
    }
  }
  return (
    <Container className="mt-4">
      <h2>{getCatalogTitle}</h2>
      {/* Alert */}
      {alert.message && (
        <Alert variant={alert.variant} onClose={clearAlert} dismissible>
          {alert.message}
        </Alert>
      )}
      
      {/* Search Filter */}
      <InputGroup className="mb-3">
        <FormControl
          placeholder="Buscar por nombre, contacto, email..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button variant="outline-secondary" onClick={() => setSearchTerm("")}>
          Limpiar
        </Button>
      </InputGroup>

      <Button variant="primary" onClick={() => handleShowModal()}>
        Agregar Nuevo
      </Button>

      <Table striped bordered hover className="mt-3 compressedTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>Número</th>
            <th>Nombre</th>
            <th>Contacto</th>
            <th>Teléfono</th>
            <th>Email</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {filteredItems.map((item) => (
            <React.Fragment key={item.vendor_id}>
              <tr onClick={() => setExpandedRow(expandedRow === item.vendor_id ? null : item.vendor_id)}>
                <td data-label="Id" >{item.vendor_id}</td>
                <td data-label="Número" >{item.vendor_number}</td>
                <td data-label="Nombre" >{item.vendor_name}</td>
                <td data-label="Contacto" >{item.contact_name}</td>
                <td data-label="Teléfono" >{item.phone_number}</td>                                
                <td data-label="email" >{item.email}</td>
                <td>
                  <Button
                    variant="warning"
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleShowModal(item);
                      setAccountValid(null);
                      setClabeValid(null);
                      if (item.CLABE) 
                      {
                          const clabeVerification = validateClabe(item.CLABE);
                          setClabeValid(clabeVerification)
                      }
                      //Enable it when the payment method field is in the DB to enable it when a credit card is selected
                      /*
                      if (item.bank_account) 
                        {
                            const accountVerification = validateLuhn(item.bank_account);
                            setAccountValid(accountVerification);
                        }*/
                      setBankData({name:item.bank_name});
                     
                    }}
                    className="me-2"
                  >
                    Editar
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(item.vendor_id);
                    }}
                  >
                    Eliminar
                  </Button>
                </td>
              </tr>
              {expandedRow === item.vendor_id && (
               <tr>
               <td colSpan="7" className="align-left">
                 <div className="row">
                   <div className="col-md-4"><strong>Dirección:</strong> {item.address || "N/A"}</div>
                   <div className="col-md-4"><strong>Términos de Pago:</strong> {item.payment_terms || "N/A"}</div>
                   <div className="col-md-4"><strong>Tipo:</strong> {item.vendor_type || "N/A"}</div>
                   <div className="col-md-4"><strong>Categoría:</strong> {item.category || "N/A"}</div>                  
                   <div className="col-md-4"><strong>Banco:</strong> {item.bank_name || "N/A"}</div>                  
                   <div className="col-md-4"><strong>Cuenta:</strong> {item.bank_account || "N/A"}</div>                  
                   <div className="col-md-4"><strong>CLABE:</strong> {item.CLABE || "N/A"}</div>                  
                
                   {item.attributes && (
                    (() => {
                      try {
                        const parsedAttributes = JSON.parse(item.attributes);
                        return Object.entries(parsedAttributes).map(([key, value]) => (
                          <div key={key}>
                            <strong>{key}:</strong> {value || "N/A"}
                          </div>
                        ));
                      } catch (error) {
                        return <div style={{ color: "red" }}>Error al leer atributos</div>;
                      }
                    })()
                  ) }
                  
                 </div>
               </td>
             </tr>
             
              )}
            </React.Fragment>
          ))}
        </tbody>
      </Table>

      {/* Add/Edit Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedItem ? "Editar Elemento" : "Agregar Nuevo Elemento"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Basic Fields */}
            <Form.Group>
              <Form.Label>Número</Form.Label>
              <Form.Control name="vendor_number" value={formData.vendor_number} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control name="vendor_name" value={formData.vendor_name} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Contacto</Form.Label>
              <Form.Control name="contact_name" value={formData.contact_name} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Teléfono</Form.Label>
              <Form.Control name="phone_number" value={formData.phone_number} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control name="email" value={formData.email} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Dirección</Form.Label>
              <Form.Control as="textarea" name="address" value={formData.address} onChange={handleChange} />
            </Form.Group>            

            {/* Dropdown: Status */}
            <Form.Group>
              <Form.Label>Estado</Form.Label>
              <Form.Select name="status" value={formData.status} onChange={handleChange}>
                <option value="">-- Seleccionar Estado --</option>
                <option value="active">Activo</option>
                <option value="inactive">Inactivoo</option>
                <option value="blacklisted">Detenido</option>
                
              </Form.Select>
            </Form.Group>

            {/* Dropdown: Vendor Type */}
            <Form.Group>
              <Form.Label>Tipo de Proveedor</Form.Label>
              <Form.Select name="vendor_type" value={formData.vendor_type} onChange={handleChange}>
                <option value="">-- Seleccionar Tipo --</option>
                <option value="supplier">Proveedor</option>
                <option value="contractor">Contratista</option>
                <option value="subcontractor">Subcontratista</option>
                <option value="service provider">Proveedor de servicios</option>
              </Form.Select>
              </Form.Group>
              
              <Form.Group>
              <Form.Label>Forma de pago:  </Form.Label>  <br></br>            
             
              <Form.Check
                inline
                type="radio"
                id="payment-account"
                name="payment-account"
                label="Cuenta Bancaria"
                value="payment-account"
                checked={paymentSelection === "payment-account"}
                onChange={(e) => setPaymentSelection(e.target.value)}
                //onChange = {handleSearchFilterChange}
              />     
              <Form.Check
                inline
                type="radio"
                id="payment-tarjeta"
                name="payment-tarjeta"
                label="Tarjeta"
                value="payment-card"
                checked={paymentSelection === "payment-card"}
                onChange={(e) => setPaymentSelection(e.target.value)}
                //onChange = {handleSearchFilterChange}
              />                        
              </Form.Group>
              <Form.Group>
              <Form.Label>{paymentSelection === "payment-card"? "Tarjeta":"Cuenta"}</Form.Label>
              <Form.Control
                type="text"
                name="bank_account"                
                value={formData.bank_account}
                onChange={paymentSelection === "payment-card"?handleAccountChange:handleChange} 
                isInvalid={paymentSelection === "payment-card" && accountValid === false}                               
              />
              <Form.Control.Feedback type="invalid">
                La cuenta ingresada no es válida.
              </Form.Control.Feedback>
              <Form.Group>
              <Form.Label>CLABE</Form.Label>
              <Form.Control
                type="text"
                name="clabe"             
                value={formData.CLABE}                
                onChange={handleClabeChange}
                maxLength={18}
                isInvalid={ clabeValid === false && formData.CLABE !== ""}
                //onChange = {handleSearchFilterChange}
              />   
              </Form.Group>   
              <Form.Control.Feedback type="invalid">
                La CLABE ingresada no es válida.
              </Form.Control.Feedback>

              {/* Mostrar banco y logo si la CLABE es válida */}
              {paymentSelection === "payment-clabe" && clabeValid && (
                <div className="d-flex align-items-center mt-2">
                  {bankData.logo && <Image src={bankData.logo} alt={bankData.name} width={50} className="me-2" />}
                  <span className="text-success"> ✅ </span>
                </div>
              )}
            </Form.Group>
           
            <Form.Group>
              <Form.Label>Banco</Form.Label>
              <Form.Select
                name="bank_name"
                value={formData.bank_name}
                disabled = {clabeValid}
                onChange={handleChange}
              >
                <option value="">-- Seleccionar Banco --</option>
                {mexicanBanks.map((bank) => (
                  <option key={bank.code} value={bank.name}>
                    {bank.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group>
              <Form.Label>Términos de pago</Form.Label>
              <Form.Control as="textarea" name="payment_terms" value={formData.payment_terms} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Categoría</Form.Label>
              <Form.Control as="textarea" name="category" value={formData.category} onChange={handleChange} />
            </Form.Group>            

            {/* Dynamic Attributes Rendering */}
            {formData.attributes && (() => {
              try {
                const parsedAttributes = JSON.parse(formData.attributes);
                return Object.entries(parsedAttributes).map(([key, value]) => (
                  <Form.Group key={key}>
                    <Form.Label>{key}</Form.Label>
                    <Form.Control
                      name={`attributes.${key}`}
                      value={value || ""}
                      onChange={handleChange}
                    />
                  </Form.Group>
                ));
              } catch (error) {
                return <div style={{ color: "red" }}>Error al cargar atributos</div>;
              }
            })()}

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer 
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default CatalogPage;
