import React, { createContext, useContext, useEffect, useState, useRef, useCallback } from "react";
import { getNotifications } from "../services/api";

const NotificationsContext = createContext();

export const NotificationsProvider = ({ children,initialUserId }) => {
  const [userId, setUserId] = useState(initialUserId);
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const hasFetched = useRef(false); // Track fetch status


  
  const fetchNotifications = useCallback(async () => {    
    if (hasFetched.current || !userId) return; // Prevent duplicate fetch
    try {
      const data = await getNotifications(userId);
      setNotifications(data);
      setNotificationsCount(data.length);
      hasFetched.current = true; // Mark as fetched
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  }, [userId]);

  const refetchNotifications = useCallback(async () => {
    try {
      const userId = localStorage.getItem("userId")
      if (!userId) return;
      const data = await getNotifications(userId);
      setNotifications(data);
      setNotificationsCount(data.length);
    } catch (error) {
      console.error("Error refetching notifications:", error);
    }
  }, []);

  const incrementNotificationsCount = useCallback(() => {
    setNotificationsCount((prev) => prev + 1);
  }, []);

  

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        notificationsCount,
        fetchNotifications,
        refetchNotifications, // Manual refresh method
        incrementNotificationsCount,
        setUserId
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationsContext);
