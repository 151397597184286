import React, { useEffect, useState } from "react";
import { Table, Button, Alert, Container, Modal } from "react-bootstrap";
import { transactionTypeMap } from "../types/CashflowModuleTypes";
import { useNotifications } from "../context/NotificationsContext";
import { useAlert } from "../components/Alerts";
import { pettycashStatusTypeMap } from "../types/PettycashModuleTypes";
import { hasAccess } from "../utils/AccessControl";
import { activeModules } from "../config/systemConfig";


import {
  getProjects,
  getCategories,
  getCashflows,
  updatePettyCash
} from "../services/api";

const IncomingCashflowPage = ({ userId , userPrivileges,rolePrivileges}) => {
  const { refetchNotifications } = useNotifications();
  const [invoices, setInvoices] = useState([]);
  const [projects, setProjects] = useState([]);
  const [categories, setCategories] = useState([]);    
  const [editingInvoice, setEditingInvoice] = useState(null);
  const [selectedCashflows, setSelectedCashflows] = useState([]); 
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const { alert, showAlert, clearAlert } = useAlert();

  useEffect(() => {
    const fetchData = async () => {
      try {
        var statusRequest;
        if (hasAccess(userPrivileges, rolePrivileges, "cashflow:payments", activeModules, "purchasing"))
        {
          statusRequest =  ["requested", "approved"];
        }
        else
        {
          statusRequest = ["requested"];
        }
        const queryParams = {
          status: statusRequest, // Include "approved" requests for payment processing
          petty_cash: 1,
        };
        const [projectsData, categoriesData, invoicesData] = await Promise.all([
          getProjects({user_id:userId}),
          getCategories(),
          getCashflows(queryParams),
        ]);

        setProjects(projectsData.data || []);
        setCategories(categoriesData.data || []);
        setInvoices(invoicesData || []);
      } catch (error) {
        console.error("Error fetching data:", error);
        showAlert("Error al obtener los datos iniciales.","danger");        
      }
    };
    fetchData();
  }, [userPrivileges]);

  const fetchCashflowDetails = async (status,requestId) => {
    try {
      const queryParams = {
        petty_cash_id: requestId,        
        status: status === "requested"?["pending"]:["approved","valid"]
      };
      const cashflows = await getCashflows(queryParams);
      setSelectedCashflows(cashflows);
      setShowDetailsModal(true);
    } catch (error) {
      console.error("Error fetching cashflow details:", error);
      showAlert("Error al obtener detalles de la solicitud.","danger");      
    }
  };

  const handleApprove = async (invoice) => {
    try {
      if (invoice.transaction_type === "fiscal" && (!invoice.uuid || invoice.uuid === "")) {
        showAlert("UUID faltante","warning");          
        return;
      }

      await updatePettyCash({
        request_id: invoice.request_id,
        user_id: userId,
        status: "approved",
        project_id: invoice.project_id,
        category_id: invoice.category_id,
      });

      setInvoices((prev) => prev.map((inv) => inv.request_id === invoice.request_id ? { ...inv, status: "approved" } : inv));
      showAlert("Solicitud aprobada exitosamente.", "success");
      refetchNotifications();
      
    } catch (error) {
      console.error("Error al aprobar la solicitud:", error);
      showAlert("Error al aprobar la solicitud.","danger");        
    }
  };

  const handleReject = async (invoiceId) => {
    try {
      await updatePettyCash({
        request_id: invoiceId,
        user_id: userId,
        status: "rejected",
      });

      setInvoices((prev) => prev.filter((invoice) => invoice.request_id !== invoiceId));
      showAlert("Solicitud rechazada exitosamente.","success");      
    } catch (error) {
      console.error("Error al rechazar la solicitud:", error);
      showAlert("Error al rechazar la solicitud.","danger");      
    }
  };

  const handlePayment = async (invoice) => {
    try {
      if (invoice.status !== "approved") {
        showAlert("Solo se pueden pagar solicitudes aprobadas.", "warning");
        return;
      }

      await updatePettyCash({
        request_id: invoice.request_id,
        user_id: userId,
        status: "paid",
        project_id: invoice.project_id,
        category_id: invoice.category_id,
        amount: invoice.amount,
        payment_date: new Date().toISOString(),
      });

      setInvoices((prev) => prev.map((inv) => inv.request_id === invoice.request_id ? { ...inv, status: "paid" } : inv));
      showAlert("Pago registrado exitosamente.", "success");
      refetchNotifications();
      
    } catch (error) {
      console.error("Error al procesar el pago:", error);
      showAlert("Error al procesar el pago.","danger");        
    }
  };

  return (
    <Container className="mt-4">
      <h1>Revisión de Facturas Entrantes</h1>
      {alert.message && (
        <Alert variant={alert.variant} onClose={clearAlert} dismissible>
          {alert.message}
        </Alert>
      )}

      <Table bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Fecha</th>
            <th>Obra</th>
            <th>Emisor</th>            
            <th>Monto</th>
            <th>Estado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice) => (
            <tr key={invoice.id}>
              <td data-label="ID">{invoice.request_id}</td>
              <td>{invoice.created_at}</td>
              <td data-label="project_name">{invoice.project_name}</td>
              <td>{invoice.name}</td>              
              <td className="text-right">
                {new Intl.NumberFormat("es-MX", {
                  style: "currency",
                  currency: "MXN",
                }).format(invoice.amount)}
              </td>
              <td>
                <span className={`badge ${(invoice.status === "approved" || invoice.status ==="paid")? "badge-success" : invoice.status === "paid" ? "badge-primary" : "badge-warning"}`}>
                  {pettycashStatusTypeMap[invoice.status]}
                </span>
              </td>
              <td>
                <Button className="btn-ripple" variant="info" onClick={() => fetchCashflowDetails(invoice.status,invoice.request_id)}>
                  Ver Detalles
                </Button>{" "}
                {invoice.status === "requested" && (
                  <>
                    <Button className="btn-ripple " variant="success" onClick={() => handleApprove(invoice)}>
                      Aprobar
                    </Button>{" "}
                    <Button className="btn-custom " variant="danger" onClick={() => handleReject(invoice.request_id)}>
                      Rechazar
                    </Button>{" "}
                  </>
                )}
                {invoice.status === "approved" && (
                  <Button className="btn-ripple" variant="success" onClick={() => handlePayment(invoice)}>
                    Pagar
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showDetailsModal} onHide={() => setShowDetailsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Detalles de Caja Chica</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="table-container">
            <Table class="modal-table" id="modal-table" striped bordered hover >
              <thead>
                <tr>
                  <th key="Fecha">Fecha</th>        
                  <th key="UUID">UUID</th>      
                  <th key="Emisor">Emisor</th>  
                  <th key="Tipo">Tipo</th>
                  <th key="Categoria">Categoría</th>
                  <th key="Concepto">Concepto</th>
                  <th key="Monto">Monto</th>
                  <th key="Notas">Notas</th>
                </tr>
              </thead>
              <tbody>
                {selectedCashflows.map((cashflow) => (
                  <tr key={cashflow.cf_id}>
                    <td data-label="Fecha">{cashflow.cf_date}</td>        
                    <td data-label="UUID">{cashflow.uuid}</td>        
                    <td data-label="Emisor">{cashflow.nombre_emisor}</td>                  
                    <td data-label="Tipo">{transactionTypeMap[cashflow.transaction_type]}</td>
                    <td data-label="Categoria">{cashflow.category_name}</td>
                    <td data-label="Concepto">{cashflow.concept}</td>
                    <td data-label="Monto">
                      {new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                      }).format(cashflow.totalAmount)}
                    </td>
                    <td data-label="Notas">{cashflow.notes}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDetailsModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default IncomingCashflowPage;
