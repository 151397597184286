import React, { useState, useEffect } from 'react';
import { Container, Table, Button, Form, Modal, Alert } from 'react-bootstrap';
import { getPatternRules, savePatternRule ,getProjects,getCategories} from '../services/api';

const PatternRulesPage = ({userId}) => {
  const [rules, setRules] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentRule, setCurrentRule] = useState({ type: 'project', pattern: '', target_id: '' ,company_id:1});
  const [alert, setAlert] = useState(null);
  const [projects, setProjects] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  const fetchRules = async () => {
    try {
      const rulesData = await getPatternRules();
      setRules(rulesData.data);
    } catch (error) {
      setAlert({ type: 'danger', message: 'Failed to fetch pattern rules.' });
    }
  };

  useEffect(() => {
    fetchRules();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const [projectsData, categoriesData] = await Promise.all([
        getProjects({user_id:userId}),
        getCategories(),         
      ]);    

      setProjects(projectsData.data);
      setCategories(categoriesData.data);       
    };
    fetchData();
  }, []);
  

  const handleSave = async () => {
    try {
      
      const result = await savePatternRule(currentRule);
      if (result){
        setShowModal(false);
        if (currentRule.id)
        {
          setRules((prev) =>
            prev.map((rule) =>
              rule.id === currentRule.id ? { ...rule, ...currentRule } : rule
            )
          );
        }
        else{
          setRules((prev)=> [...prev,currentRule]);        
        }
        setAlert({ type: 'success', message: 'Regla guardada exitosamente!' });      
      }
    } catch (error) {
      setAlert({ type: 'danger', message: 'Fallo al guardar la regla.' });
    }
  };

  return (
    <Container>
      <h1>Administrador de reglas de asociación</h1>
      {alert && <Alert variant={alert.type}>{alert.message}</Alert>}
      <Button onClick={() => {setCurrentRule ( {type: 'project', pattern: '', target_id: '' ,company_id:1});setShowModal(true)}}>Agregar nueva regla</Button>
      <div className="table-container noOverflow">
        <Table bordered hover className="mt-4 fixed ">
          <thead>
            <tr>  
              <th>tipo</th>
              <th>Patrón</th>
              <th>ID objetivo</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {rules.map((rule) => (
              <tr key={rule.id}>
                <td>{rule.type}</td>
                <td>{rule.pattern}</td>                       
                <td>
                  {rule.type === "project" && 
                    projects.find((p) => p.id === parseInt(rule.target_id))?.name
                  }
                  {rule.type === "category" && 
                    categories.find((c) => c.category_id === parseInt(rule.target_id))?.category_name
                  } 
                </td>           
                <td>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => {
                      setCurrentRule(rule);
                      setShowModal(true);
                    }}
                  >
                    Edit
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Modal for Adding/Editing Rules */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{currentRule.id ? 'Editar Regla' : 'Nueva Regla'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Tipo</Form.Label>
              <Form.Select
                value={currentRule.type}
                onChange={(e) => setCurrentRule({ ...currentRule, type: e.target.value })}
              >
                <option value="project">Projecto</option>
                <option value="category">Categoría</option>
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Regla</Form.Label>
              <Form.Control
                type="text"
                value={currentRule.pattern}
                onChange={(e) => setCurrentRule({ ...currentRule, pattern: e.target.value })}
              />
            </Form.Group>

            {/* Select Project */}
            {currentRule.type == 'project' &&(
            <Form.Group>
              <Form.Label>Proyecto:</Form.Label>
              <Form.Select
                onChange={(e) => setCurrentRule({ ...currentRule, target_id: e.target.value })}
                value={currentRule.target_id || ""}
              >
                <option value="">-- Seleccionar --</option>
                {projects.map((project) => (
                  <option key={project.id} value={project.id}>
                    {project.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            )}


            {currentRule.type == 'category' &&(

              <Form.Group>
              <Form.Label>Categoría:</Form.Label>
              <Form.Select
                onChange={(e) => setCurrentRule({ ...currentRule, target_id: e.target.value })}
                value={currentRule.target_id || ""}
              >
                <option value="">-- Seleccionar --</option>
                {categories.map((category) => (
                  <option key={category.category_id} value={category.category_id}>
                    {category.category_name}
                  </option>
                ))}
              </Form.Select>
              </Form.Group>

            )}
          </Form>          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default PatternRulesPage;
