import React, { useEffect, useState } from "react";
import { Table, Button, Alert, Container, Spinner, Modal, Form ,Collapse} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faCheck, faTimes, faEye } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from 'react-toastify';
import { useAlert } from "../components/Alerts";
import { hasPermission } from "../context/UserContext";
import ConfirmationDialog from "../components/ConfirmationDialog";
import Pagination from "../components/Pagination";
import ScrollToTopButton from "../components/ScrollTop";
import FilterInquiry from "../components/FilterInquiry";
import { purDECIMALS,purTOLERANCE } from "../config/systemConfig";



import {
  getInquiries,
  getPOs,
  getProjects,
  approveInquiry,
  approvePO,
  rejectPO,
  rejectInquiry, 
  deletePO,
  getVendors,
  getUsers
} from "../services/api";

const ApprovalPage = ({ userPrivileges, userId }) => {
  const [projects, setProjects] = useState([]);
  const [pendingInquiries, setPendingInquiries] = useState([]);
  const [pendingPOs, setPendingPOs] = useState([]);  
  const [itemToDelete, setItemToDelete] = useState(null);
  const [selectedPO, setSelectedPO] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const { alert, showAlert, clearAlert } = useAlert();  
  const [showFilters, setShowFilters] = useState(false);
  const [users, setUsers] = useState([]);  
  const [vendors, setVendors] = useState([]);
  const [poItemsForModal, setPOItemsForModal] = useState([]);
  const [showPODetails, setShowPODetails] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [overQuantity, setOverQuantity] = useState(false);


  /* Pagination */
  const [currentInqPage, setCurrentInqPage] = useState(1);
  const [totalInqPages, setTotalInqPages] = useState(1);
  const [currentPOPage, setCurrentPOPage] = useState(1);
  const [totalPOPages, setTotalPOPages] = useState(1);

  // -------------------------
  // FILTER STATES
  // -------------------------
  const [filterProject, setFilterProject] = useState("");
  const [filterDescription, setFilterDescription] = useState("");
  const [filterRequestor, setFilterRequestor] = useState("");

  const [debouncedFilterDescription, setDebouncedFilterDescription] = useState("");

  // Debounce effect: update debouncedFilterDescription 500ms after filterDescription stops changing, if length >= 3
  useEffect(() => {
    const handler = setTimeout(() => {
      if (filterDescription.length >= 3) {
        setDebouncedFilterDescription(filterDescription);
      } else {
        setDebouncedFilterDescription(""); // or set it to filterDescription if you want to include empty or short values
      }
    }, 500); // Delay in ms

    return () => clearTimeout(handler);
  }, [filterDescription]);


  // Fetch projects on load
  useEffect(() => {
    showAlert(null);
    const fetchProjects = async () => {
      const projectsData = await getProjects({user_id:userId});
      setProjects(projectsData.data);
    };
    
    const fetchUsers = async () => {
       const usersData = await getUsers();
       setUsers(usersData);
    }
    fetchProjects();
    fetchUsers();
    
  }, []);

  const applyFilters = () => {
    fetchInquiries(1); // Or any logic you have to update data based on filters
    fetchPOs(1);
  };

  // Fetch pending inquiries (with filters)
  const fetchInquiries = async (page) => {
    const queryParams = {
      page: page,
      filter: 1,
      // Add filters only if they have a value
      ...(filterProject && { projectId: filterProject }),      
      ...(debouncedFilterDescription && { description: debouncedFilterDescription }),
      ...(filterRequestor && { created_by: filterRequestor }),
    };
    try {
      const inquiriesData = await getInquiries(queryParams);
      setTotalInqPages(inquiriesData.pagination.total_pages);
      const statusOrder = ["Pendiente", "Autorizado", "Rechazado"];
      const sortedInquiries = inquiriesData.data
        .filter((inq) => inq.status === "Pendiente")
        .sort((a, b) => {
          const priorityComparison = a.priority - b.priority;
          if (priorityComparison !==0){ return priorityComparison;} 
          const statusComparison = statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
          if (statusComparison !== 0) {
            return statusComparison;
          }
          return b.inquiry_id - a.inquiry_id;
        });
      setPendingInquiries(sortedInquiries);
    } catch (error) {
      console.error("Error fetching pending inquiries:", error);
      showAlert("Error al cargar las solicitudes pendientes.", "danger");
    }
  };

  // Fetch pending POs (optionally add filters here too)
  const fetchPOs = async (page) => {
    const queryParams = {
      page: page,
      filter: 1,      
      ...(filterProject && { project_id: filterProject }),            
      ...(filterRequestor && { buyerId: filterRequestor }),
      // You can add similar filters if needed
    };
    try {
      const POsData = await getPOs(queryParams);
      setPendingPOs(POsData.filter((po) => po.status === "Pendiente"));
    } catch (error) {
      console.error("Error fetching pending POs:", error);
      showAlert("Error al cargar las solicitudes pendientes.", "danger");
    }
  };

  const fetchPendingApprovals = async () => {
    try {
      // Call the functions to fetch both POs and inquiries
      fetchPOs(1);
      const vendorsData = await getVendors();
      setVendors(vendorsData.data);
      fetchInquiries(1);
    } catch (error) {
      console.error("Error fetching pending approvals:", error);
      showAlert("Error al cargar las solicitudes pendientes.", "danger");
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchInquiries(currentInqPage);
    
  }, [currentInqPage,filterProject, debouncedFilterDescription, filterRequestor]);

  useEffect(() => {
    fetchPOs(currentPOPage);
  }, [currentPOPage,filterProject,filterRequestor]);

  useEffect(() => {
    fetchPendingApprovals();
  }, []);

  // Handlers for approval, rejection, deletion, etc.
  const handleRejectInquiry = async (projectId, inquiryId) => {
    try {
      await rejectInquiry(projectId, inquiryId, userId);
      setPendingInquiries((prev) => prev.filter((inquiry) => inquiry.inquiry_id !== inquiryId));
      showAlert("Solicitud rechazada exitosamente.", "success");
    } catch (error) {
      console.error("Error al rechazar la solicitud:", error);
      showAlert("Error al rechazar la solicitud.", "warning");
    }
  };

  const handleApproveInquiry = async (projectId, inquiryId) => {
    try {
      await approveInquiry(projectId, inquiryId, userId);
      setPendingInquiries((prev) => prev.filter((inquiry) => inquiry.inquiry_id !== inquiryId));
      showAlert("Requisición aprobada exitosamente.", "success");
    } catch (error) {
      console.error("Error approving inquiry:", error);
      showAlert("Error al aprobar la requisición - " + error, "warning");
    }
  };

  const handleApprovePO = async (projectId,poId) => {
    try {
      await approvePO(projectId,poId, userId);
      setPendingPOs((prev) => prev.filter((po) => po.po_id !== poId));
      showAlert("Orden de compra aprobada exitosamente.", "success");
    } catch (error) {
      console.error("Error approving PO:", error);
      showAlert("Error al aprobar la orden de compra.", "warning");
    }
  };

  const handleRejectPO = async (projectId,poId) => {
    try {
      await rejectPO(projectId,poId, userId);
      setPendingPOs((prev) => prev.filter((po) => po.po_id !== poId));
      showAlert("Orden de compra rechazada exitosamente.", "success");
    } catch (error) {
      console.error("Error approving PO:", error);
      showAlert("Error al rechazada la orden de compra.", "warning");
    }
  };

  const handleDeleteClick = (item) => {
    setItemToDelete(item);
    setShowConfirmDelete(true);
  };

  const handleConfirmDelete = () => {
    handleDeletePO(itemToDelete);
    setShowConfirmDelete(false);
    setItemToDelete(null);
  };

  const handleCancelDelete = () => {
    setShowConfirmDelete(false);
    setItemToDelete(null);
  };

  const handleDeletePO = async (po) => {
    try {
      const queryParams = {
        po_id: po.po_id,
        user_id: userId,
      };
      const response = await deletePO(14, queryParams);
      setPendingPOs((prev) => prev.filter((poItem) => poItem.po_id !== po.po_id));
      showAlert("PO eliminada correctamente.", "success");
    } catch (error) {
      console.error("Error deleting PO:", error);
      showAlert("Error al eliminar la PO. Inténtalo de nuevo.", "danger");
    }
  };

  const handleShowPODetails = async (po) => {
    setSelectedPO(po);
    try {
      const queryParams = { po_id: po.po_id};
      const [response] = await Promise.all([getPOs(queryParams)]);
      setPOItemsForModal(response);
    } catch (error) {
      console.error("Error retrieving PO items:", error);
      setPOItemsForModal([]);
    }
    setShowPODetails(true);
  };

  const handleClosePODetails = () => {
    setShowPODetails(false);
    setSelectedPO(null);
    setPOItemsForModal([]);
  };

  // Group inquiries by project (or any other grouping criteria)
  const groupedInquiries = pendingInquiries.reduce((acc, inquiry) => {
    const project = parseInt(inquiry.project_id) || 0;
    if (!acc[project]) {
      acc[project] = [];
    }
    acc[project].push(inquiry);
    return acc;
  }, {});


  // Group pendingPOs by project name
const groupedPOs = pendingPOs.reduce((acc, po) => {
  // Use a default value if project_name is missing
  const project = po.project_name || "Sin Proyecto";
  if (!acc[project]) {
    acc[project] = [];
  }
  acc[project].push(po);
  return acc;
}, {});

const getQuantityClassName = (quantity, budgetRemainingQuantity,inquiryQuantity) => {
  const diff = budgetRemainingQuantity - quantity;
  if (diff < 0) {
    return "text-centered background-red"; // Over budget
  } else if (inquiryQuantity - quantity < 0) {
    return "text-centered over-budget-warning"; // Exactly equal
  } else {
    return "text-centered"; // Within budget (or you can add another class if desired)
  }
};

  return (
    <Container className="mt-4">
      <h1>Aprobaciones Pendientes</h1>
      {/* Alert */}
      {alert.message && (
        <Alert variant={alert.variant} onClose={clearAlert} dismissible>
          {alert.message}
        </Alert>
      )}

      {/* Filter Accordion */}
      <FilterInquiry
        filterProject={filterProject}
        setFilterProject={setFilterProject}
        filterDescription={filterDescription}
        setFilterDescription={setFilterDescription}
        filterRequestor={filterRequestor}
        setFilterRequestor={setFilterRequestor}
        applyFilters={applyFilters}
        projects = {projects}
        users = {users}        
      />
      {/* Pending Inquiries */}
      <h3 className="mt-4">Requisiciones Pendientes</h3>
      {pendingInquiries.length > 0 ? (
        <div>
          <Table className="compressedTable" bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Fecha</th>
                <th>P</th>
                <th>Descripción</th>
                <th>Cantidad</th>
                <th>Costo Unitario</th>
                <th>SubTotal</th>
                <th>Solicitado Por</th>
                <th>Fecha</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(groupedInquiries).map((projectID) => (
                <React.Fragment key={parseInt(projectID)}>
                  {/* Project Header Row */}
                  <tr className="table-group-header">
                    <td colSpan="9" style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>
                      {projects.find((item) => item.id === parseInt(projectID))?.name}
                    </td>
                  </tr>
                  {groupedInquiries[parseInt(projectID)].map((inquiry) => (
                    <tr key={inquiry.inquiry_id}>
                      <td data-label="Id">{inquiry.inquiry_id}</td>
                      <td data-label="Fecha">{new Date(inquiry.created_at)?.toLocaleDateString()}</td>
                      <td data-label="Prioridad">{inquiry.priority}</td>
                      <td data-label="Descripción"> <>
                          <div>{inquiry.description}<b>{inquiry.category? `-${inquiry.category}` :""}</b></div>
                          {inquiry.notes && <div style={{fontSize:"0.85em", color:"#666"}}>{inquiry.notes}</div>}
                          </></td>
                      <td data-label="Cantidad" className={inquiry.budgetQuantity < inquiry.inquiryQuantity? "over-budget text-right" : "text-right"}>
                        {new Intl.NumberFormat("es-MX", { minimumFractionDigits: purDECIMALS, maximumFractionDigits: purDECIMALS }).format(inquiry.inquiryQuantity)}
                        { inquiry.budgetQuantity < inquiry.inquiryQuantity && <div style={{fontSize:"0.85em", color:"#666"}}>{inquiry.budgetQuantity}</div>}
                      </td>
                      <td data-label="Costo Unitario" className="text-right">
                        {new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(inquiry.unitCost)}
                      </td>
                      <td data-label="SubTotal" className="text-right">
                        {new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(inquiry.unitCost * inquiry.inquiryQuantity)}
                      </td>
                      <td data-label="Solicitado por">{inquiry.requestor_name}</td>
                      <td data-label="Fecha">{new Date(inquiry.created_at)?.toLocaleDateString()}</td>
                      <td className="action-buttons">
                        {inquiry.status !== "Autorizado" &&
                          hasPermission(userPrivileges, "inquiries", ["write", "approve"]) && (
                            <>
                              <Button
                                variant="success"
                                size="sm"
                                onClick={() => handleApproveInquiry(inquiry.project_id, inquiry.inquiry_id)}
                                className="me-1"
                              >
                                <FontAwesomeIcon icon={faCheck} title="Aprobar" />
                              </Button>
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={() => handleRejectInquiry(inquiry.project_id, inquiry.inquiry_id)}
                                className="me-1"
                              >
                                <FontAwesomeIcon icon={faTimes} title="Rechazar" />
                              </Button>
                            </>
                          )}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
          <Pagination
            currentPage={currentInqPage}
            totalPages={totalInqPages}
            onPageChange={setCurrentInqPage}
          />
        </div>
      ) : (
        <p>No hay requisiciones pendientes.</p>
      )}

      {/* Pending POs */}
      <h3 className="mt-4">Órdenes de Compra Pendientes</h3>
      {loadingData ? (
        <Spinner animation="border" />
      ) : (
        <>
          {pendingPOs.length > 0 ? (
            <div>
              <Table className="compressedTable" bordered hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Obra</th>
                    <th>Número de Orden</th>
                    <th>Descripción</th>
                    <th>Total</th>
                    <th>Comprador</th>
                    <th>Fecha</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                {Object.keys(groupedPOs).map((projectName) => (
                  <React.Fragment key={projectName}>
                    {/* Project header row */}
                    <tr className="table-group-header">
                      <td colSpan="8" style={{ backgroundColor: "#f0f0f0", fontWeight: "bold" }}>
                        {projectName}
                      </td>
                    </tr>
                    {groupedPOs[projectName].map((po) => (
                      <tr key={po.po_id}>
                        <td data-label="Id">{po.po_id}</td>
                        <td data-label="Obra">{po.project_name}</td>
                        <td data-label="Orden">{po.po_number}</td>
                        <td data-label="Descripción">{po.description}</td>
                        <td data-label="Total" className="text-right">
                          {new Intl.NumberFormat("es-MX", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(po.total_amount)}
                        </td>
                        <td data-label="Comprador">{po.buyer}</td>
                        <td data-label="Fecha">{new Date(po.created_at).toLocaleDateString()}</td>
                        <td>
                          <Button
                            variant="info"
                            size="sm"
                            onClick={() => {
                              setOverQuantity(po.over_quantity_flag);
                              handleShowPODetails(po);
                            }}
                            className="me-1"
                          >
                            <FontAwesomeIcon icon={faEye} title="Ver Detalles PO" />
                          </Button>
                          {po.status !== "Aprobado" &&
                            hasPermission(userPrivileges, "approvals", ["write", "approve"]) && (
                              <Button
                                variant="success"
                                size="sm"
                                onClick={() => handleApprovePO(po.project_id,po.po_id)}
                                className="me-1"
                                disabled={po.status !== "Pendiente"}
                              >
                              <FontAwesomeIcon icon={faCheck} title="Aprobar PO" />
                              </Button>
                            )}
                          {po.status !== "Aprobado" &&
                            hasPermission(userPrivileges, "approvals", ["write", "approve"]) && (
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={() => handleRejectPO(po.project_id,po.po_id)}
                                className="me-1"
                                disabled={po.status !== "Pendiente"}
                              >
                              <FontAwesomeIcon icon={faTimes} title="Rechazar" />
                              </Button>
                            )}
                          {hasPermission(userPrivileges, "po", ["delete"]) && (
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDeleteClick(po)}
                            >
                              Eliminar
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
              </Table>
              <Pagination
                currentPage={currentPOPage}
                totalPages={totalPOPages}
                onPageChange={setCurrentPOPage}
              />
            </div>
          ) : (
            <p>No hay órdenes de compra pendientes.</p>
          )}
        </>
      )}

      {/* PO Details Modal */}
      {selectedPO && (
        <Modal show={showPODetails} onHide={handleClosePODetails} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Detalles de la Orden de Compra</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><strong>Número:</strong> {selectedPO.po_number}</p>
            <p><strong>Comprador:</strong> {selectedPO.buyer}</p>
            <p><strong>Descripción:</strong> {selectedPO.description}</p>
            <p><strong>Proveedor:</strong> {vendors.find((prev) => prev.vendor_id === selectedPO.vendor_id)?.vendor_name}</p>
            <p><strong>Términos de Pago:</strong> {selectedPO.payment_method}</p>
            <p>
              <strong>Total:</strong> 
              <span className="text-right">
                {new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(selectedPO.total_amount)}
              </span>
            </p>
            {poItemsForModal && poItemsForModal.length > 0 ? (
              <>
                <h5>Artículos</h5>
                <Table bordered size="sm" className="compressedTable">
                  <thead>
                    <tr>
                      <th>Código</th>
                      <th>Cantidad</th>
                      <th>Descripción</th>
                      <th>Costo Presupuesto</th>
                      <th>Costo Unitario</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {poItemsForModal.map((item, idx) => (
                      <tr key={idx}>
                        <td data-label="Código">{item.item_code}</td>
                        <td className={getQuantityClassName(item.quantity, item.budget_quantity,item.inquiry_quantity)}>{item.quantity}</td>                        
                        <td data-label="Descripción">{item.description}</td>
                        <td data-label="Presupuesto" className="text-right text-centered" style={{ backgroundColor: "#eeeeee" }}>
                          {new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(parseFloat(item.budget_cost) || 0)}
                        </td>
                        <td data-label="C/U" className={item.unit_cost > item.budget_cost ? "text-right text-centered background-red" : "text-right text-centered"}>
                          {new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(parseFloat(item.unit_cost) || 0)}
                        </td>
                        <td data-label="Total" className="text-right text-centered">
                          {new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(parseFloat(item.total_cost) || 0)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            ) : (
              <p>No se encontraron artículos para esta orden.</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClosePODetails}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Confirmation Modal */}
      <ConfirmationDialog
        show={showConfirmDelete}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
        message="¿Seguro que deseas eliminar este presupuesto?"
      />
      <ScrollToTopButton />
    </Container>
  );
};

export default ApprovalPage;
