import React, { useEffect, useState ,useRef} from "react";
import { useAlert } from "../components/Alerts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from 'react-toastify';
import ConfirmationDialog from "../components/ConfirmationDialog";
import Select from "react-select";
import ScrollToTopButton from "../components/ScrollTop";
import { hasAccess } from "../utils/AccessControl";
import { activeModules } from "../config/systemConfig";
import {translate} from "../types/language-es";
import FilterInquiry from "../components/FilterInquiry";
import { purDECIMALS,purTOLERANCE } from "../config/systemConfig";
import {
  faExclamationTriangle,
  faEye,
  faCheck,
  faPrint,
  faEdit,
  faTrash,
  faBoxOpen,
  faBox,
  faMoneyBillWave
} from "@fortawesome/free-solid-svg-icons";
import {
  Table,
  Button,
  Form,
  Alert,
  Container,
  Modal,
} from "react-bootstrap";
import {
  getProjects,
  getApprovedInquiries,
  getPOs,
  getVendors,
  getUsers,
  addPO,
  deletePO,
  approvePO,
  generatePOPDF,
  updatePO,
  receivePayPO,
  getPOItems, // Make sure this API function is implemented and exported from your services/api
} from "../services/api";

const PORequestPage = ({ userId , userPrivileges,rolePrivileges }) => {
  // Existing state variables
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [approvedInquiries, setApprovedInquiries] = useState([]);
  const [POs, setPOs] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [users, setUsers] = useState([]);  
  const [poItems, setPOItems] = useState([]);
  const [newPO, setNewPO] = useState(null);  
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmBudget, setShowConfirmBudget] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const { alert, showAlert, clearAlert } = useAlert();
  const quantityRefs = useRef([]);
  const [confirmationInput, setConfirmationInput] = useState("");
  const [outOfBudget,setOutOfBudget] = useState(false);
  const editSectionRef = useRef(null);
  const [budgetRemainingQuantity,setBudgetRemainingQuantity] = useState(false);

  // -------------------------
  // FILTER STATES
  // -------------------------
  
  const [filterDescription, setFilterDescription] = useState("");
  const [filterRequestor, setFilterRequestor] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterFollowStatus, setFilterFollowStatus] = useState("");
  

  // New state for the PO details modal
  const [showPODetails, setShowPODetails] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmApprove, setShowConfirmApprove] = useState(false);
  const [poToApprove, setPoToApprove] = useState(null);

  const [selectedPO, setSelectedPO] = useState(null);
  const [poItemsForModal, setPOItemsForModal] = useState([]);
  const [editingPO, setEditingPO] = useState(null); // Track selected PO for editing

  // Fetch projects on load
  useEffect(() => {
    showAlert(null);
    const fetchProjects = async () => {
      const projectsData = await getProjects({user_id:userId});
      setProjects(projectsData.data);
    };
    fetchProjects();
  }, []);

  // Fetch POs and approved inquiries when a project is selected
  useEffect(() => {
    const fetchProjectData = async () => {
      if (!selectedProjectId) return;

      const [POsData, inquiriesData] = await Promise.all([
        getPOs({project_id:selectedProjectId}),
        getApprovedInquiries(selectedProjectId),
      ]);

      setPOs(POsData);
      setApprovedInquiries(inquiriesData.data || []);
    };

    fetchProjectData();
  }, [selectedProjectId]);


  const applyFilters = async() => {

    const queryParams = {
         
      ...(selectedProjectId && { project_id: selectedProjectId }),            
      ...(filterRequestor && { buyerId: filterRequestor }),
      ...(filterStatus && { filter: filterStatus }),
      ...(filterFollowStatus && { followStatus: filterFollowStatus }),
      // You can add similar filters if needed
    };

    const [POsData, inquiriesData] = await Promise.all([
      getPOs(queryParams),
      getApprovedInquiries(selectedProjectId),
    ]);

    setPOs(POsData);
    setApprovedInquiries(inquiriesData.data || []);
  };


  // Fetch vendors and users for dropdowns
  useEffect(() => {
    const fetchDropdownData = async () => {
      const usersData = await getUsers();
      const vendorsData = await getVendors();
      setVendors(vendorsData.data);
      setUsers(usersData);
    };
    fetchDropdownData();
  }, []);

  // Add a new PO with empty items
  const handleAddNewPO = () => {
    setNewPO({
      po_number: "",
      buyerId: "",
      vendorId: "",
      status_id: "pendiente",
      payment_method : "efectivo",
    });
    setPOItems([]);
  };

  // Add a new PO item
  const handleAddPOItem = () => {
    setPOItems((prevItems) => [
      ...prevItems,
      {
        inquiry_id: "",
        quantity: 0,
        remaining_quantity: 0,
        unit_cost: 0,
        budget_cost: 0,
        item_code: "",
        total_cost: 0,
      },
    ]);
    quantityRefs.current.push(React.createRef());
  };

  
const handleEditPO = async (po) =>  {
  setEditingPO(po); // Set selected PO for editing  
  setNewPO({
    po_number: po.po_number,
    description: po.description,
    buyerId: po.buyerId,
    vendorId:po.vendor_id,
    shipping_address: po.shipping_address,
    payment_method:po.payment_method
  });
  const queryParams = {        
    po_id:po.po_id,
    project_id:selectedProjectId
  }
  const [response] = await Promise.all([
    getPOs(queryParams),   
  ]);
  
  
  const inquiry_id = response[0].inquiry_id
  
  const updatedItems = [...response];
  
  const itemTotals = updatedItems.reduce((acc, item) => {
    if (!acc[item.item_code]) {
      acc[item.item_code] = 0;
    }
    acc[item.item_code] += item.quantity;
    return acc;
  }, {});

  
  const extendedPOItems = response.map((prev) => {
    const inquiry = approvedInquiries.find(
      (inq) => inq.inquiry_id === Number(prev.inquiry_id)
    );
    const totalOrdered = itemTotals[prev.item_code] || 0; // Get total ordered quantity
    const remainingQty = inquiry.remaining_quantity - totalOrdered; // Calculate remaining quantity
  
    return {
      ...prev,
      unitCost: prev.unit_cost,
      initial_remaining_quantity: inquiry.remaining_quantity , // Assuming this exists in response
      remaining_quantity: remainingQty,
    };
  });

  setPOItems(extendedPOItems || []);
  setTimeout(() => {
    editSectionRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  }, 100);
};

const showMessage = (message,type = "success") => {
  // Display a success toast message
  switch (type){
    case "error":
        toast.error(message);
    break;
    case "info":
        toast.info(message);
    break;
    case "warn":
        toast.warn(message);
    break;
    default: toast.success(message);
  }
};

const recalculateItems = ((inquiry_id, index,initial_remaining_quantity,updatedItems) => {
  const initialAvailable = initial_remaining_quantity;
  const cumulativeQuantity = updatedItems.reduce((sum, currentItem, idx) => {
    if (
      currentItem.inquiry_id === inquiry_id &&
      idx !== index // Exclude the current row being edited
    ) {
      return sum + currentItem.quantity;
    }
    return sum;
  }, 0);
  const newRemaining = initialAvailable  - cumulativeQuantity ;          
  const recalculatedUpdatedItems = updatedItems.map((item,idx) => {
      if(item.inquiry_id === inquiry_id &&  idx !== index) { 
        return { ...item, remaining_quantity: newRemaining}; 
      } 
      return item;
  });
  return recalculatedUpdatedItems;
});


  // Update PO item 
  const handlePOItemChange = (index, field, value,remaining_quantity = 0,getFocus = false) => {
    const updatedItems = [...poItems];
    const item = updatedItems[index];
        
    if (field === "inquiry_id") {
      if (!value) {
      

        const inquiry_id =  updatedItems[index].inquiry_id 
        const initial_remaining_quantity = updatedItems[index].initial_remaining_quantity;
        updatedItems[index] = {
          ...item,
          inquiry_id: null,
          description: "",
          unitCost: 0,
          budget_cost: 0,
          item_code: "",
          quantity: 0,
          initial_remaining_quantity: 0,
          remaining_quantity: 0,
          total_cost: 0,
        };
                                
        const recalculatedUpdatedItems = recalculateItems(inquiry_id,index,initial_remaining_quantity,updatedItems);
        setPOItems(recalculatedUpdatedItems);
        
        return;
      }

      const inquiry = approvedInquiries.find(
        (inq) => inq.inquiry_id === Number(value)
      );


      const cumulativeQuantity = updatedItems.reduce((sum, currentItem, idx) => {
        // Compare each row's item_id to the item_id of the row at "index"
        if (currentItem.inquiry_id === inquiry?.inquiry_id && idx != index) {
          return sum + currentItem.quantity;
        }
        return sum;
      }, 0);
  

      updatedItems[index] = {
        ...item,
        inquiry_id: inquiry.inquiry_id,
        description: inquiry.description,
        unitCost: inquiry.unitCost,
        budget_cost: inquiry.unitCost,
        item_code: inquiry.costCode,
        quantity: 0,
        initial_remaining_quantity: inquiry.remaining_quantity ,
        remaining_quantity:inquiry.remaining_quantity - cumulativeQuantity,
        total_cost: 0,
      };
      setPOItems(updatedItems);
      if (getFocus) setTimeout(() => quantityRefs.current[index]?.current?.focus(), 0);
    } else if (field === "quantity") {
      
      const regex = /^(?:\d+(?:\.\d*)?|\.\d+)$/;

      let newQuantity;
      if (value === "") {
        // Allow empty value
        newQuantity = "";
      } else if (regex.test(value)) {
        // If the value is in a valid regular number format, convert it to a float
        newQuantity = parseFloat(value);
      } else {
        // Otherwise, you can either set a default, ignore the change, or notify the user.
        newQuantity = 0;
      }
      const cumulativeQuantity = updatedItems.reduce((sum, currentItem, idx) => {
        if (
          currentItem.inquiry_id === updatedItems[index].inquiry_id &&
          idx !== index // Exclude the current row being edited
        ) {
          return sum + currentItem.quantity;
        }
        return sum;
      }, 0);

      const inquiry = approvedInquiries.find(
        (inq) => inq.inquiry_id === item.inquiry_id
      );
      const initialAvailable = updatedItems[index].initial_remaining_quantity ;//ARFA: this is causing an issue when initial_remaining_quantity is cero || remaining_quantity;
      const newRemaining = initialAvailable  - cumulativeQuantity  - newQuantity;
      updatedItems[index] = {
        ...item,
        quantity: newQuantity,
        remaining_quantity: newRemaining,
        total_cost: item.unitCost * newQuantity,
      };

      const recalculatedUpdatedItems = updatedItems.map((item,idx) => {
        if(item.inquiry_id === updatedItems[index].inquiry_id &&  idx !== index) { 
           return { ...item, remaining_quantity: newRemaining}; 
        } 
        return item;
     });
     setPOItems(recalculatedUpdatedItems);
    }
    else if (field === "unitCost"){
      updatedItems[index] = {
        ...item,
        unitCost: Number(value) || item.budget_cost,        
        total_cost: Number(value) * updatedItems[index].quantity,
      };
      setPOItems(updatedItems);
    }

    
  };



  const handleSaveOrUpdatePO = async (outOfBudget = false) => {
    if (!newPO.po_number || !newPO.buyerId || !newPO.vendorId || poItems.length === 0) {   
      showMessage("Por favor complete todos los campos obligatorios.","warn");
      return;
    }
  
    try {
      const isOverQuantity = poItems.some(item => item.remaining_quantity < 0);
      const isOverBudget = poItems.some(item => item.totalCost > item.budget_cost);
      const updatedPOItems = poItems.map((item) => {
        const matchingInquiry = approvedInquiries.find(inq => inq.inquiry_id === item.inquiry_id);
        return {
          ...item,
          unit_id: matchingInquiry ? matchingInquiry.unit_id : null, // Assign unit_id if found
        };
      });

      if (editingPO) {
        // Updating an existing PO
        const returnedValue = await updatePO(selectedProjectId,editingPO.po_id,{
          po_id: editingPO.po_id,
          ...newPO,
          items: updatedPOItems,
          userId,
        });
  
        const recalculatedTotal = poItems.reduce((sum,item) => sum + (item.total_cost || 0),0);
        
        // Update state after editing
        setPOs((prevPOs) =>
          prevPOs.map((item) =>
            item.po_id === editingPO.po_id ? { ...item, ...newPO,total_amount: recalculatedTotal,over_budget_flag:isOverBudget,over_quantity_flag:isOverQuantity}  : item
          )
        );
  
        showAlert("Orden de compra actualizada correctamente.", "success");
        setEditingPO(null); // Reset editing state
      } else { // NEW PO

        const invalidItem = poItems.find((item) => item.remaining_quantity < 0);
        const zeroQuantity = poItems.map((items, index) => {
        if (items.quantity <= 0){      
          return true;
        }
        });

        if (zeroQuantity[0]){
          showAlert("Introduzca una cantidad mayor a cero","warning");   
          return;
        }
        if (invalidItem && !outOfBudget) {
          setShowConfirmBudget(true)
          return;
        }

        const totalAmount = poItems.reduce((sum, item) => sum + item.total_cost, 0);
        // Creating a new PO
        const returnedValue = await addPO(selectedProjectId, {
          ...newPO,
          items: updatedPOItems,
          userId,
          totalAmount,
        });
  
        showAlert("Orden de compra agregada correctamente.", "success");
        
        const poID = returnedValue.po_id;
        setPOs((prevPOs) => [...prevPOs, {...newPO,po_id : poID,items:poItems,buyer:users.find(user => user.user_id === parseInt(userId))?.user_name,total_amount:totalAmount,status:"Pendiente",vendor_id:parseInt(newPO.vendorId),over_budget_flag:isOverBudget,over_quantity_flag:isOverQuantity,budget_quantity:newPO.budgetQuantity}]);              
      }
  
      setNewPO(null);
      setPOItems([]);
    } catch (error) {      
      console.error("Error al guardar la orden de compra:", error);
      if (error.response.data.error?.includes("Duplicated entry: PO number")) {
        showAlert("Error: Número de PO duplicada","danger");        
      } else {
        showMessage(translate(error.response.data.error),"error");                
      }
    }
  };


  
  const handleConfirmReceived = (po) =>
  {
    handleFollowPOStatus(po,po.followStatus ^ 2)  
  }

  const handleConfirmPayment   = (po) =>
    {
      handleFollowPOStatus(po,po.followStatus ^ 1)
    }
  // Save the new PO
  const handleSaveNewPO = async (outOfBudget = false) => {
    if (
      !newPO.po_number ||
      !newPO.buyerId ||
      !newPO.vendorId ||     
      poItems.length === 0
    ) {
      showAlert("Por favor complete todos los campos obligatorios.");
      
      return;
    }

    const invalidItem = poItems.find((item) => item.remaining_quantity < 0);


    const zeroQuantity = poItems.map((items, index) => {
     if (items.quantity <= 0){      
      return true;
     }
    });

    if (zeroQuantity[0]){
      showAlert("Introduzca una cantidad mayor a cero","warning");   
      return;
    }
    if (invalidItem && !outOfBudget) {
      setShowConfirmBudget(true)
      return;
    }

    const totalAmount = poItems.reduce((sum, item) => sum + item.total_cost, 0);

    try {
      const returnedValue = await addPO(selectedProjectId, {
        ...newPO,
        items: poItems,
        userId,
        totalAmount,
      });

      showAlert("Orden de compra agregada correctamente.","success");
      const poID = returnedValue.po_id;
      setPOs((prevPOs) => [...prevPOs, {...newPO,po_id : poID,items:poItems,buyer:users.find(user => user.user_id === parseInt(userId))?.user_name,total_amount:totalAmount,status:"Pendiente"}]);      
      setNewPO(null);      
    } catch (error) {
      console.error("Error al guardar la orden de compra:", error);
      if (error.response.data.error.includes("Duplicated entry: PO number")) {
        showAlert("Error: Número de PO duplicada","danger");        
      } else {
        showAlert("Error al guardar la orden de compra.","danger");
        
      }
    }
  };

  

  
  // New handler: Open modal and retrieve PO items for the selected PO
  const handleShowPODetails = async (po) => {
    setSelectedPO(po);
    try {
      // Retrieve the PO items from the backend          
      const queryParams = {        
        po_id:po.po_id,
        project_id:selectedProjectId
      }
      const [response] = await Promise.all([
        getPOs(queryParams),
       
      ]);

      // Assume the response returns an array of PO items
      setPOItemsForModal(response);
    } catch (error) {
      console.error("Error retrieving PO items:", error);
      setPOItemsForModal([]);
    }
    setShowPODetails(true);
  };

  const handleClosePODetails = () => {
    setShowPODetails(false);
    setSelectedPO(null);
    setPOItemsForModal([]);
  };



  const handleConfirmDelete = () => {
    // Execute deletion for itemToDelete
    handleDeletePO(itemToDelete);
    
    // After deletion:
    setShowConfirmDelete(false);
    setItemToDelete(null);
  };

  const handleCancelDelete = () => {
    setShowConfirmDelete(false);
    setItemToDelete(null);
  };

  const handleApproveClick = (po) => {
    setPoToApprove(po);
    if (po.over_quantity_flag || po.over_budget_flag || po.over_inq_quantity_flag) {
      setShowConfirmApprove(true);
    } else {
      handleApprovePO(po)      
    }
  };

  const handleConfirmBudget = (outOfBudget) => {    
    handleSaveOrUpdatePO(outOfBudget);        
    setShowConfirmBudget(false);    
  };

  const handleCancelBudget = () => {
    setShowConfirmBudget(false);    
  };

  
  const handleDeleteClick = (item) => {
    setItemToDelete(item);
    setShowConfirmDelete(true);
  };

  const handleDeletePO = async (po) => {
  
    try{    
      const queryParams = {
        po_id:po.po_id,
        user_id:userId,
      }
      const response = await deletePO(selectedProjectId,queryParams);      
      const updatedPOList = POs.filter((prev) => (prev.po_id !== po.po_id));
      setPOs(updatedPOList);

      //update inquiries
      const inquiriesData = await getApprovedInquiries(selectedProjectId);      
      setApprovedInquiries(inquiriesData.data || []);

      showAlert("PO eliminada correctamente.","success");
      setConfirmationInput("");
    } catch (error) {
      console.error("Error deleting po:", error);
      showAlert("Error al eliminar la PO. Inténtalo de nuevo.","danger");
    }
  }

  const handleDeleteRow = (indexToDelete) => {
    const updatedItems = [...poItems];
    const recalculatedUpdatedItems = recalculateItems(poItems[indexToDelete].inquiry_id,indexToDelete,poItems[indexToDelete].initial_remaining_quantity,updatedItems);
    setPOItems(recalculatedUpdatedItems);
    setPOItems((prevItems) => prevItems.filter((_, index) => index !== indexToDelete));
  };
  
  // New handler: Approve PO by calling updatePO API
  const handleApprovePO = async (po) => {
    try {
  
      const response = await approvePO(selectedProjectId,po.po_id,userId);
      setPOs((prevPOs) =>
        prevPOs.map((item) =>
          item.po_id === po.po_id ? { ...item, status: "Autorizado" } : item
        )
      );    
        
      const inquiriesData = await getApprovedInquiries(selectedProjectId);      
      setApprovedInquiries(inquiriesData.data || []);     
      setShowConfirmApprove(false);
      showAlert("Orden de compra aprobada correctamente.","success");      
    } catch (error) {
      console.error("Error approving PO", error);      
      showMessage(translate(error.toString()),"error");       
      setShowConfirmApprove(false);
    }
  };

  const handleFollowPOStatus = async (po,extStatus) => {
    try {
      
      const data = {userId:parseInt(userId), followStatus:extStatus};
      const response = await receivePayPO(selectedProjectId,po.po_id,data);
      setPOs((prevPOs) =>
        prevPOs.map((item) =>
          item.po_id === po.po_id ? { ...item, followStatus: extStatus } : item
        )
      );    
              
      showAlert("Orden de compra aprobada correctamente.","success");      
    } catch (error) {
      console.error("Error approving PO", error);
      showAlert("Error al aprobar la orden de compra.","danger");            
    }
  };
  
  const handleCancelEdit = () => {
    setEditingPO(null); // Reset the editing state
    setNewPO(null); // Clear the form
    setPOItems([]); // Reset PO items    
  };

  // New handler: Print PO (customize as needed)
  const handlePrintPO = async(po) => {
    try {
      const response = await generatePOPDF(selectedProjectId,{po_id:po.po_id,user_id:userId});
      const link = document.createElement("a");
      link.href = response.pdf_url;
      link.download = `PO_${po.po_number}.pdf`; // Optional: Suggested filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
              
    } catch (error) {
      console.error("Error approving PO", error);
      showAlert("Error al aprobar la orden de compra.","danger");      
    }
  };

  

  // Helper function to determine the CSS class based on quantity and remaining budget quantity
const getQuantityClassName = (quantity, budgetRemainingQuantity,inquiryQuantity) => {
  const diff = budgetRemainingQuantity - quantity;
  if (diff < 0) {
    return "text-centered background-red"; // Over budget
  } else if (inquiryQuantity - quantity < 0) {
    return "text-centered over-budget-warning"; // Exactly equal
  } else {
    return "text-centered"; // Within budget (or you can add another class if desired)
  }
};

  return (
    <Container className="mt-4">
      <h1>Órdenes de Compra</h1>
      {/* Alert */}
        {alert.message && (
        <Alert variant={alert.variant} onClose={clearAlert} dismissible>
          {alert.message}
        </Alert>
      )}

      {/* Filter Accordion */}
      <FilterInquiry
        filterProject={selectedProjectId}
        setFilterProject={setSelectedProjectId}
        filterDescription={filterDescription}
        setFilterDescription={setFilterDescription}
        filterRequestor={filterRequestor}
        filterStatus = {filterStatus}
        filterFollowStatus = {filterFollowStatus}
        setFilterFollowStatus = {setFilterFollowStatus}
        setFilterStatus = {setFilterStatus}
        setFilterRequestor={setFilterRequestor}
        applyFilters={applyFilters}
        projects = {projects}
        users = {users}        
      />
      
      {/* POs Table */}
      {POs.length > 0 && (
        <>
          <h3 className="mt-3">Órdenes de Compra Existentes</h3>
          <Table bordered hover className="compressedTable">
            <thead>
              <tr>
                <th>Número</th>
                <th>Comprador</th>
                <th>Descripción</th>
                
                <th>Total</th>
                <th>Estatus</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {POs.map((po) => (
                <tr key={po.po_id}>
                  <td data-label="Orden" >{po.po_number}</td>
                  <td data-label="Comprador" >{po.buyer}</td>
                  <td data-label="Descripción" >{po.description}</td>
                  
                  <td data-label="Costo Unitario" className="text-right">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(po.total_amount)}</td>                  
                  <td data-label="Estatus" >
                    <div>{po.status}</div>
                    <div style={{fontSize:"0.85em", color:"#666"}} >{users.find((item) => item.user_id === po.approved_by)?.user_name}</div>
                  </td>

                  <td className="action-buttons">
                    {/* View PO Details Button */}
                    <Button
                      variant="info"
                      size="sm"
                      onClick={() => {setBudgetRemainingQuantity(po.budget_quantity - po.spent_quantity);handleShowPODetails(po)}}
                      className="me-1"
                    >
                      <FontAwesomeIcon
                        icon={faEye}
                        title="Ver Detalles PO"
                      />
                    </Button>
                    {/* Edit Button */}
                    <Button
                      variant="warning"
                      size="sm"
                      disabled = {po.status == "Autorizado"|| po.status == "Cancelado"} 
                      onClick={() => handleEditPO(po)}
                      className="me-2"
                    >
                      <FontAwesomeIcon icon={faEdit} title="Editar" />
                    </Button>
                    {/* Approve PO Button */}                    
                    <Button
                      variant="success"
                      size="sm"
                      onClick={() => handleApproveClick(po)}
                      className="me-1"
                      disabled = {po.status !== "Pendiente"}
                    >                      
                    <FontAwesomeIcon
                      icon={faCheck}
                      title="Aprobar PO"
                    />
                    </Button>
                                      
                    {/* Confirm PO Material Received Button */}                    
                    <Button
                      variant= { po.followStatus & 2 ?"secondary":"success"}
                      size="me"
                      onClick={() => handleConfirmReceived(po)}
                      className="me-1"
                      disabled={po.status !== "Autorizado"    } // Only allow if PO is authorized
                  >                      
                      <FontAwesomeIcon
                        icon={po.followStatus & 2 ? faBoxOpen:faBox}
                        title={ po.followStatus & 2 ?"Cancelar Rrecepción":"Confirmar Recepción"}
                      />
                    </Button>

                    {/* Confirm PO Payment Button */}
                    <Button
                      variant= { po.followStatus & 1 ? "secondary":"success"}
                      size="me"
                      onClick={() => handleConfirmPayment(po)}
                      className="me-1"
                      disabled={po.status !== "Autorizado" } // Only allow if PO is authorized
                    >
                      <FontAwesomeIcon
                        icon={faMoneyBillWave}
                        title={ po.followStatus & 1 ?"Cancelar Pago":"Confirmar Pago"}
                      />
                    </Button>

                      {/* Print PO Button */}
                      <Button
                      variant="secondary"
                      size="me"
                      disabled = {po.status !== "Autorizado"}
                      onClick={() => handlePrintPO(po)}
                      className="me-1 steelblue"
                    >
                      <FontAwesomeIcon
                        icon={faPrint}
                        title="Imprimir PO"
                      />
                    </Button>
                    
                    {/* Delete Button (existing functionality) */}
                    {hasAccess(userPrivileges, rolePrivileges, "po:delete", activeModules, "purchasing") && (
                    <Button
                      className="btn-delete"
                      variant="danger"
                      size="sm"
                      onClick={()=> {handleDeleteClick(po)}}
                    >
                      Eliminar
                    </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}

      {/* Add New PO */}
      
  
      {!editingPO && (
      <Button className="mt-3" onClick={handleAddNewPO}>
        Nueva Orden de Compra
      </Button>
      )}
      {(newPO || editingPO) && (
        <>          
          <div ref={editSectionRef} className="mt-4"></div>
          {editingPO ? (
            <h3 className="mt-3">Editar Orden de Compra</h3>
          ) : (
            <h3 className="mt-3">Nueva Orden de Compra</h3>
          )}
          <Form>
            <Form.Group>
              <Form.Label>Número de Orden</Form.Label>
              <Form.Control
                type="text"
                value={newPO.po_number}
                onChange={(e) =>
                  setNewPO({ ...newPO, po_number: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                type="text"
                value={newPO.description}
                onChange={(e) =>
                  setNewPO({ ...newPO, description: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Comprador</Form.Label>
              <Form.Select
                value={newPO.buyerId}
                onChange={(e) =>
                  setNewPO({ ...newPO, buyerId: e.target.value })
                }
              >
                <option value="">-- Seleccionar --</option>
                {users.filter((item) => item.role_id === 5).map((user) => (
                  <option key={user.user_id} value={user.user_id}>
                    {user.user_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Proveedor</Form.Label>
              <Form.Select
                value={newPO.vendorId }
                onChange={(e) =>
                  setNewPO({ ...newPO, vendorId: e.target.value })
                }
              >
                <option value="">-- Seleccionar --</option>
                {vendors.map((vendor) => (
                  <option key={vendor.vendor_id} value={vendor.vendor_id}>
                    {vendor.vendor_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Dirección de envío</Form.Label>
              <Form.Control
                type="text"
                value={newPO.shipping_address}
                onChange={(e) =>
                  setNewPO({ ...newPO, shipping_address: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group>
            <Form.Label>Método de pago</Form.Label>
            <div>
              {["Efectivo", "Transferencia", "Tarjeta", "Cheque"].map((method) => (
                <Form.Check
                  key={method.toLocaleLowerCase()}
                  type="radio"
                  label={method}
                  name="payment_method" // Ensures only one option can be selected
                  value={method.toLowerCase()}
                  checked={newPO.payment_method === method.toLowerCase()}
                  onChange={(e) =>
                    setNewPO({ ...newPO, payment_method: e.target.value })
                  }
                  inline
                />
              ))}
            </div>
          </Form.Group>
          </Form>

          {/* PO Items */}
          <Button className="mt-3" onClick={handleAddPOItem}>
            Agregar Artículo
          </Button>
          <Table bordered className="mt-3 compressedTable">
            <thead>
              <tr>
                <th>Requisición</th>
                <th>Presupuesto</th>
                <th>Cantidad</th>
                <th>Costo Presupuesto</th>
                <th>Costo Unitario</th>
                <th>Total</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {poItems.map((item, index) => {
                const isOverQuantity = item.remaining_quantity < 0;
                const isOverBudget = item.unitCost > item.budget_cost; 
                return (
                  <tr
                    key={index}
                    className={isOverQuantity ? "over-quantity" : ""}
                  >
                    <td>
                    <div className="autocomplete-container">
                    <Select
                      options={approvedInquiries // TODO: add an inquiry.quantity field to track the quantity ordered by inq
                        .filter((inq) => inq.status === "Autorizado"  && inq.remaining_inq_quantity > 0)
                        .map((inq) => ({ 
                          remaining_inq_quantity:inq.remaining_inq_quantity,
                          category: inq.category, 
                          description: inq.description,
                          value: inq.inquiry_id, 
                          label: (inq.description + (inq.notes?"("+ inq.notes+")":"") + (inq.category?`-${inq.category}`:"") +  inq.remaining_inq_quantity )})) 
                      }

                      
                      value={
                        approvedInquiries.find((inq) => inq.inquiry_id === item.inquiry_id) 
                          ? {
                              value: approvedInquiries.find((inq) => inq.inquiry_id === item.inquiry_id).inquiry_id,
                              description: approvedInquiries.find((inq) => inq.inquiry_id === item.inquiry_id)?.description,                              
                              label: approvedInquiries.find((inq) => inq.inquiry_id === item.inquiry_id)?.category
                                ? `${approvedInquiries.find((inq) => inq.inquiry_id === item.inquiry_id)?.description} - ${approvedInquiries.find((inq) => inq.inquiry_id === item.inquiry_id)?.category}`
                                : approvedInquiries.find((inq) => inq.inquiry_id === item.inquiry_id)?.description
                            }
                          : null
                      }
                      

                 
                      formatOptionLabel={(option) => (
                        <div>
                          {option.description}                          
                          {option.category && <>-<strong><small>{option.category}</small></strong></>}
                          {option.remaining_inq_quantity &&  `- Cant:${option.remaining_inq_quantity}` }
                        </div>
                      )}
                     
                      filterOption={(option, inputValue) => {
                        return option.label.toLowerCase().includes(inputValue.toLowerCase());
                      }}


                      onChange={(selectedOption) => {
                        if (!selectedOption) {
                          // Clear the selection when x is pressed
                          handlePOItemChange(index, "inquiry_id", null, true);
                        } else {
                          // Update with the selected value
                          handlePOItemChange(index, "inquiry_id", selectedOption.value, true);
                        }
                      }}
                      placeholder="Buscar Requisición..."
                      className="fixed-autocomplete"
                      menuPortalTarget={document.body} // Renders dropdown at root level
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensures dropdown is always on top
                        menu: (base) => ({ ...base, zIndex: 9999 }) // Extra safeguard
                      }}
                      isClearable
                      noOptionsMessage={() => "Sin requisiciones aprobadas"}
                    />
                    </div>
                    </td>

                    <td data-label="Presupuesto" className="text-right">
                     
                     {new Intl.NumberFormat("es-MX",                       
                        {minimumFractionDigits: purDECIMALS, maximumFractionDigits: purDECIMALS}
                      ).format(item.remaining_quantity)}
                    </td>

                    <td >
                      <Form.Control
                        type="number"
                        ref={quantityRefs.current[index]} 
                        value={item.quantity}
                        className =  { approvedInquiries.find((inq) => inq.inquiry_id === item.inquiry_id)?.budgetQuantity < item.quantity? "over-budget-warning":""}
                        onChange={(e) => {
                          const inputValue = Number(e.target.value) || "";
                          if (inputValue >= 0) {
                            handlePOItemChange(index, "quantity", inputValue,item.remaining_quantity);
                          }
                        }}
                        onBlur={(e) => {
                          if (e.target.value === "" || Number(e.target.value) < 0) {
                            handlePOItemChange(index, "quantity", 0,item.remaining_quantity);
                          }
                        }}
                        min="0"
                      />
                    </td>
                                        
                    <td data-label="C/U presupuesto"  className="text-right">
                      {new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                      }).format(item.budget_cost)}
                    </td>
                    <td  data-label="C/U" className="text-right">
                      <Form.Control
                        type="number"
                        value={item.unitCost}
                        className={isOverBudget? "over-budget text-right" : "text-right"}
                        onChange={(e) => {
                          const inputValue = Number(e.target.value) ;
                          if (inputValue >= 0) {
                            handlePOItemChange(index, "unitCost", inputValue);
                          }
                        }}
                        onBlur={(e) => {
                          if (e.target.value === "" || Number(e.target.value) < 0) {
                            handlePOItemChange(index, "unitCost", 0);
                          }
                        }}
                        min="0"
                      />
                    </td>
                                        
                    <td data-label="Total"  className="text-right">
                      {new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                      }).format(item.total_cost)}
                    </td>
                    <td className="text-center">
                      {isOverQuantity && (
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          color="red"
                          title="La cantidad solicitada excede la cantidad disponible"
                        />
                      )}
                      <Button variant="danger" size="xs" className = "xsmall-icon" onClick={() => handleDeleteRow(index)} title="Eliminar">
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Button
            variant={editingPO ? "warning" : "success"}
            className="mt-3"
            onClick={() => handleSaveOrUpdatePO()}
          >
            {editingPO ? "Actualizar Orden" : "Enviar Orden"}
          </Button>
          {editingPO && (
            <Button  className="mt-3" variant="secondary" onClick={handleCancelEdit}>
              Cancelar Edición
            </Button>
          )}
        </>
      )}

      {/* PO Details Modal */}
      {selectedPO && (
        <Modal show={showPODetails} onHide={handleClosePODetails} size="lg" budgetRemainingQuantity={budgetRemainingQuantity}>
          <Modal.Header closeButton>
            <Modal.Title>Detalles de la Orden de Compra</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <strong>Número:</strong> {selectedPO.po_number}
            </p>
            <p>
              <strong>Comprador:</strong> {selectedPO.buyer}
            </p>
            <p>
              <strong>Descripción:</strong> {selectedPO.description}
            </p>
            <p>
              <strong>Proveedor:</strong> {vendors.find((prev)  => prev.vendor_id === selectedPO.vendor_id)?.vendor_name}
            </p>
            <p>
              <strong>Dirección de envío:</strong> {selectedPO.shipping_address}
            </p>
            <p>
              <strong>Método de pago:</strong> {selectedPO.payment_method}
            </p>
            
            <p>
              <strong>Total: </strong> 
               <span className="text-right">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(selectedPO.total_amount)}</span>              
            </p>
            {/* Render PO items retrieved from the API */}
            {poItemsForModal && poItemsForModal.length > 0 ? (
              <>
                <h5>Artículos</h5>
                <Table bordered size="sm">
                  <thead>
                    <tr>
                      <th>Código</th>
                      <th>Cantidad</th>
                      <th>Descripción</th>                      
                      <th>Costo Presupuesto</th>
                      <th>Costo Unitario</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {poItemsForModal.map((item, idx) => (
                      <tr key={idx}>
                        <td>{item.item_code}</td>
                        
                        

                        <td className={getQuantityClassName(item.quantity, item.budget_quantity,item.inquiry_quantity)}>{new Intl.NumberFormat("es-MX",{minimumFractionDigits: purDECIMALS, maximumFractionDigits: purDECIMALS}).format(item.quantity)}</td>
                        <td><div>{item.description} </div>{item.notes && <div style={{fontSize:"0.85em", color:"#666"}}>{item.notes}</div>}</td>                        
                        <td className="text-right text-centered" style = {{ backgroundColor: "#eeeeee" }}>
                          {new Intl.NumberFormat("es-MX", {
                            style: "currency",
                            currency: "MXN",
                          }).format(!isNaN(parseFloat(item.budget_cost))
                            ? parseFloat(item.budget_cost)
                            : 0)}
                        </td>
                        <td className= {item.unit_cost > item.budget_cost?"text-right text-centered background-red":"text-right text-centered"}>                          
                          {new Intl.NumberFormat("es-MX", {                            
                            style: "currency",
                            currency: "MXN",
                          }).format(!isNaN(parseFloat(item.unit_cost))
                            ? parseFloat(item.unit_cost)
                            : 0)}
                        </td>
                        <td className="text-right text-centered">
                          {new Intl.NumberFormat("es-MX", {
                            style: "currency",
                            currency: "MXN",
                          }).format(!isNaN(parseFloat(item.total_cost))
                            ? parseFloat(item.total_cost)
                            : 0)}
                        </td>                        
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            ) : (
              <p>No se encontraron artículos para esta orden.</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClosePODetails}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Confirmation Modal */}
      <ConfirmationDialog
        show={showConfirmDelete}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
        message = "¿Seguro que deseas eliminar este presupuesto?"        
      />
       {/* Confirmation Modal */}
       <ConfirmationDialog
        show={showConfirmBudget}
        onConfirm={handleConfirmBudget}
        onCancel={handleCancelBudget}
        message = "¿Seguro que deseas continuar con la orden fuera de presupuesto?"        
        title = "Orden fuera de presupuesto"
        confirmButton = "Si"
      />

      <ConfirmationDialog
        show={showConfirmApprove}
        title="Aprobación Fuera de Presupuesto"
        message="¿Está seguro de que desea aprobar esta orden de compra?"
        onConfirm={() => handleApprovePO(poToApprove)}
        onCancel={() => setShowConfirmApprove(false)}
        confirmVariant="success"
        confirmButton = "Si"
        confirmLabel="Aprobar"
      />
      <ScrollToTopButton />
      <ToastContainer 
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
     <ToastContainer 
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
    
  );
};

export default PORequestPage;
