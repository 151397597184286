import React, { createContext, useContext, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
// Create the context
const AlertContext = createContext();

// Create a provider component
export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({ message: null, variant: "info" });

  // Function to show an alert
  const showAlert = (message, variant = "info") => {
    setAlert({ message, variant });
  };

  // Function to clear the alert
  const clearAlert = () => {
    setAlert({ message: null, variant: "info" });
  };

  return (
    <AlertContext.Provider value={{ alert, showAlert, clearAlert }}>
      {children}
      <ToastContainer 
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        containerStyle={{
          position: "fixed",
          bottom: "100px", // Increase this value if needed
          left: "0",
          right: "0",
          zIndex: 9999,
        }}
      />
    </AlertContext.Provider>
  );
};

// Custom hook to use the alert context
export const useAlert = () => {
  return useContext(AlertContext);
};

export   const showMessage = (message,type = "success") => {
  // Display a success toast message
  switch (type){
    case "error":
        toast.error(message,{
          style: {
            background:"rgb(255, 238, 238)",
            color: "grey",
            fontSize: "16px",
            zIndex:200,
          },
        });
    break;
    case "info":
        toast.info(message);
    break;
    case "warn":
        toast.warn(message);
    break;
    default: toast.success(message);
  }
};
