import React, { useCallback,useEffect, useState,useRef} from "react";
import { Container, Table, Button, Form, Alert ,Modal,Row,Col,Spinner} from "react-bootstrap";
import { getBanks,getBankTransactions,matchTransaction, getCategories,getProjects,conciliateTransaction,getSystemTransactions, uploadBankStatement,addCashflow,getPatternRules } from "../services/api"; 
import {calculateDateRange} from "../utils/dateUtils";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useIsMobile from "../hooks/useIsMobile";

const BankConciliationPage = ({userId}) => { 
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useIsMobile(); // Use the custom hook
  const tableRef = useRef(null);
  const [bankTransactions, setBankTransactions] = useState([]);
  const [banks, setBanks] = useState([]);
  const [satTransactions, setSatTransactions] = useState([]);
  const [unmatchedTransactions, setUnmatchedTransactions] = useState([]);
  const [matchedTransactions, setMatchedTransactions] = useState([]);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [selectedBankId, setSelectedBankId] = useState("");
  const [projects, setProjects] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState({});
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedItemsToMatch, setSelectedItemsToMatch] = useState([]);
  const [loadingTransactionId,setLoadingTransactionId] = useState(null);
  const [alert, setAlert] = useState(null);
  const [alertVariant, setAlertVariant] = useState('info');
  const [batchProject, setBatchProject] = useState("");
  const [batchDepartment, setBatchDepartment] = useState("");
  const [proposedProjects, setProposedProjects] = useState({});
  const [proposedCategories, setProposedCategories] = useState({});
  const [dateFilter, setDateFilter] = useState("monthPicker"); // Default filter type
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [customSearch,setCustomSearch] = useState("")
  const [totalCargo, setTotalCargo] = useState(0);
  const [totalAbono, setTotalAbono] = useState(0);
  const [emptyEntry,setEmptyEntry] = useState(true);

  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const today = new Date();
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    concept: "",
  });
  
  const [searchFilters, setSearchFilters] = useState({
    filter_type: 'amount'
  });

  const inputRef = useRef(null);
  const nameInputRef = useRef(null);


  const updateDateFilter = (filter, selectedMonth = null) => {
    setDateFilter(filter);
    const { start, end } = calculateDateRange(filter, selectedMonth);
    setFilters({ startDate: start, endDate: end });
  };
  
  const handleMonthChange = (date) => {
    setSelectedMonth(date);
    updateDateFilter("monthPicker", date);
  };



  const handleDateFilterChange = (e) => {
    const filter = e.target.value;
    updateDateFilter (filter);
  };
  

  const recalculateTotals = (transactions) => {
    const newTotalCargo = transactions.reduce((sum, transaction) => sum + (transaction.charge || 0), 0);
    const newTotalAbono = transactions.reduce((sum, transaction) => sum + (transaction.credit || 0), 0);
    setTotalCargo(newTotalCargo);
    setTotalAbono(newTotalAbono);
  };

  const handleMatchTransaction = async (bankTransaction) => {
    try {
      const updatedBankTransaction = {
        ...bankTransaction,
        company_id: 1,
        status:['pending'],
        searchType: searchFilters.filter_type, // Example filter type        
      };
      setIsLoading(true);
      setLoadingTransactionId(bankTransaction.transaction_id);
      const matchResult = await matchTransaction(selectedBankId, updatedBankTransaction);
      if (matchResult.status === 200) {
        setSatTransactions(matchResult.data || []);
        setSelectedTransaction(bankTransaction);
        setShowModal(true);
      } else {
        setSatTransactions([]);
        setSelectedTransaction(null);
      }
    } catch (error) {
      console.error("Error matching transaction:", error);
    }
    finally
    {
      setIsLoading(false);
      setLoadingTransactionId(null);
    }
  };

  const handleSearch = async (filterType,customSearchValue, tolerance) => {
    
    if (!selectedTransaction) return;
  
    try {
      setIsLoading(true) ;
      const updatedBankTransaction = {
        ...selectedTransaction,
        company_id: 1,
        searchType: filterType, // Use the passed filter type
        customSearch : customSearchValue,
        tolerance:tolerance,
        status: filterType === 'pc'?['checked']:['pending']
      };
      
      const matchResult = await matchTransaction(selectedBankId, updatedBankTransaction);
  
      if (matchResult.status === 200) {
        setSatTransactions(matchResult.data || []);
        
        setSearchFilters( {'filter_type':filterType});
        setCustomSearch ("");
      } else {
        setSatTransactions([]);
      }
    } catch (error) {
      console.error("Error fetching matches:", error);
    }
    finally
    {
      setIsLoading(false) ;
    }
  };

const MatchModal = ({ show, onHide, bankTransaction, satTransactions, initialFilterType, onSearch, onMatch, customSearch,
  setCustomSearch}) => {
  const [localFilterType, setLocalFilterType] = useState(initialFilterType || "amount");  
  const [tempCustomSearch, setTempCustomSearch] = useState(customSearch);
  const [tolerance, setTolerance] = useState(0.05);    
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); 
  const potentialMatches = satTransactions || { data: [] };
  
  
  useEffect(() => {
    if (localFilterType === "uuid" && inputRef.current) {
      inputRef.current.focus();
    }
    else if ((localFilterType === "name" && nameInputRef.current)){
      nameInputRef.current.focus();
    }
  }, [localFilterType]);

  useEffect(() => {
    // Sync with the parent state when the modal opens
    if (show) {
      setLocalFilterType(initialFilterType);  
      setTempCustomSearch(customSearch);
    }
  }, [show, initialFilterType,customSearch]);


  useEffect(() => {
    const updateCursor = () => {
      document.body.style.cursor = isLoading ? "progress" : "default";
    };  
    updateCursor();  
    // Cleanup when the component unmounts
    return () => {
      document.body.style.cursor = "default";
    };
  }, [isLoading]);


  // Handle input changes
  const handleInputChange = (e) => {
    const value = e.target.value; // Current input value  
    setTempCustomSearch(value);
    // Enable the button if there is valid input, otherwise disable it
    setIsButtonDisabled(value.trim() === "");
  };



  const handleSearchClick = (e) => {
    e.preventDefault();
    setCustomSearch(tempCustomSearch);   
    onSearch(localFilterType, tempCustomSearch,tolerance);
  };

  if (!bankTransaction) return null;


  
  return (
      <Modal
        show={show}
        onHide={onHide}
        onExited={() => {
          setLocalFilterType(initialFilterType);
          setTempCustomSearch(customSearch);
        }}
        size="lg"
        animation={false}
        
      >
    <Modal.Header closeButton>
      <Modal.Title>Buscar Coincidencia</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        <strong>Transacción Bancaria:</strong> {bankTransaction.concept} -{" "}
        <strong>{new Intl.NumberFormat('es-MX', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(bankTransaction.charge || bankTransaction.credit)}</strong>
      </p>  

      <Form.Group className="mb-3">
      <Form.Label><strong>Filtrar por:</strong></Form.Label>
      <div>
      <div className="d-flex align-items-center">
       <Form.Check
          inline
          type="radio"
          id="searchBy-nouuid"
          name="filter_type"
          label="Sin UUID"
          value='no_uuid'
          checked={localFilterType === "no_uuid"}
          //onChange={(e) => setLocalFilterType(e.target.value)}
          onChange = {handleSearchFilterChange}
        />    
        <Form.Check
          inline
          type="radio"
          id="searchBy-amount"
          name="filter_type"
          label="Monto"
          value='amount'
          checked={localFilterType === "amount"}
          //onChange={(e) => setLocalFilterType(e.target.value)}
          onChange = {handleSearchFilterChange}
          />
           {localFilterType === "amount" && (
              <>
                <Form.Label className="ms-2 me-2 mb-0">Tolerancia (-/+)</Form.Label>
                <Form.Control
                  type="number"
                  value={tolerance}
                  onChange={(e) => setTolerance(parseFloat(e.target.value) || 0)}
                  min="0.02"
                  step="0.01"
                  style={{ width: "100px" }}
                />
              </>
            )}
             </div>
          <Form.Check
          inline
          type="radio"
          id="searchBy-sender"
          name="filter_type"
          label="Emisor"
          value='sender'
          checked={localFilterType === "sender"}
          //onChange={(e) => setLocalFilterType(e.target.value)}
          onChange = {handleSearchFilterChange}
          />                  
          <Form.Check
          inline
          type="radio"
          id="searchBy-uuid"
          name="filter_type"
          label="UUID"
          value='uuid'
          checked={localFilterType === "uuid"}
          //onChange={(e) => setLocalFilterType(e.target.value)}
          onChange = {handleSearchFilterChange}
          />  
          {localFilterType === "uuid" && (       
          <Form.Group>
              <Form.Label>UUID</Form.Label>
              <Form.Control
                ref={inputRef} 
                type="text"
                id="uuid-textbox"
                value={tempCustomSearch}               
                onChange={handleInputChange}
              />
          </Form.Group>          
          )}         

        <Form.Check
          inline
          type="radio"
          id="searchBy-name"
          name="filter_type"
          label="Nombre"
          value='name'
          checked={localFilterType === "name"}
          //onChange={(e) => setLocalFilterType(e.target.value)}
          onChange = {handleSearchFilterChange}
          />  
          {localFilterType === "name" && (       
          <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                ref = {nameInputRef}
                type="text"                
                value={tempCustomSearch}                
                onChange={handleInputChange}
              />
          </Form.Group>
          )}         
      </div>

      <Form.Check
          inline
          type="radio"
          id="searchBy-pc"
          name="filter_type"
          label="Caja Chica"
          value='pc'
          checked={localFilterType === "pc"}
          //onChange={(e) => setLocalFilterType(e.target.value)}
          onChange = {handleSearchFilterChange}
        />   
      </Form.Group>
      <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
      <Button   type="button" variant="primary"  disabled={isLoading ||  (localFilterType === "name" ||localFilterType === "uuid" ) && !tempCustomSearch.trim() } onClick=   {localFilterType === "no_uuid" ?() => handleConfirmMatch(bankTransaction, { invoices: null, status: "checked", nombre_emisor:null }) :  handleSearchClick}>
     
      {isLoading ? (
        <>
          <Spinner as="span" animation="border"  size="sm"  role="status"   aria-hidden="true"
          />{" "}
          Cargando...
        </>
      ) : (
        localFilterType === "no_uuid" ? "Conciliar" : "Buscar"
      )}
      </Button>      

      {selectedItemsToMatch.length > 0 && (
      <Button
        variant="success"

        onClick={() => {
        let remainingAmount = bankTransaction.charge||bankTransaction.credit;
        const filteredInvoices = selectedItemsToMatch.map((invoiceId) => {
          const match = potentialMatches.data.find((item) => item.invoice_id === invoiceId);
        
          if (match) {
            const paidAmount = Math.min(remainingAmount, match.total - (match.paidTotal || 0)); // Allocate remaining amount
            remainingAmount -= paidAmount; // Deduct allocated amount
        
            return {
              amount: match.total, // Original invoice total
              paidAmount: paidAmount, // Amount allocated
              invoice_id: match.invoice_id,
              uuid: match.uuid,
              nombre_emisor: match.nombre_emisor,
              status: match.status,
              petty_cash_id : match.petty_cash_id,
            };
          }
          return null; // Skip if no match found (shouldn't happen if data is consistent)
        }).filter(Boolean);
        onMatch({...bankTransaction,invoiceList:filteredInvoices}, filteredInvoices);}}
      >
        Coincidir
      </Button>     
      )}
      </div>
      <div className="table-container" ref={tableRef} style={{ overflowY: "auto", maxHeight: "500px" }}>      
      <Table bordered hover className="fixed-header-table compressedTable">      
      <thead>
        {/* Total Row */}
        <tr className="total-sticky-row">
          <th colSpan="6" className="text-end">Total</th>
          <th className="text-right">
            {new Intl.NumberFormat('es-MX', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(
              (potentialMatches.data || [])
                .filter((item) => selectedItemsToMatch.includes(item.invoice_id))
                .reduce((acc, item) => acc + (item.total - item.paidTotal || 0), 0)
            )}
          </th>
          <th></th> {/* Empty for 'Acciones' column */}
        </tr>
        {/* Field Headers */}
        <tr className="sticky-row">
          <th>
            <Form.Check
              type="checkbox"
              checked={selectedItemsToMatch.length > 0}
              onChange={(e) =>
                setSelectedItemsToMatch(
                  e.target.checked
                    ? potentialMatches.data.map((t) => t.invoice_id)
                    : []
                )
              }
            />
          </th>
          <th>Fecha</th>
          <th>Folio</th>
          <th>Concepto</th>
          <th>UUID</th>
          <th>Emisor</th>
          <th>Monto</th>
          <th>Pendiente</th>
        </tr>
      </thead>
        <tbody>
          {Array.isArray(potentialMatches.data) && potentialMatches.data.length > 0 ? (potentialMatches.data.map((st) => (
            <tr key={st.invoice_id}>
              <td data-label="Seleccionar">
                <Form.Check
                  type="checkbox"        
                  checked={selectedItemsToMatch.includes(st.invoice_id)}
                  onChange={() => toggleItemsToMatchSelection(st.invoice_id)}
                />
              </td>
              <td data-label="Fecha">{st.fecha_emision}</td>
              <td data-label="Folio">{st.folio}</td>
              <td data-label="Concepto">{st.conceptos}</td>
              <td data-label="UUID">{st.uuid}</td>
              <td data-label="Nombre">{st.nombre_emisor}</td>
              <td data-label="Total" className="text-right">{new Intl.NumberFormat('es-MX', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(st.total)} </td>                      
              <td data-label="Pendinete" className="text-right">{new Intl.NumberFormat('es-MX', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(st.total-st.paidTotal)} </td>                      
            </tr>
          )) ):(<tr>
            <td colSpan="8" className="text-center">
              No se encontraron coincidencias.
            </td>
          </tr>
        )}
        </tbody>
      </Table>
      </div>     
    </Modal.Body>
  </Modal>
  );
};


  useEffect(() => {
    const initialProjectSelections = bankTransactions.reduce((acc, transaction) => {
      acc[transaction.transaction_id] = !!(
        transaction.project_id || proposedProjects[transaction.transaction_id]
      );  
      return acc;
    }, {});

   

    const initialCategorySelections = bankTransactions.reduce((acc, transaction) => {
      acc[transaction.transaction_id] = !!(
        transaction.category_id || proposedCategories[transaction.transaction_id]
      );
      return acc;
    }, {});
    setSelectedProjects(initialProjectSelections);
    setSelectedCategory(initialCategorySelections);
  }, [bankTransactions, proposedProjects,proposedCategories]);

  useEffect(() => {
    recalculateTotals(unmatchedTransactions);
  }, [unmatchedTransactions]);
  
  useEffect(() => {
    const fetchBanks = async () => {
    
      try {
        const banksData = await getBanks();
        setBanks(banksData.data || []);
      } catch (error) {
        console.error("Error fetching banks:", error);
        setAlert({ type: "danger", message: "Error al obtener los bancos." });
      }
    };

    fetchBanks();
  }, []);



  useEffect(() => {
   /*if (selectedBankId) {  
      console.log("Fetching transactions for Bank ID:", selectedBankId);

      fetchTransactions(selectedBankId);
    }*/
    
    if (filters.startDate === "") 
    {
        handleMonthChange (today);        
    }
    
  }, [selectedBankId]);

  const fetchTransactions = async (bankId) => {
    const queryParams = {
      source_type: "bank",
      start_date: filters.startDate,
      end_date: filters.endDate,
      status:['pending','valid','approved']
    };
  
    try {
      // Fetch all necessary data in parallel
      setIsLoading(true);
      const [bankData, projectsData, categoriesData, matchResult] = await Promise.all([
        getBankTransactions(bankId, queryParams),
        getProjects({user_id:userId}),
        getCategories(),
        matchTransaction(bankId, queryParams),
      ]);
  
      // Set projects and categories
      setProjects(projectsData.data || []);
      setCategories(categoriesData.data || []);
  
      // Prepare bank transactions and sorted SAT transactions
      const bankTransactions = bankData.data || [];
      const satTransactions = matchResult.data?.data || [];
     
  
      const missingInvoices = [];
      //search for missing invoices
      bankTransactions.map((bt) => {
        const invoiceIds = bt.invoices ? bt.invoices.split(",") : [];
        
        // Check for missing invoices
        invoiceIds.forEach((invoiceId) => {
          const isInvoicePresent = satTransactions.some((st) => st.invoice_id.toString() === invoiceId);
          if (!isInvoicePresent) {
            missingInvoices.push(invoiceId); // Add missing invoice ID to the list
          }
        });
      });
      

        const queryParamsMissingInvoice = {
          source_type: "bank",
          searchType: "invoices",
          customSearch : missingInvoices
        };
      
        let sortedData = [];
        try {
          // Fetch all necessary data in parallel
          setIsLoading(true);
          const missingInvoices = await matchTransaction(bankId, queryParamsMissingInvoice);


          if (missingInvoices.status === 200) {
            const fetchedInvoices = missingInvoices.data.data; // Assuming `data.data` contains the fetched invoices
      
            // Combine the fetched invoices with the existing satTransactions
            const updatedSatTransactions = [
              ...satTransactions, // Fallback to an empty array if undefined
              ...(fetchedInvoices || []).filter(
                (fetched) =>
                  !(satTransactions || []).some(
                    (existing) => existing.invoice_id === fetched.invoice_id
                  )
              ),
            ];
      
            // Update the sortedData and state
            sortedData = updatedSatTransactions.sort((a, b) => a.invoice_id - b.invoice_id);
            setSatTransactions(sortedData || []);
      
          }
        } catch (error) {
          console.error("Error fetching transactions:", error);
          setAlert("Error al obtener transacciones.");
        }
          
   
      
      // Generate matched list
      const matchedList = bankTransactions.map((bt) => {
        const invoiceIds = bt.invoices ? bt.invoices.split(",") : [];
        const matchedInvoices = sortedData.filter(
          (st) =>
            (((bt.status === "checked" || bt.status === "imported") && !bt.invoices) ||  // Internal movements use case
              ((bt.status === "imported" || bt.status === "checked") && invoiceIds.includes(st.invoice_id.toString())) ||
              (Math.abs(st.total - (bt.charge || bt.credit)) < 0.02 && st.fecha_emision === bt.transaction_date))
        );
  
        if (matchedInvoices.length > 0) {
          // Handle multiple matched invoices
          if (
            bt.concept.includes("TRASPASO CUENTAS PROPIAS") ||
            ((bt.status === "checked" || bt.status === "imported" )&& !bt.invoices)
          ) {
            return {
              ...bt,
              matchedInvoices: [], // No specific invoices matched
              matched: true,
            };
          } else {
            // Combine matched invoices' data
            var general_projectId;
            var general_categoryId;
            if (matchedInvoices.length > 0 && (matchedInvoices.petty_cash_id != null || matchedInvoices.petty_cash_id != ""))
            {
              general_projectId = matchedInvoices[0].project_id;
            }
            else{
              general_projectId = bt.project_id;
            }
            if (matchedInvoices.length == 1)
            {
              
              general_categoryId = matchedInvoices[0].category_id;
            }
            else{
              general_categoryId = bt.category_id;
            }
            
            return {
              ...bt,
              matchedInvoices: matchedInvoices.map((inv) => ({
                uuid: inv.uuid,
                amount:inv.total,
                paidAmount:inv.paidTotal,
                nombre_emisor: inv.nombre_emisor,
                uso_cfdi: inv.uso_cfdi,
                invoice_id: inv.invoice_id,              
                project_id: inv.project_id
              })),     
              project_id: general_projectId,         
              category_id: general_categoryId,
              matched: true,
            };
          }
        }
      
        // No matches found
        return { ...bt, matchedInvoices: [], matched: false };

      });
  
      // Separate matched and unmatched transactions
      const matched = matchedList.filter((bt) => bt.matched);
      const unmatched = matchedList.filter((bt) => !bt.matched);
  
      // Update state
      setBankTransactions(matchedList);
      setMatchedTransactions(matched);
      setUnmatchedTransactions(unmatched);
  
      // Additional operations
      generateProposedMappings(matched, projectsData.data, categoriesData.data);
      recalculateTotals(unmatched); // Update totals
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setAlert("Error al obtener transacciones.");
    }
    finally{
      setIsLoading(false);
    }
  };
  
  


  

  const toggleTransactionSelection = (transactionId) => {
    setSelectedTransactions((prev) => {
      const updatedSelections = prev.includes(transactionId)
        ? prev.filter((id) => id !== transactionId)
        : [...prev, transactionId];
      console.log("Updated Selected Transactions:", updatedSelections);
      return updatedSelections;
    });
  };
  
  const toggleItemsToMatchSelection = (transactionId) => {
    if (tableRef.current) {
      const scrollPosition = tableRef.current.scrollTop; // Save scroll position
      setSelectedItemsToMatch((prev) => {
        const updatedSelections = prev.includes(transactionId)
          ? prev.filter((id) => id !== transactionId)
          : [...prev, transactionId];
        return updatedSelections;
      });
    setTimeout(() => {
      if (tableRef.current) {
        tableRef.current.scrollTop = scrollPosition; // Restore scroll position
      }
    }, 0);
    }
  };

  const handleBatchUpdate = () => {
    if (!batchProject && !batchDepartment) {
      
      setAlert( "Seleccione un proyecto o departamento para aplicar.");
      setAlertVariant("info");
      return; 
    }

    console.log("Bank Transactions Before Update:", bankTransactions);
    console.log("Selected Transactions:", selectedTransactions);
    console.log("Batch Project:", batchProject);
    console.log("Batch Department:", batchDepartment);
    setBankTransactions((prev) => {
      if (prev.length === 0) {
        console.error("No transactions to process");
      }
      console.log("Selected Transactions:", selectedTransactions);
      console.log("Batch Project:", batchProject);
      console.log("Batch Department:", batchDepartment);
    
      return prev.map((transaction) => {
        console.log("Processing Transaction:", transaction);
    
        // Ensure both transaction_id and selectedTransactions are the same type
        const isSelected = selectedTransactions.includes(transaction.transaction_id);
    
        console.log(
          "Transaction ID:",
          transaction.transaction_id,
          "Is Selected:",
          isSelected
        );
    
        if (isSelected) {
          return {
            ...transaction,
            project_id: parseInt(batchProject) || transaction.project_id,
            category_id: batchDepartment || transaction.category_id,
          };
        }
        return transaction;
      });
    });

    setSelectedTransactions([]);
    setBatchProject("");
    setBatchDepartment("");
  };
  const identifyUnmatched = (bankData, systemData) => {
    const unmatched = bankData.filter(
      (bt) =>
        !systemData.some(
          (st) => st.amount === bt.amount && st.date === bt.transaction_date
        )
    );
    setUnmatchedTransactions(unmatched);
  };

  const handleImportTransaction = async (bankTransaction) => {
    setIsLoading(true); // Show a loading spinner or disable the button
    
    try {
      // Map the bank transaction fields to cashflow entry
      const cashflowEntry = {
        expense_date: bankTransaction.transaction_date,
        project_id: bankTransaction.project_id || proposedProjects[bankTransaction.transaction_id] || null,
        category_id: bankTransaction.category_id || proposedCategories[bankTransaction.transaction_id] || null,
        bank_id:bankTransaction.bank_id,
        concept: bankTransaction.concept,
        transaction_type: "bank",
        bank_id: selectedBankId,
        totalAmount: parseFloat(bankTransaction.charge || bankTransaction.credit || 0),
        operation_type: bankTransaction.charge ? "expense" : "income",
        notes: bankTransaction.reference_number || "Imported from bank transaction",
        status: "valid",
        source: "banks",
        name:bankTransaction.nombre_emisor,
        uuid:bankTransaction.uuid,
        invoices: bankTransaction.matchedInvoices,
        transaction_id : bankTransaction.transaction_id,
        transaction_type: "fiscal",
        user_id: userId,
      };

      // Save the new cashflow entry
      const response = await addCashflow(cashflowEntry);

      if (response.status === 201) {
        // Update the UI: Remove the imported transaction from the list
        setBankTransactions((prev) =>
          prev.filter((transaction) => transaction.transaction_id !== bankTransaction.transaction_id)
        );
        setUnmatchedTransactions((prev) =>
          prev.filter((transaction) => transaction.transaction_id !== bankTransaction.transaction_id)
        );


        /*setMatchedTransactions((prev) => [
          { ...bankTransaction},
          ...prev,            
          ]);
          */
        
        setAlert( "Transacción importada con éxito" );
        setAlertVariant("success");
      } else {
        throw new Error("Failed to import transaction.");
      }
    } catch (error) {
      console.error("Error importing transaction:", error);
      setAlert("Error importing transaction. Please try again.");
      setAlertVariant("danger");
    } finally {
      setIsLoading(false); // Stop the loading spinner
    }
  };


  const addProposedProject = (transactionId, projectId) => {
    setProposedProjects((prev) => ({
      ...prev,
      [transactionId]: projectId, // Add or update the project for the given transaction ID
    }));
  };

  const generateProposedMappings = async (transactions, projects, categories) => {
    try {
      const patternRules = await getPatternRules();

      // Generate project mappings

      const generateProjectPatternRules = () => {
        // Dynamically generate patterns for category names
        const dynamicCategoryPatterns = projects.map((projects) => ({
          pattern: new RegExp(`[ 0-9]${projects.name}\\b`, "i"), // Case-insensitive matching for category name
          projectId: projects.id,
        }));
      
        // Flatten static patterns into individual rules
        const staticCategoryRules = patternRules.data
        .filter((rule) => rule.type === "project")
        .map((rule) => ({ pattern: new RegExp(rule.pattern, "i"), projectId: rule.target_id }));
      
        // Combine dynamic rules first, followed by static rules
        return [ ...staticCategoryRules,...dynamicCategoryPatterns];
      };

      const projectPatterns = generateProjectPatternRules();/*;patternRules
        .filter((rule) => rule.type === "project")
        .map((rule) => ({ pattern: new RegExp(rule.pattern, "i"), projectId: rule.target_id }));

      */
      const proposedProjectsMap = transactions.reduce((acc, transaction) => {
        const matchedProject = projectPatterns.find((rule) =>
          rule.pattern.test(transaction.concept)
        );      
        acc[transaction.transaction_id] = matchedProject?.projectId || "";
        return acc;
      }, {});
      
      setProposedProjects(proposedProjectsMap);
      

      // Generate category mappings
      const categoryPatterns = patternRules.data
        .filter((rule) => rule.type === "category")
        .map((rule) => ({ pattern: new RegExp(rule.pattern, "i"), categoryId: rule.target_id }));

      const proposedCategoriesMap = transactions.reduce((acc, transaction) => {
        const matchedCategory = categoryPatterns.find((rule) =>
          rule.pattern.test(transaction.concept)
        );
        acc[transaction.transaction_id] = matchedCategory?.categoryId || "";
        return acc;
      }, {});

      
      setProposedCategories(proposedCategoriesMap);
      
    } catch (error) {
      console.error("Error generating mappings:", error);
    }
  };

  const handleProjectChange = (transactionId, projectId) => {
    setSelectedProjects((prev) => ({
      ...prev,
      [transactionId]: !!projectId, // Store whether a project is selected
    }));

    setBankTransactions((prev) =>
      prev.map((transaction) =>
        transaction.transaction_id === transactionId
          ? { ...transaction, project_id: parseInt(projectId) }
          : transaction
      )
    );
    setMatchedTransactions((prev) =>
      prev.map((transaction) =>
        transaction.transaction_id === transactionId
          ? { ...transaction, project_id: parseInt(projectId) }
          : transaction
      )
    );
  };

  const handleCategoryChange = (transactionId, categoryId) => {
    setSelectedCategory((prev) => ({
      ...prev,
      [transactionId]: !!categoryId, // Store whether a project is selected
    }));
    setBankTransactions((prev) =>
      prev.map((transaction) =>
        transaction.transaction_id === transactionId
          ? { ...transaction, category_id: parseInt(categoryId) }
          : transaction
      )
    );
    setMatchedTransactions((prev) =>
      prev.map((transaction) =>
        transaction.transaction_id === transactionId
          ? { ...transaction, category_id: parseInt(categoryId) }
          : transaction
      )
    );
  };


  const handleConfirmMatch = async (bankTransaction, systemTransaction) => {


     // Save the new cashflow entry
     const queryParams = {      
      user_id:userId,
      company_id : 1,  
      status: systemTransaction.status || "checked" ,
      transaction_id: bankTransaction.transaction_id,
      totalAmount:bankTransaction.charge || bankTransaction.credit ,
      NonZero:1,
      invoices:bankTransaction.invoiceList

     }

     setSearchFilters((prev) =>({...prev,filter_type :"amount"}));
     setCustomSearch ("");
     setSelectedItemsToMatch([]);
     setShowModal(false); // Close the modal
     const invoiceTotal = queryParams.invoices?.reduce((sum, invoice) => sum + (invoice.amount || 0), 0);

      // Validate if totalAmount <= invoiceTotal
      if (queryParams.totalAmount > invoiceTotal) {
        const proceed = window.confirm("El monto del banco es mayor la suma de los montos de las facturas asociadas. ¿Desea continuar?");
        if (!proceed) {                    
          return; // Exit the function early if the user cancels
        }
      }
  
     const response = await conciliateTransaction(bankTransaction.bank_id,queryParams);

     if (response.status === 200) {
       // Update the UI: Remove the imported transaction from the list
       
      const updatedBankTransaction = {
        ...bankTransaction,
        matchedInvoices:bankTransaction.invoiceList,                 
      };
  
      if (systemTransaction.status === 'pending') // removing the entry from the matched list when cancel is trigger
      {
        setUnmatchedTransactions((prev) => [
          ...prev,
          updatedBankTransaction, // Use the updated transaction with UUID
        ]);
        setMatchedTransactions((prev) =>
          prev.filter((bt) => bt.transaction_id !== bankTransaction.transaction_id)
        );

      }
      else // adding the entry to the matched list when the add (conciliar) is triggered
      {
        setSelectedItemsToMatch([]);
        setMatchedTransactions((prev) => [
          ...prev,
          updatedBankTransaction, // Use the updated transaction with UUID
        ]);
        setUnmatchedTransactions((prev) =>
          prev.filter((bt) => bt.transaction_id !== bankTransaction.transaction_id)
        );
        
        
          // Apply regex rules to update proposedProjects and proposedCategories
        const patternRules = await getPatternRules();

        // Dynamically determine project and category mappings for the new transaction
        const matchedProjectRule = patternRules
          .filter((rule) => rule.type === "project")
          .find((rule) => new RegExp(rule.pattern, "i").test(updatedBankTransaction.concept));

        const matchedCategoryRule = patternRules
          .filter((rule) => rule.type === "category")
          .find((rule) => new RegExp(rule.pattern, "i").test(updatedBankTransaction.concept));

        // Update the state with the new mappings
        setProposedProjects((prev) => ({
          ...prev,
          [bankTransaction.transaction_id]: matchedProjectRule?.target_id || "",
        }));

        setProposedCategories((prev) => ({
          ...prev,
          [bankTransaction.transaction_id]: matchedCategoryRule?.target_id || "",
        }));
      }
      recalculateTotals(unmatchedTransactions); // Update totals
      
    }
       
  };
  



  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };



  const handleSearchFilterChange = (e) => {
    const { name, value } = e.target;
    if (value === "uuid" || value === "name")
      {
        setEmptyEntry(true);       
      }
    else setEmptyEntry(false);

    setSearchFilters((prev) => ({ ...prev, [name]: value }));
  };

  

  return (
    <Container>
      <h1>Conciliación Bancaria</h1>

      {alert && <Alert variant={alertVariant}>{alert}</Alert>}

      {/* Bank Selection */}
      <Form className="mb-4">
        <Form.Group className="mb-3">
          <Form.Label>Selecciona un Banco</Form.Label>
          <Form.Select
          value={selectedBankId}
          onChange={(e) => setSelectedBankId(e.target.value)}
          >
          <option value="">-- Seleccionar Banco --</option>
          {banks.map((bank) => (
              <option key={bank.source_id} value={bank.source_id}>
              {bank.source_name}
              </option>
          ))}
          </Form.Select>
      </Form.Group>


        <Row className="align-items-center mb-3">
        <Col md={3}>
        <Form.Group className="mb-3">
            <Form.Label>Filtrar por Fecha</Form.Label>
            <Form.Select value={dateFilter} onChange={handleDateFilterChange}>
              <option value="custom">Rango Personalizado</option>
              <option value="currentMonth">Mes Actual</option>
              <option value="lastMonth">Mes Anterior</option>
              <option value="thisYear">Este Año</option>
              <option value="lastYear">Año Anterior</option>
              <option value="monthPicker">Mensual</option>
            </Form.Select>
          </Form.Group>
          </Col>
          {dateFilter === "custom" && (
            <>
                  <Form.Group className="mb-3">
                  <Form.Label>Fecha de Inicio</Form.Label>
                  <Form.Control
                      type="date"
                      name="startDate"
                      value={filters.startDate}
                      onChange={handleFilterChange}
                  />
                  </Form.Group>

                  <Form.Group className="mb-3">
                  <Form.Label>Fecha de Fin</Form.Label>
                  <Form.Control
                      type="date"
                      name="endDate"
                      value={filters.endDate}
                      onChange={handleFilterChange}
                  />
                  </Form.Group>
              </>
          )}
          
          <Col>
            {dateFilter === "monthPicker" && (
            
              <Form.Group className="mb-6 date-container">
                <Form.Label>Seleccionar Mes: </Form.Label>
                <DatePicker
                  className="date-picker-control"
                  selected={selectedMonth}
                  onChange={handleMonthChange}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                />
              </Form.Group>
            
          )}
        </Col>  
       </Row>
        <Button disabled = {!selectedBankId}
        variant="primary" onClick={()=>fetchTransactions(selectedBankId)}>          

        {isLoading ? (
        <>
          <Spinner as="span" animation="border"  size="sm"  role="status"   aria-hidden="true"
          />{" "}
          Cargando...
        </>
        ) : (
          "Aplicar Filtros"
        )}

        
        </Button>
      </Form>    

      {/* Unmatched Transactions */}
      <h3>Transacciones No Coinciden</h3>
      <Table className="custom-table" bordered hover>
        <thead>
          <tr>
            <th>
              <Form.Check
                type="checkbox"
                onChange={(e) =>
                  setSelectedTransactions(
                    e.target.checked
                      ? unmatchedTransactions.map((t) => t.transaction_id)
                      : []
                  )
                }
              />
            </th>
            <th>Fecha</th>
            <th>Concepto</th>                        
            {!isMobile?(
            <>
            <th>Cargo</th>
            <th className="hide-mobile">Abono</th>            
            </>
            ):<th>Monto</th>
            }
            {!isMobile && (<th>FE</th>)}
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {unmatchedTransactions.map((bt) => (
            <tr key={bt.transaction_id}>
               <td  data-label="checkbox">
                <Form.Check
                  type="checkbox"
                  checked={selectedTransactions.includes(bt.transaction_id)}
                  onChange={() => toggleTransactionSelection(bt.transaction_id)}
                />
              </td>
              <td  data-label="Fecha" style={{ whiteSpace: "nowrap" }}>{bt.transaction_date}</td>

              <td  data-label="Concepto"> {bt.concept}</td>
              
              {!isMobile?(  
                <>
                <td  data-label="Cargo" className="text-right">{bt.charge !== null
                  ? new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(bt.charge)
                  : '-'}
                </td>
                <td  data-label="Abono"className="text-right">{bt.credit !== null
                  ? new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(bt.credit)
                  : '-'}
                </td>
                </>
              ):<td  data-label="Monto"className="text-right">{bt.credit !== null
                ? new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(bt.credit || bt.charge)
                : '-'}
              </td>
              }
              {!isMobile && (<td  data-label="cfID">{bt.cashflow_id}</td>)}
              <td>
                <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                <Button
                  data-label="Accion"
                  className="mt-3 d-inline me-2"  
                  variant="primary"
                  inline
                  size="sm"
                  onClick={() => handleMatchTransaction(bt)}
                  >
                   {loadingTransactionId === bt.transaction_id ? (
                      <span>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        Con..
                      </span>
                    ) : (
                      "Conciliar"
                    )}
                </Button>             
                </div>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
        <tr>
          <td colSpan="3" className="text-end"><strong>Totales:</strong></td>
          <td className="text-right"><strong>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totalCargo)}</strong></td>
          <td className="text-right"><strong>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totalAbono)}</strong></td>
          <td colSpan="2"></td>
        </tr>
      </tfoot>
      </Table>

      {/* Batch Update Controls */}
        <h3>Actualización por Lote</h3>
      <Form>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Proyecto</Form.Label>
              <Form.Select
                value={batchProject}
                onChange={(e) => setBatchProject(e.target.value)}
              >
                <option value="">-- Seleccionar Proyecto --</option>
                {projects.map((project) => (
                  <option key={project.id} value={project.id}>
                    {project.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Departamento</Form.Label>
              <Form.Control
                type="text"
                value={batchDepartment}
                onChange={(e) => setBatchDepartment(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <Button variant="primary" disabled = {bankTransactions.length === 0} onClick={handleBatchUpdate}>
        Aplicar
      </Button>

      {/* Matched Transactions */}
      <h3>Transacciones Coincidentes</h3>
      <Table bordered hover className="bank-table">
        <thead>
          <tr>
           <th>
              <Form.Check
                type="checkbox"
                onChange={(e) =>
                  setSelectedItemsToMatch(
                    e.target.checked
                      ? matchedTransactions.map((t) => t.transaction_id)
                      : []
                  )
                }
              />
            </th>
            <th>Fecha Banco</th>
            <th>Concepto Banco</th>
            <th>Monto Banco</th>
            <th>Emisor</th>
            <th>uuid</th>            
            <th className="wide-column">Obra</th>            
            <th className="wide-column">Departamento</th> 
            <th>Acciones</th>                       
          </tr>
        </thead>
        <tbody>
          {matchedTransactions.map(( bankTransaction ) => (
            <tr key={bankTransaction.transaction_id}>
               <td>
                <Form.Check
                  type="checkbox"
                  checked={selectedItemsToMatch.includes(bankTransaction.transaction_id)}
                  onChange={() => toggleTransactionSelection(bankTransaction.transaction_id)}
                />
              </td>
              <td>{bankTransaction.transaction_date}</td>
              <td>{bankTransaction.concept}</td>
              <td className={`text-right ${bankTransaction.credit ? "text-red" : ""}`}>
                {new Intl.NumberFormat("es-MX", {
                  style: "currency",
                  currency: "MXN",
                }).format(bankTransaction.charge || bankTransaction.credit)}
              </td>

              <td>{bankTransaction.matchedInvoices?.[0]?.nombre_emisor || "No asociado"}</td>
              
                {bankTransaction.matchedInvoices?.length > 0
                ? bankTransaction.matchedInvoices.map((inv) => inv.uuid).join(" / ")
                : "No uuid"}           
              <td> 
                <Form.Group className="mb-3">                  
                  <Form.Select
                    value={bankTransaction.project_id || proposedProjects[bankTransaction.transaction_id] || ""}
                    disabled = { bankTransaction.status === "imported"}
                    style={{
                      backgroundColor: bankTransaction.project_id || proposedProjects[bankTransaction.transaction_id] ? "" : "lightyellow",
                    }}
                  //  value={getProposedProject(bt.concept) || ""}
                    onChange={(e) => handleProjectChange(bankTransaction.transaction_id, e.target.value)}
                  >
                    <option value="">Proyecto</option>
                    {projects.map((project) => (
                      <option key={project.id} value={project.id}>
                        {project.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </td>
              <td> 
                <Form.Group className="mb-3">                  
                  <Form.Select
                    //v  alue={getProposedCategory(bt.concept) || ""}
                    value={ bankTransaction.category_id || proposedCategories[bankTransaction.transaction_id] || ""}
                    disabled = { bankTransaction.status === "imported"}
                    style={{
                      backgroundColor: bankTransaction.category_id || proposedCategories[bankTransaction.transaction_id] ? "" : "lightyellow",
                    }}
                    onChange={(e) => handleCategoryChange(bankTransaction.transaction_id, e.target.value)}
                  >
                    <option value="">Departamento</option>
                    {categories.map((categories) => (
                      <option key={categories.category_id} value={categories.category_id}>
                        {categories.category_name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </td>     
              
              <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
              { bankTransaction.status !== "imported" && selectedProjects[bankTransaction.transaction_id] && selectedCategory[bankTransaction.transaction_id]&&(
              <Button
                  variant="success"
                  size="sm"
                  className="mt-3 d-inline"  
                  inline
                  onClick={() => handleImportTransaction(bankTransaction)}
                  disabled={isLoading}
                >
                  {isLoading ? "Importando..." : "Importar"}
                </Button>                                
              )}
               { bankTransaction.status !== "imported" &&  selectedProjects[bankTransaction.transaction_id] && selectedCategory[bankTransaction.transaction_id]&&(
              
                <Button
                variant="warning"
                size="sm"
                className="mt-3 d-inline"  
                inline
                disabled={isLoading}
                onClick={() =>
                  handleConfirmMatch({...bankTransaction, matchedInvoices:selectedItemsToMatch}, { invoices: null, status: "pending" })
                }
              >
                Cancelar
              </Button> 
               )}
                {bankTransaction.status === "imported" && (
                  <span className="text-success">Importado</span>
                )}
               </div>
            </tr>
          ))}
        </tbody>
      </Table>
      <MatchModal
        show={showModal}
        onHide={() => {setShowModal(false);setSelectedItemsToMatch([]);}}
        bankTransaction={selectedTransaction}        
        satTransactions={satTransactions}
        initialFilterType={searchFilters.filter_type}
        onSearch={handleSearch}
        onMatch={handleConfirmMatch}
        customSearch={customSearch}        
        setCustomSearch={setCustomSearch}         
      />
    </Container>
  );
};

export default BankConciliationPage;
