import React, { useState, useEffect } from "react";
import { Container, Form, Button, Table, Alert, Modal } from "react-bootstrap";
import { getProjects, deleteProject } from "../services/api";
import Pagination from "../components/Pagination";

const DeleteProjectPage = ({userId}) => {
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [alert, setAlert] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [confirmationInput, setConfirmationInput] = useState("");
  
  /*Pagination*/
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  

  // Fetch projects on component mount
  
  const fetchProjects = async (page) => {
    try {
      const queryParams = {
        "order_by":"name",
        "page":page,
        user_id:userId
      }
      const projectsData = await getProjects(queryParams);
      setProjects(projectsData.data || []);
      setTotalPages(projectsData.pagination.total_pages);
    } catch (error) {
      console.error("Error fetching projects:", error);
      setAlert({
        type: "danger",
        message: "Error al obtener los proyectos.",
      });
    }
  };
    
  

  useEffect(() => {
    fetchProjects(currentPage);
  }, [currentPage]);

  // Handle delete project
  const handleDeleteProject = async () => {
    if (confirmationInput !== "CONFIRMAR") {
      setAlert({
        type: "warning",
        message: "Por favor, escribe 'CONFIRMAR' para eliminar el proyecto.",
      });
      return;
    }

    try {
      await deleteProject(selectedProjectId,userId);
      setProjects((prev) =>
        prev.filter((project) => project.id !== selectedProjectId)
      );
      setAlert({
        type: "success",
        message: "Proyecto eliminado correctamente.",
      });
      setShowModal(false);
      setSelectedProjectId("");
      setConfirmationInput("");
    } catch (error) {
      console.error("Error deleting project:", error);
      setAlert({
        type: "danger",
        message: "Error al eliminar el proyecto. Inténtalo de nuevo.",
      });
    }
  };

  return (
    <Container className="mt-4">
      <h1>Eliminar Proyecto</h1>

      {alert && <Alert variant={alert.type}>{alert.message}</Alert>}

      {/* Project List */}
      <h3>Lista de Proyectos</h3>
      <div>
        <Table id="projects-table" striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Responsable</th>
              <th>Fecha Inicio</th>
              <th>Fecha Fin</th>
              <th>Descripción</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project) => (
              <tr key={project.id}>
                <td data-label="ID">{project.id}</td>
                <td data-label="Obra">{project.name}</td>
                <td data-label="PM">{project.pm_name}</td>
                <td data-label="Fecha Inicio">{project.start_date}</td>
                <td data-label="Fecha Fin">{project.end_date}</td>
                <td data-label="Descripción">{project.description || "N/A"}</td>
                <td>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => {
                      setSelectedProjectId(project.id);
                      setShowModal(true);
                    }}
                  >
                    Eliminar
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
       {/* Pagination Component */}
       <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
      </div>

      {/* Confirmation Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Eliminación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            ¿Estás seguro de que deseas eliminar este proyecto? Esta acción no
            se puede deshacer.
          </p>
          <Form.Group>
            <Form.Label>Escribe 'CONFIRMAR' para continuar:</Form.Label>
            <Form.Control
              type="text"
              value={confirmationInput}
              onChange={(e) => setConfirmationInput(e.target.value)}
              placeholder="CONFIRMAR"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={handleDeleteProject}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default DeleteProjectPage;
