import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Button, Form, Card, Spinner, Alert } from "react-bootstrap";
import { getProjects, deleteProject, getQuote, getBudget, getBudgetItems } from "../services/api";
import { useLocation } from "react-router-dom";
import Pagination from "../components/Pagination";
import ScrollToTopButton from "../components/ScrollTop";

const ProjectBudgetPage = ({userId}) => {
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const location = useLocation(); // Query parameters  
  const [budgets, setBudgets] = useState([]);
  const [selectedBudgetId, setSelectedBudgetId] = useState(null);
  const [budgetItems, setBudgetItems] = useState([]);

  const [loadingProjects, setLoadingProjects] = useState(true);
  const [loadingBudgets, setLoadingBudgets] = useState(false);
  const [loadingBudgetItems, setLoadingBudgetItems] = useState(false);
  const [error, setError] = useState(null);
  const queryParams = new URLSearchParams(location.search);
  const initialProjectId = queryParams.get("project_id");

  /*Pagination*/
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const queryParams = {
          "order_by":"name",   
          user_id:userId
        }
        const data = await getProjects(queryParams);
        setProjects(data.data);
        if (initialProjectId) {
          setSelectedProjectId(initialProjectId);
        }
      } catch (err) {
        setError("Error fetching projects.");
      } finally {
        setLoadingProjects(false);
      }
    };

    fetchProjects();
  }, [initialProjectId]);

  useEffect(() => {
    const fetchBudgets = async () => {
      if (!selectedProjectId) return;

      setLoadingBudgets(true);
      setBudgets([]);
      setSelectedBudgetId(null);
      setBudgetItems([]);

      try {
        const data = await getBudget(selectedProjectId);
        setBudgets(data.data);
      } catch {
        setError("Error fetching budgets.");
      } finally {
        setLoadingBudgets(false);
      }
    };

    fetchBudgets();
  }, [selectedProjectId]);

  const fetchBudgetItems = async (page) => {
    if (!selectedBudgetId) return;

    setLoadingBudgetItems(true);
    setBudgetItems([]);

    try {
      const queryParams = {remainingBudget:1,page:page}
      const data = await getBudgetItems(selectedProjectId,selectedBudgetId,queryParams);
      setBudgetItems(data.data);
      setTotalPages(data.pagination.total_pages);
    } catch {
      setError("Error fetching budget items.");
    } finally {
      setLoadingBudgetItems(false);
    }
  };

  useEffect(() => {  
    fetchBudgetItems();
  }, [selectedBudgetId]);


   useEffect(() => {
      if (selectedProjectId)
      {
        fetchBudgetItems(currentPage);
      }
    }, [currentPage]);

  const handleProjectChange = (e) => setSelectedProjectId(e.target.value);
  const handleBudgetClick = (budgetId) => setSelectedBudgetId(budgetId);

  return (
    <Container fluid className="App">
      <h1>Listado de insumos</h1>

      {error && <Alert variant="danger">{error}</Alert>}

      {loadingProjects ? (
        <Spinner animation="border" />
      ) : (
        <>
          {/* Project Selector */}
          <Form.Group className="project-select">
            <Form.Label>Selecciona un proyecto:</Form.Label>
            <Form.Control as="select" onChange={handleProjectChange} value={selectedProjectId || ""} style={{ width: "300px" }}>
              <option value="">-- Selecciona un proyecto --</option>
              {projects.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {selectedProjectId && (
            <Card className="mb-4">
              <Card.Header>Presupuestos</Card.Header>
              <Card.Body>
                {loadingBudgets ? (
                  <Spinner animation="border" />
                ) : budgets.length > 0 ? (
                  <Table bordered hover className="project-table compressedTable">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Descripción</th>
                        <th>Total</th>
                        <th>Restante</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {budgets.map((budget) => (
                        <tr key={budget.budget_id}>
                          <td data-label="Id">{budget.budget_id}</td>
                          <td data-label="Descripción">{budget.budgetName}</td>
                          <td data-label="Presupuesto">${Number(budget.TotalAmount).toLocaleString()}</td>
                          <td data-label="Restante">${Number(budget.remaining_budget).toLocaleString()}</td>                          
                          <td>
                            <Button variant="info" size="sm" onClick={() => handleBudgetClick(budget.budget_id)}>
                              Ver Detalles
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <p>No hay presupuestos disponibles.</p>
                )}
              </Card.Body>
            </Card>
          )}

          {selectedBudgetId && (
            <Card>
              <Card.Header>Detalles del Presupuesto</Card.Header>
              <Card.Body>
                {loadingBudgetItems ? (
                  <Spinner animation="border" />
                ) : budgetItems.length > 0 ? (
                  <div>
                  <Table bordered hover className="item-table compressedTable">
                    <thead>
                      <tr>
                        <th>Código</th>
                        <th>Categoría</th>
                        <th>Descripción</th>
                        <th>Cantidad</th>
                        <th>Unidad</th>
                        <th>Costo Unitario</th>                        
                        <th>Costo Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {budgetItems.map((item) => (
                        <tr key={item.id}>
                          <td data-label="Código">{item.costCode}</td>
                          <td data-label="Departamento">{item.category}</td>
                          <td data-label="Descripción">{item.description}</td>
                          <td data-label="Cantidad" className="text-right">{new Intl.NumberFormat('es-MX', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(item.quantity)}</td>
                          <td data-label="Unidad">{item.unitOfMeasure}</td>                          
                          <td data-label="Costo" className="text-right">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.budget_cost)}</td>
                          <td data-label="Importe" className="text-right">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.quantity * item.budget_cost)}</td> 
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {/* Pagination Component */}
                  <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={setCurrentPage}
                  />
                  </div>
                ) : (
                  <p>No hay detalles disponibles.</p>
                )}
              </Card.Body>
            </Card>
          )}
        </>
      )}
      <ScrollToTopButton />
    </Container>
  );
};

export default ProjectBudgetPage;
