// src/utils/dateUtils.js
export const calculateDateRange = (filterType,selectedMonth = null) => {
  const today = new Date();
  let start, end;

  switch (filterType) {
    case "currentMonth":
      start = new Date(today.getFullYear(), today.getMonth(), 1);
      end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      break;
    case "lastMonth":
      start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      end = new Date(today.getFullYear(), today.getMonth(), 0);
      break;
    case "thisYear":
      start = new Date(today.getFullYear(), 0, 1);
      end = new Date(today.getFullYear(), 11, 31);
      break;
    case "lastYear":
        start = new Date(today.getFullYear()-1, 0, 1);
        end = new Date(today.getFullYear()-1, 11, 31);
      break;
    case "monthPicker":
      if (!selectedMonth) return { start: "", end: "" };
      const year = selectedMonth.getFullYear();
      const month = selectedMonth.getMonth() + 1; // JavaScript months are 0-indexed
      start = new Date(year, month - 1, 1);
      end = new Date(year, month, 0); // Last day of the month
      break;
    default:
      return { start: "", end: "" };
  }

  return { start: start.toISOString().split("T")[0], end: end.toISOString().split("T")[0] };
};

export const formatDateToISO = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};
