import { useState } from "react";

const useCardValidation = () => {
  const [cardType, setCardType] = useState("");

  // Algoritmo de Luhn para validar el número de tarjeta
  const validateLuhn = (cardNumber) => {
    if (!cardNumber) return false;
    const sanitized = cardNumber.replace(/\D/g, ""); // Elimina espacios y caracteres no numéricos
    let sum = 0;
    let alternate = false;

    for (let i = sanitized.length - 1; i >= 0; i--) {
      let n = parseInt(sanitized[i], 10);
      if (alternate) {
        n *= 2;
        if (n > 9) n -= 9;
      }
      sum += n;
      alternate = !alternate;
    }

    return sum % 10 === 0;
  };

  // Detectar el tipo de tarjeta (Visa, Mastercard, AMEX, etc.)
  const getCardType = (cardNumber) => {
    if (!cardNumber) return "Desconocida";
    const sanitized = cardNumber.replace(/\D/g, "");

    const cardPatterns = {
      Visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
      Mastercard: /^5[1-5][0-9]{14}$|^2[2-7][0-9]{14}$/,
      "American Express": /^3[47][0-9]{13}$/,
      "Diners Club": /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
      Discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
      JCB: /^(?:2131|1800|35\d{3})\d{11}$/
    };

    for (const [type, pattern] of Object.entries(cardPatterns)) {
      if (pattern.test(sanitized)) {
        //setCardType(type);
        //return type;
      }
    }    
    const bin = sanitized.substring(0, 6); 

    const bankBINs = {
      "411111": "Chase Bank",
      "400000": "Bank of America",
      "555555": "Citi Bank",
      "601100": "Discover",
      "378282": "American Express",
      "510510": "Mastercard Test Bank",
      "471110": "BBVA",
      "520082": "Santander",
      "450903": "HSBC",
      "415231": "Banorte"
    };
  
    let cardType = "Desconocida";
    for (const [type, pattern] of Object.entries(cardPatterns)) {
      if (pattern.test(sanitized)) {
        cardType = type;
        break;
      }
    }
  
    const bankName = bankBINs[bin] || "Banco desconocido";

    setCardType(bankName);
    return bankName;
  };

  return { validateLuhn, getCardType, cardType };
};

export default useCardValidation;
