import React, { useEffect, useState } from "react";
import {
  getUsers,
  getPermissions,
  getUserPrivileges,
  addUser,
  updateUser,
  getRoles,
  deleteUser,
} from "../services/api";
import {
  Modal,
  Button,
  Table,
  Form,
  Alert,
  Spinner,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Users = ({ userId }) => {
  const [users, setUsers] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState(null);
  
  
  const [showModal, setShowModal] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [userForm, setUserForm] = useState({
    user_name: "",
    email: "",
    role: "",
    role_id: null,
    user_id:null,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [usersData, permissionsData,rolesData] = await Promise.all([
          getUsers(),
          getPermissions(),
          getRoles()
        ]);
        setUsers(usersData || []);
        setPermissions(permissionsData || []);
        setRoles(rolesData.data || []);
        setLoading(false);
      } catch (err) {
        setError("Fallo al obtener usuarios y permisos");
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setUserForm((prev) => ({ ...prev, [name]: name === "role_id"?parseInt(value):value }));
  };

  const handleSaveUser = async () => {
    try {
      if (editingUser) {
        await updateUser({...userForm,user_id:userId,user_key:userForm.user_id,role_id:parseInt(userForm.role_id)});
        setUsers((prev) =>
          prev.map((user) =>
            user.user_id === editingUser.user_id ? { ...user, ...userForm } : user
          )
        );
      } else {       
        const newUser = await addUser({...userForm,user_id:userId,role_id:parseInt(userForm.role_id)});
        setUsers((prev) => [...prev, userForm]);
      }
      setShowModal(false);
      setEditingUser(null);
      setUserForm({ user_name: "", email: "", role: "",role_id :null , user_id:null});
      
    } catch (err) {
      console.error("Error saving user:", err);
    }
  };

  const handleDeleteUser = async (user_id) => {
    if (window.confirm("¿Estás seguro de que deseas eliminar este usuario?")) {
      try {
        await deleteUser(user_id,{requestor_id:userId});
        setUsers((prev) => prev.filter((user) => user.user_id !== user_id));
      } catch (err) {
        console.error("Error deleting user:", err);
      }
    }
  };

  const handleEditUser = (user) => {
    setEditingUser(user);
    setUserForm({ user_name: user.user_name, email: user.email, role_id: user.role_id, user_id:user.user_id});
    setShowModal(true);
  };

  const handleManagePermissions = (user_id) => {
    navigate(`/permissions/${user_id}`);
  };

  if (loading) {
    return <Spinner animation="border" />;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <div className="container">
      <h2 className="text-center mt-4">Gestión de Usuarios</h2>
      <Button variant="success" className="mb-4" onClick={() => setShowModal(true)}>
        Agregar Usuario
      </Button>

      <Table striped bordered hover className="compressedTable">
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre</th>
            <th>Email</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={user.user_id}>
              <td data-label="Id" >{index + 1}</td>
              <td  data-label="Nombre">{user.user_name}</td>
              <td  data-label="email">{user.email}</td>
              <td>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => handleEditUser(user)}
                  className="me-2"
                >
                  Editar
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => handleDeleteUser(user.user_id)}
                  className="me-2"
                >
                  Eliminar
                </Button>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => handleManagePermissions(user.user_id)}
                >
                  Gestionar Permisos
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* User Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{editingUser ? "Editar Usuario" : "Agregar Usuario"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                name="user_name"
                value={userForm.user_name}
                onChange={handleFormChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={userForm.email}
                onChange={handleFormChange}
                required
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Role</Form.Label>
              <Form.Select name="role_id" value={userForm.role_id} onChange={handleFormChange}>
                <option value="">-- Seleccionar --</option>
                {roles.map((role) => (
                  <option key={role.role_id} value={role.role_id}>
                    {role.role_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="success" onClick={handleSaveUser}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Users;
