import React, {  useEffect,useState } from "react";
import { Link,useLocation  } from "react-router-dom";
import { Navbar as BootstrapNavbar, Nav, NavDropdown } from "react-bootstrap";
import { useNotifications } from "../context/NotificationsContext";
import { hasAccess } from "../utils/AccessControl";
import { activeModules } from "../config/systemConfig";
import { FaHome,FaMoneyBillWave,FaBell, FaThList,FaProjectDiagram, FaShoppingCart, FaUser, FaCashRegister,FaExchangeAlt,FaCog,FaChartLine} from "react-icons/fa";

const Navbar = ({ isLoggedIn, userPrivileges,fetchUserPrivileges,rolePrivileges,userId }) => {

const location = useLocation();
const { notificationsCount } = useNotifications();
const [menuOpen, setMenuOpen] = useState(false); 
const isActive = (path) => location.pathname === path;

useEffect(() => {
  if (isLoggedIn && userId) {
   // fetchUserPrivileges(userId);
  }
}, [isLoggedIn, userId, fetchUserPrivileges]);

const handleMenuClick = () => {
  setMenuOpen(!menuOpen); // Toggle menu open/close
};

const handleItemClick = () => {
  setMenuOpen(false); // Close menu when an item is clicked
};


  return (
    <BootstrapNavbar bg="light" expand="lg" className="mb-4" expanded={menuOpen}>
      <div className="container-fluid">        
        <BootstrapNavbar.Brand as={Link} to="/" className="text-white">ERP System - PROEDESA</BootstrapNavbar.Brand>
        
        <BootstrapNavbar.Toggle aria-controls="navbarNav" onClick={handleMenuClick} />
        <BootstrapNavbar.Collapse id="navbarNav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/" className={isActive("/") ? "active" : ""}  onClick={handleItemClick}>  
            <FaHome  className="icon-margin"/>Inicio
            </Nav.Link>
           
            <Nav.Link as={Link} to="/dashboard" className={isActive("/dashboard") ? "active" : ""}  onClick={handleItemClick}>
              Login
            </Nav.Link>
            {isLoggedIn && (
              <>
                <NavDropdown title={<><FaProjectDiagram className="icon-margin"/>Proyectos</>} id="projects-dropdown">                  
                  {userPrivileges?.includes("projects:write") && (
                    <NavDropdown.Item as={Link} to="/projects/add"  onClick={handleItemClick}>
                      Agregar Proyecto
                    </NavDropdown.Item>
                  )}
                  {userPrivileges?.includes("projects:write") && (
                    <NavDropdown.Item as={Link} to="/projects/edit" onClick={handleItemClick}>
                      Editar Proyecto
                    </NavDropdown.Item>
                  )}
                  {userPrivileges?.includes("projects:delete") && (
                    <NavDropdown.Item as={Link} to="/projects/delete"  onClick={handleItemClick}>
                      Eliminar Proyecto
                    </NavDropdown.Item>
                  )}
                </NavDropdown>
                {hasAccess(userPrivileges, rolePrivileges, "inquiries:read", activeModules, "purchasing") && (
                    
                  <NavDropdown title={<><FaShoppingCart className="icon-margin"/>Compras</>} id="purchase-dropdown">
                    {hasAccess(userPrivileges, rolePrivileges, "inquiries:approve", activeModules, "purchasing") && 
                      (
                        <NavDropdown.Item as={Link} to="/approvals"  onClick={handleItemClick}>
                          Aprobaciones
                        </NavDropdown.Item>
                      ) }

                    <NavDropdown.Item as={Link} to="/project-budget"  onClick={handleItemClick}>Presupuestos</NavDropdown.Item>
                    {hasAccess(userPrivileges, rolePrivileges, "inquiries:write_own", activeModules, "purchasing") && (
                      <NavDropdown.Item as={Link} to="/inquiries" onClick={handleItemClick}>
                        Requisiciones
                      </NavDropdown.Item>
                    )}
                    {hasAccess(userPrivileges, rolePrivileges, "po:write", activeModules, "purchasing") && (
                      <NavDropdown.Item as={Link} to="/pos" onClick={handleItemClick}>
                        Órdenes de Compra
                      </NavDropdown.Item>                
                    )}
                     {hasAccess(userPrivileges, rolePrivileges, "po:read", activeModules, "purchasing") && (
                      <NavDropdown.Item as={Link} to="/budget/report" onClick={handleItemClick}>
                        Reporte de presupuesto
                      </NavDropdown.Item>                
                    )}
                  </NavDropdown>
                )}
                
                {(hasAccess(userPrivileges, rolePrivileges, "banks:read", activeModules, "banks") || hasAccess(userPrivileges, rolePrivileges, "cashflow:approve", activeModules, "cashflow")) && (    
                <NavDropdown title={<><FaChartLine className="icon-margin"/> Finanzas</>} id="finance-dropdown">
                  {hasAccess(userPrivileges, rolePrivileges, "banks:read", activeModules, "banks" ) && (
                      <NavDropdown title={<><FaExchangeAlt />Bancos</>} id="banks-submenu" drop="end"  menuVariant="light" >
                      
                      <NavDropdown.Item as={Link} to="/bank-statement" onClick={handleItemClick}>
                        Movimientos
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/bank-reconciliation" onClick={handleItemClick}>
                        Conciliación
                      </NavDropdown.Item>
                      <NavDropdown.Item as={Link} to="/pattern-rules" onClick={handleItemClick}>
                        Reglas de asociación
                      </NavDropdown.Item>
                  </NavDropdown>
                  )}
                  {hasAccess(userPrivileges, rolePrivileges, "cashflow:read", activeModules, "cashflow") && (    
                    <NavDropdown title={<><FaCashRegister className="icon-margin"/>Flujo de Caja</>} id="cashflow-dropdown">                       
                      {hasAccess(userPrivileges, rolePrivileges, "cashflow:approve", activeModules, "cashflow") && (    
                        <NavDropdown.Item as={Link} to="/invoice-inbox" onClick={handleItemClick}>
                        Buzón de Facturas
                      </NavDropdown.Item>
                      )}
                    {hasAccess(userPrivileges, rolePrivileges, "cashflow:write_own", activeModules, "cashflow") && (
                    <NavDropdown.Item as={Link} to="/cashflow" onClick={handleItemClick}>
                      Entradas de Flujo de Caja
                    </NavDropdown.Item>  
                    )}
                    {hasAccess(userPrivileges, rolePrivileges, "cashflow:write", activeModules, "cashflow") && (                    
                      <NavDropdown.Item as={Link} to="/cashflow-dispersion" onClick={handleItemClick}>
                        Dispersión de Gastos
                      </NavDropdown.Item>
                    )}                               
                    {hasAccess(userPrivileges, rolePrivileges, "cashflow:write", activeModules, "cashflow") && (                    
                      <NavDropdown.Item as={Link} to="/cashflow-reports" onClick={handleItemClick}>
                        Reportes de Flujo de Caja
                      </NavDropdown.Item>
                    )}
                  </NavDropdown>
                  )}
                  {hasAccess(userPrivileges, rolePrivileges, "ap:read", activeModules, "accounts-payable") && (
                  <NavDropdown.Item className="fa-money-bill-wave" as={Link} to="/cashflow" onClick={handleItemClick}>
                    <FaMoneyBillWave className="icon-margin" /> Cuentas por pagar
                  </NavDropdown.Item>  
                  )}
                  </NavDropdown>                            
                )}
              { userPrivileges?.includes("catalogs:read") && (
                <NavDropdown title={<><FaThList className="icon-margin"/>Catalogos</>} id="catalogs-dropdown">
                    <NavDropdown.Item as={Link} to="/catalogs/vendors" onClick={handleItemClick}>
                      Proveedores
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/catalogs/customers" onClick={handleItemClick}>
                      Clientes
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/catalogs/accounts" onClick={handleItemClick}>
                      Cuentas
                    </NavDropdown.Item>
                </NavDropdown>
              )}
              { userPrivileges?.includes("reports:read") && ( 
                
                <NavDropdown title={<><FaChartLine className="icon-margin"/>Reportes</>} id="reports-dropdown">
                    <NavDropdown.Item as={Link} to="/reports/projects" onClick={handleItemClick}>
                      Proyectos
                    </NavDropdown.Item>                    
                    <NavDropdown.Item as={Link} to="/reports/cashflow" onClick={handleItemClick}>
                      Flujo de Efectivo
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/reports/pettyCash" onClick={handleItemClick}>
                      Caja Chica
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/reports/invoices" onClick={handleItemClick}>
                      Facturas
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/reports/budgets" onClick={handleItemClick}>
                      Presupuestos
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/reports/pos" onClick={handleItemClick}>
                      Ordenes
                    </NavDropdown.Item>
                    
                  </NavDropdown>
              )}

                <Nav.Link as={Link} to="/notifications">
                 <FaBell/> Notificaciones{" "}
                  {notificationsCount > 0 && (
                    <span className="badge bg-danger">{notificationsCount}</span>
                  )}
                </Nav.Link>
                { userPrivileges?.includes("system:read") && (                  
                  <NavDropdown title={<><FaCog className="icon-margin"/>Sistema</>} id="system-dropdown">                    
                      <NavDropdown.Item as={Link} to="/users" onClick={handleItemClick}>
                        Usuarios
                      </NavDropdown.Item>                       
                      <NavDropdown.Item as={Link} to="/system/import" onClick={handleItemClick}>
                        Importar
                      </NavDropdown.Item>   
                      <NavDropdown.Item as={Link} to="/system/config" onClick={handleItemClick}>
                        Configuración
                      </NavDropdown.Item>                  
                  </NavDropdown>
                )}
              </>
            )}

{isLoggedIn && (
  <NavDropdown title="Perfil" id="profile-dropdown">
    <NavDropdown.Item as={Link} to="/profile">
      Mi Perfil
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to="/logout">
      Cerrar Sesión
    </NavDropdown.Item>
  </NavDropdown>
)}

          </Nav>
        </BootstrapNavbar.Collapse>
      </div>
    </BootstrapNavbar>
  );
};

export default Navbar;
