import React, { useEffect, useState, useRef } from "react";
import { Container, Table, Spinner,Form, Button, Alert , OverlayTrigger, Tooltip } from "react-bootstrap";
import { getBanks, getBankTransactions } from "../services/api"; // Replace with your API functions
import {calculateDateRange} from "../utils/dateUtils";
import useIsMobile from "../hooks/useIsMobile";

const BankTransactionViewPage = () => {  
  const isMobile = useIsMobile(); // Use the custom hook
  const chartRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [banks, setBanks] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [selectedBankId, setSelectedBankId] = useState("");
  const [alert, setAlert] = useState(null);
  const [dateFilter, setDateFilter] = useState("currentMonth"); // Default filter type
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    concept: "",
    status_type: 'all',
  });

  const renderTooltip = (text) => (
    <Tooltip id={`tooltip-${text.toLowerCase()}`}>{text}</Tooltip>
  );

  useEffect(() => {
    const fetchBanks = async () => {
    
      setIsLoading(true);
      try {
        const banksData = await getBanks();
        setBanks(banksData.data || []);
      } catch (error) {
        console.error("Error fetching banks:", error);
        setAlert({ type: "danger", message: "Error al obtener los bancos." });
      }
      finally{
        setIsLoading(false);
      }
    };
    updateDateFilter(dateFilter);
    fetchBanks();
  }, []);

  const fetchTransactions = async () => {
    try {
      if (!selectedBankId) {
        setAlert({
          type: "warning",
          message: "Por favor, selecciona un banco.",
        });
        return;
      }

      const queryParams = {        
        company_id: 1,        
        start_date: filters.startDate,
        end_date: filters.endDate,        
        concept: filters.concept,        
        status: (filters.status_type === 'all')?null:filters.status_type ,  
      };
      setIsLoading(true);
      const transactionsData = await getBankTransactions(selectedBankId,queryParams);
      setTransactions(transactionsData?.data || []);
      setAlert(null);
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setAlert({
        type: "danger",
        message: "Error al obtener las transacciones.",
      });
    }
    finally{
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const updateCursor = () => {
      document.body.style.cursor = isLoading ? "progress" : "default";
    };
  
    updateCursor();
  
    // Cleanup when the component unmounts
    return () => {
      document.body.style.cursor = "default";
    };
  }, [isLoading]);

  const updateDateFilter = (filter) =>{
    setDateFilter(filter);
    const { start, end } = calculateDateRange(filter);    
    setFilters({...filters,startDate: start ,endDate:end});
  };


  const handleDateFilterChange = (e) => {
    const filter = e.target.value;
    setDateFilter(filter);

    const { start, end } = calculateDateRange(filter);    
    setFilters({...filters,startDate: start ,endDate:end});    
  };



  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Container className="mt-4">
      <h1>Transacciones Bancarias</h1>
      {alert && <Alert variant={alert.type}>{alert.message}</Alert>}

      {/* Filters */}
      <Form className="mb-4">
        <Form.Group className="mb-3">
          <Form.Label>Selecciona un Banco</Form.Label>
          <Form.Select
            value={selectedBankId}
            onChange={(e) => setSelectedBankId(e.target.value)}
          >
            <option value="">-- Seleccionar Banco --</option>
            {banks.map((bank) => (
              <option key={bank.source_id} value={bank.source_id}>
                {bank.source_name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Concepto</Form.Label>
          <Form.Control
            type="text"
            name="concept"
            placeholder="Buscar por concepto"
            value={filters.concept}
            onChange={handleFilterChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Filtrar por Fecha</Form.Label>
          <Form.Select value={dateFilter} onChange={handleDateFilterChange}>
            <option value="custom">Rango Personalizado</option>
            <option value="currentMonth">Mes Actual</option>
            <option value="lastMonth">Mes Anterior</option>
            <option value="thisYear">Este Año</option>
            <option value="lastYear">Año Anterior</option>
          </Form.Select>
        </Form.Group>
        {dateFilter === "custom" && (
          <>
                <Form.Group className="mb-3">
                <Form.Label>Fecha de Inicio</Form.Label>
                <Form.Control
                    type="date"
                    name="startDate"
                    value={filters.startDate}
                    onChange={handleFilterChange}
                />
                </Form.Group>

                <Form.Group className="mb-3">
                <Form.Label>Fecha de Fin</Form.Label>
                <Form.Control
                    type="date"
                    name="endDate"
                    value={filters.endDate}
                    onChange={handleFilterChange}
                />
                </Form.Group>
            </>
        )}
        <Form.Group className="mb-3">
        <Form.Label>Filtrar por Estado</Form.Label>
        <div>
           <Form.Check
            inline
            type="radio"
            id="status-checked"
            name="status_type"
            label="Todos"
            value='all'
            checked={filters.status_type === "all"}
            onChange={handleFilterChange}
            />
            <Form.Check
            inline
            type="radio"
            id="status-imported"
            name="status_type"
            label="Importado"
            value='imported'
            checked={filters.status_type === "imported"}
            onChange={handleFilterChange}
            />
            <Form.Check
            inline
            type="radio"
            id="status-pending"
            name="status_type"
            label="Pendiente"
            value='pending'
            checked={filters.status_type === "pending"}
            onChange={handleFilterChange}
            />
            <Form.Check
            inline
            type="radio"
            id="status-checked"
            name="status_type"
            label="Conciliado"
            value='checked'
            checked={filters.status_type === "checked"}
            onChange={handleFilterChange}
            />
        </div>
        </Form.Group>
        <Button variant="primary" onClick={fetchTransactions}>        
          {isLoading ? (
          <>
            <Spinner as="span" animation="border"  size="sm"  role="status"   aria-hidden="true"
            />{" "}
            Cargando...
          </>
          ) : (
            "Aplicar Filtros"
          )}
        </Button>
      </Form>

      {/* Transactions Table */}
      <h3>Listado de Transacciones</h3>
      <Table striped bordered hover id="bank-transactions-table">
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Concepto</th>
            <th>Emisor</th>
            <th>UUID</th>
            <th>Cargo</th>
            <th>Abono</th>
            <th>Subtotal</th>
            <th>IVA</th>
            <th>ISR</th>
            <th>Total</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {transactions.length > 0 ? (
            transactions.map((transaction) => (
              <tr key={transaction.transaction_id}>
                <td data-label="Fecha">{transaction.transaction_date}</td>
                <td data-label="Concepto">{transaction.concept}</td>
                <td data-label="Nombre">{transaction.name}</td>
                <td data-label="UUID">{transaction.invoice_details}</td>
                <td data-label="Monto"className="text-danger text-right">
                  {transaction.charge > 0
                    ? new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(transaction.charge)
                    : isMobile?"":"-"}
                </td>
                <td data-label="Monto" className="text-success">
                  {transaction.credit > 0
                    ? new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(transaction.credit)
                    : isMobile?"":"-"}
                </td>
                <td data-label="Subtotal">{transaction.subtotal > 0?new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(transaction.subtotal):""}</td>
                <td data-label="Iva">{transaction.iva_16 > 0?new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(transaction.iva_16 || 0):""}</td>
                <td data-label="ISR">{transaction.retenido_isr > 0?new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(transaction.retenido_isr):""}</td>
                <td data-label="Total">{transaction.total || ""}</td>
                <td data-label="Estatus" className="text-center">
                  {transaction.status === 'checked' ? (                    
                    <OverlayTrigger
                      placement="top"
                      overlay={renderTooltip("Conciliado")}
                    >
                    <span style={{ color: "green" }}>✔</span>
                    </OverlayTrigger>
                  ) : transaction.status === 'rejected' ? (
                    <OverlayTrigger
                      placement="top"
                      overlay={renderTooltip("Rechazado")}
                    >
                    <span style={{ color: "red" }}>✘</span>
                    </OverlayTrigger>
                  ) : transaction.status === 'imported' ? (
                    <OverlayTrigger
                      placement="top"
                      overlay={renderTooltip("Importado")}
                    >
                    
                    <span style={{ color: "blue" }}>📥</span>
                    </OverlayTrigger>
                  ) : (
                    <OverlayTrigger
                      placement="top"
                      overlay={renderTooltip("Pendiente")}
                    >
                    <span style={{ color: "orange" }}>🕒</span>
                    </OverlayTrigger>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="10" className="text-center">
                No hay transacciones disponibles.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
};

export default BankTransactionViewPage;
