export const translate = (message) => {
  const translationMap = {
    // Literal translation
    "You do not have permission to delete vendors": "No tienes los permisos necesarios para borrar un proveedor",
    // Regex translation (ensure the pattern matches your input; note the colon and quotes for this example)
    "Missing field: '(.*)'": "Campo faltante: '$1'",
    "Database query failed: \([0-9a-zA-Z]+, \"Duplicate entry '[0-9a-zA-Z]+' for key 'vendors\.vendor_number'\"\)":"Error: Llave duplicada",
    "The amount of the PO exceeds the approval limit":"El monto de la PO sobrepasa el límite de aprobación",
  };

  // Iterate over keys in the translationMap
  for (const pattern in translationMap) {
    try {
      // Create a regex from the pattern. Adjust flags if needed (e.g. "i" for case-insensitive)
      const regex = new RegExp(pattern);
      if (regex.test(message)) {
        // If the pattern matches, return the result of replacing using the regex.
        return message.replace(regex, translationMap[pattern]);
      }
    } catch (e) {
      console.error("Invalid regex pattern", pattern, e);
    }
  }
  
  // If no pattern matched, return the original message.
  return message;
};
