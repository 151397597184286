import React, { useState } from "react";
import { userLogin} from "../services/api";
import { useNotifications } from "../context/NotificationsContext";

const LoginPage = ({ handleLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { refetchNotifications } = useNotifications();  

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Mock login validation
    const response = await userLogin(username,password);
    if (response && response.data.userId) {      
      handleLogin(response.data.userId,response.data.userName,response.data.role_id);
      refetchNotifications();
    } else {
      alert("Usuario o contraseña incorrectos");
    }
  };

  return (
    <div className="container mt-5">
      <h2>Iniciar Sesión</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label>Usuario</label>
          <input
            type="text"
            className="form-control"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label>Contraseña</label>
          <input
            type="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Iniciar Sesión
        </button>
      </form>
    </div>
  );
};

export default LoginPage;
