import React, { useEffect, useState, useRef } from "react";
import { Table, Button, Form, Alert, Container, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faExclamationTriangle,faCheck,faTimes,faTrash,faPen } from "@fortawesome/free-solid-svg-icons";
import { useLocation , useNavigate} from "react-router-dom";
import { useAlert, showMessage } from "../components/Alerts";
import Pagination from "../components/Pagination";
import Select from "react-select";
import ScrollToTopButton from "../components/ScrollTop";
import { purDECIMALS,purTOLERANCE } from "../config/systemConfig";

import {
  getProjects,
  getBudget,
  getBudgetItems,
  getInquiries,
  addInquiry,
  approveInquiry,
  rejectInquiry,
  getUnits
} from "../services/api";

const InquiriesPage = ({userPrivileges,userId}) => {      
  const location = useLocation(); // Query parameters  
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [budgets, setBudgets] = useState([]);
  const [selectedBudgetId, setSelectedBudgetId] = useState(null);
  const [selectedInquiryId,setSelectedInquiryId] = useState(null)
  const [budgetItems, setBudgetItems] = useState([]);
  const [inquiryItems, setInquiryItems] = useState([]); // Existing inquiries
  const [isLoading,setIsLoading] = useState(false)
  const [units, setUnits] = useState([]);
  const [selectedFilterStatus,setSelectedFilterStatus] = useState(null);
  const [newInquiryItems, setNewInquiryItems] = useState([]); // New entries  
  const { alert, showAlert, clearAlert } = useAlert();
  const storedUserId = localStorage.getItem("userId");
  const [showNotes,setShowNotes] = useState ({});
  const quantityRefs = useRef([]);
  const [approvedInquiries,setApprovedInquiries] = useState([]);
  const queryParams = new URLSearchParams(location.search);
  const initialProjectId = queryParams.get("project_id");
  const initialBudgetId = queryParams.get("budget_id");
  const initialFetch = useRef(false);
  
  
  
  /*Pagination*/
  const [currentInqPage, setCurrentInqPage] = useState(1);
  const [totalInqPages, setTotalInqPages] = useState(1);
  

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const projectIdFromUrl = searchParams.get("project_id");
    const inquiriesIdFromUrl = searchParams.get("inquiries_id"); // or module_item_id if needed

    if (projectIdFromUrl) {
      setSelectedProjectId(parseInt(projectIdFromUrl));
    }
    if(inquiriesIdFromUrl){
      setSelectedInquiryId(parseInt(inquiriesIdFromUrl));
    }
    // If your logic requires setting a budget based on the URL, do it here as well:
    // const budgetIdFromUrl = searchParams.get("budget_id");
    // if (budgetIdFromUrl) setSelectedBudgetId(budgetIdFromUrl);

    // If you need to trigger a data fetch, you can call it here or let your useEffects do it
  }, [location.search]);


  

  const fetchInquiries = async(page,selectedFilter = null) => {
    const queryParams = {    
      "page":page,      
      budgetId: selectedBudgetId,
      project_id:selectedProjectId,
      inquiryId:selectedInquiryId,
      filter:selectedFilter,
    }
    try {
      const inquiriesData = await getInquiries(queryParams);
      setTotalInqPages(inquiriesData.pagination.total_pages);
      const statusOrder = ["Pendiente", "Autorizado", "Rechazado"];
        
        const validInquiries = inquiriesData.data.filter(item =>(item.status !== "Rechazado"));
        const sortedInquiries = validInquiries.sort((a, b) => {
          const statusComparison =  statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
          if (statusComparison !== 0) {
            return statusComparison; // If statuses are different, sort by status
          }
        
          // If statuses are the same, sort by ID
          return b.inquiry_id - a.inquiry_id ; 
        });
        
        setInquiryItems(sortedInquiries); // Existing inquiries

        const approvedInquiry = sortedInquiries.filter(
          (inq) => inq.status === "Autorizado"
        );
        setApprovedInquiries(approvedInquiry);
  
      }
        catch (error) {
        console.error("Error fetching pending approvals:", error);
        showAlert("Error al cargar las solicitudes pendientes.","danger");
        
      }   
  };

  const handleProjectChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedProjectId(selectedOption.value);
      setInquiryItems([])
      // Remove query parameters when a project is selected
      navigate("/inquiries", { replace: true });
    } else {
      setSelectedProjectId(null);
    }
  };
  
  const fetchBudgets = async () => {
    // If no project is selected, clear all related states
    if (!selectedProjectId) {
      setBudgets([]);
      setSelectedBudgetId(null);
      setBudgetItems([]);
      setInquiryItems([]);
      setNewInquiryItems([]);
      return;
    }
    
    // Otherwise, fetch budgets for the selected project
    const budgetData = await getBudget(selectedProjectId);
    setBudgets(budgetData.data);
    
    if (initialBudgetId) {
      setSelectedBudgetId(initialBudgetId);            
    } else {
      setSelectedBudgetId(null);
    }
    setBudgetItems([]);    
    if (!selectedInquiryId) {
      setInquiryItems([]);
     // fetchInquiries(1,2)
    }
    setNewInquiryItems([]);
  };

  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const unitsData = await getUnits(); // Call API to get units
        setUnits(unitsData.data); // Store units in state
      } catch (error) {
        console.error("Error fetching units:", error);
      }
    };

    fetchUnits();
  }, []);

  const toggleNotes = (index) => {
    setShowNotes((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle visibility for the specific row
    }));
  };

  // Fetch all projects on component load
  useEffect(() => {
    const fetchProjects = async () => {
      const projectsData = await getProjects({user_id:userId});
      setProjects(projectsData.data);
      if (initialProjectId) {
        setSelectedProjectId(initialProjectId);
      }
    };
    fetchProjects();
  }, [initialProjectId]);

  // Fetch budgets when a project is selected
  useEffect(() => {    
    fetchBudgets();
  }, [selectedProjectId,initialBudgetId]);

  
  useEffect(() => {
    if (!selectedProjectId || !selectedInquiryId) return; 
    
    if (!initialFetch.current) { // Run only on first load
      fetchInquiries(currentInqPage);
      initialFetch.current = true; // Mark as fetched
    }
  }, [currentInqPage, selectedProjectId, selectedInquiryId]);

  // Fetch budget items and inquiries when a budget is selected
  useEffect(() => {
    const fetchBudgetItemsAndInquiries = async () => {
      if (!selectedBudgetId) return;
      try {
        setIsLoading(true);
        const items = await getBudgetItems(selectedProjectId, selectedBudgetId, { noPagination: 1 });
        setBudgetItems(items.data);
        const inquiries = fetchInquiries(1);
        setNewInquiryItems([]); // Reset new items
      } catch (error) {
        console.error("Error fetching data:", error);
        showAlert("Error al obtener los datos del presupuesto y solicitudes.", "danger");
      } finally {
        setIsLoading(false);
      }
    };
    fetchBudgetItemsAndInquiries();
  }, [selectedBudgetId]);
  
  // Add a new empty row to the new inquiries table
  const handleAddRow = () => {
    setNewInquiryItems((prev) => [
      ...prev,
      { id: "", description: "", total_quantity: 0, unitCost: 0, total_cost: 0 ,  remaining_quantity: 0,notes:""},
    ]);
    quantityRefs.current.push(React.createRef());
    setShowNotes({});
  };

  // Handle changes in the new inquiry table rows
  const handleRowChange = (index, field, value,getFocus = false,remaining_quantity = 0) => {
    
    const updatedItems = [...newInquiryItems]; 
    const item = updatedItems[index];
          
      if (field === "item_id") {
        if (!value) {
          updatedItems[index] = {
            
            inquiry_id: null,
            description: "",
            unitCost: 0,
            costCode :  "",
            budget_cost: 0,
            item_code: "",
            notes: "",
            quantity: 0,
            priority: 2,
            initial_remaining_quantity: 0,
            inquiry_quantity:0,
            remaining_quantity: 0,
            total_quantity : 0,
            total_cost: 0,
          };
          setNewInquiryItems(updatedItems);
          return;
        }
    
      const selectedItem = budgetItems.find((item) => item.id === Number(value));
      if (selectedItem) {
        
        const selectedUnit = units.find((unit) => unit.unit_name === selectedItem.unitOfMeasure);
        if (selectedUnit) {
          const updatedRows = inquiryItems.map((row) => ({
            ...row,
            unitOfMeasure: selectedUnit.unit_id, // Set the value dynamically
          }));
          setInquiryItems(updatedRows);
        }

        const cumulativeQuantity = updatedItems.reduce((sum, currentItem, idx) => {
          // Compare each row's item_id to the item_id of the row at "index"
          if (currentItem.item_id === selectedItem.id) {
            return sum + currentItem.quantity;
          }
          return sum;
        }, 0);
    


      updatedItems[index] = {
        ...updatedItems[index],
        item_id: selectedItem.id,
        costCode: selectedItem.costCode,
        description: selectedItem.description,
        notes:selectedItem.notes,
        unitCost: selectedItem.unitCost,
        unitOfMeasure : selectedUnit? selectedUnit.unit_id : "",
        remaining_quantity: selectedItem.remaining_quantity - cumulativeQuantity ,
        inquiry_quantity:selectedItem.inquiryQuantity,
        initial_remaining_quantity: selectedItem.remaining_quantity ,
        costCode: selectedItem.costCode,
        quantity:  0,
        total_cost: selectedItem.unitCost * (updatedItems[index].quantity || 0),
      };
     
      const recalculatedUpdatedItems = updatedItems.map((item,idx) => {
        if(item.item_id === selectedItem.id &&  idx !== index) { 
           return { ...item, remaining_quantity: selectedItem.remaining_quantity }; 
        } 
        return item;
     });
      setNewInquiryItems(updatedItems);
      if (getFocus) setTimeout(() => quantityRefs.current[index]?.current?.focus(), 0);
    }
    } else if (field === "quantity") {
            
        const newQuantity = Number(value) || 0;
        const cumulativeQuantity = updatedItems.reduce((sum, currentItem,inx) => {
          if (
            currentItem.item_id === updatedItems[index].item_id  && index != inx
          ) {
            return sum + currentItem.quantity;
          }
          return sum;
        }, 0);        
        const initialAvailable = updatedItems[index].initial_remaining_quantity ;//|| remaining_quantity;
        const newRemaining = initialAvailable  - cumulativeQuantity  - newQuantity;
        updatedItems[index] = {
          ...updatedItems[index],
          quantity: Number(value),
          total_quantity: Number(value),
          unit_id :  updatedItems[index].unitOfMeasure,
          remaining_quantity: newRemaining ,
          total_cost: updatedItems[index].unitCost * Number(value),
        };
        
        const recalculatedUpdatedItems = updatedItems.map((item,idx) => {
          if(item.item_id === updatedItems[index].item_id &&  idx !== index) { 
             return { ...item, remaining_quantity: newRemaining}; 
          } 
          return item;
       });
        setNewInquiryItems(recalculatedUpdatedItems);
      
    }
    else {
      updatedItems[index] = {
        ...updatedItems[index],        
        [field]:value,        
      };
      setNewInquiryItems(updatedItems);
    }
    
  };

  // Handle form submission
  const handleSubmit = async () => {
    try {
      const invalidItems = newInquiryItems.filter((item) => item.total_quantity <= 0);

      if (invalidItems.length > 0) {
        showMessage("Error: Todas las cantidades deben ser mayores a 0", "error");
        return; // Stop execution if validation fails
      } 
    
      const inquiryResponse = await addInquiry(selectedProjectId, selectedBudgetId, storedUserId, newInquiryItems);      
      const updatedInquiryItems = newInquiryItems.map((item, index) => ({
        ...item,
        inquiry_id: inquiryResponse.inquiryId[index],
        inquiryQuantity:item.total_quantity,
        status:"Pendiente",        
        unit_id : item.unitOfMeasure
      }));
      setInquiryItems ((prev) => ([...updatedInquiryItems,...prev]));
      
      showAlert("Solicitud creada exitosamente.","success");             
      setNewInquiryItems([]);      
    } catch (error) {
      showAlert("Error al crear la solicitud.","danger");      
      console.error(error);
    }
  };
  
  const handleApproveInquiry = async (inquiryId) => {
    try {
      await approveInquiry(selectedProjectId,inquiryId, storedUserId); // Call API
      setInquiryItems((prevItems) =>
        prevItems.map((item) =>
          item.inquiry_id === inquiryId ? { ...item, status: "Autorizado" } : item
        )          
      );
      //setApprovedInquiries((prev) => ({...prev,inquiriesData})); 
      setApprovedInquiries(inquiryItems.filter((prev) => prev.status === "Autorizado"));
      await fetchBudgets();

      showAlert("Solicitud aprobada exitosamente.","success");      
    } catch (error) {
      console.error("Error al aprobar la solicitud:", error);
      showAlert("Error al aprobar la solicitud.","warning");      
    }
  };

  const handleRejectInquiry = async (inquiryId) => {
    try {
      await rejectInquiry(selectedProjectId,inquiryId, storedUserId); // Call API
      setInquiryItems((prevItems) =>
        prevItems.map((item) =>
          item.inquiry_id === inquiryId ? { ...item, status: "Rechazado" } : item
        )          
      );
      //setApprovedInquiries((prev) => ({...prev,inquiriesData})); 
      setApprovedInquiries(inquiryItems.filter((prev) => prev.status === "Rechazado"));
      

      showAlert("Solicitud rechazada exitosamente.","success");      
    } catch (error) {
      console.error("Error al rechazar la solicitud:", error);
      showAlert("Error al rechazar la solicitud.","warning");      
    }
  };

  const handleDeleteRow = (indexToDelete) => {
    setNewInquiryItems((prevItems) => prevItems.filter((_, index) => index !== indexToDelete));
  };
  


  const totalAmount = inquiryItems.reduce((sum, item) => sum + (item.unitCost * item.total_quantity), 0);  
  return (
    <Container className="mt-4">
      <h1>Gestión de Solicitudes</h1>


      {/* Select Project */}
      <div>
        <p>Selecciona una Obra:</p>
      <Select
          options={projects                        
            .map((project) => ({ value: project.id, label: project.name }))
          }
          value={projects.find((p) => p.id === selectedProjectId) 
            ? { value: selectedProjectId, label: projects.find((p) => p.id === selectedProjectId)?.name || "" } 
            : null} // Ensures correct value handling
          onChange={handleProjectChange}
          placeholder="Buscar Projecto..."
          className="fixed-autocomplete"
          menuPortalTarget={document.body} // Renders dropdown at root level
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensures dropdown is always on top
            menu: (base) => ({ ...base, zIndex: 9999 }) // Extra safeguard
          }}
          isClearable
          noOptionsMessage={() => "Sin presupuesto aprobado"}
        />
        </div>     


     

      {/* Select Budget */}
      {budgets.length > 0? (
        <Form.Group className="mt-3">
          <Form.Label>Selecciona un Presupuesto:</Form.Label>
          <Form.Select
            onChange={(e) => setSelectedBudgetId(e.target.value)}
            value={selectedBudgetId || ""}
          >
            <option value="">-- Seleccionar --</option>
            {budgets.map((budget) => (
              <option key={budget.budget_id} value={budget.budget_id}>
                {budget.budgetName} (Restante: ${Number(budget.remaining_budget).toLocaleString()})
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      ):<span>No hay presupuestos dispnibles</span>}

      {isLoading ? (
        <Spinner animation="border" style={{ marginTop: "20px" }}/>
      ) : (
        <>     
          {/* Existing Inquiries */}
          {inquiryItems.length > 0 && (
            <>
              <h3 className="mt-4">Solicitudes Existentes</h3>
              <Table className="compressedTable" striped bordered hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Código</th>
                    <th>Descripción</th>                    
                    <th>Cantidad</th>                    
                    <th>Unidad</th>
                    <th>Costo Unitario</th>
                    <th>Costo Total</th>
                    <th>Estatus</th>
                    <th>Acciones</th>
                   
                  </tr>
                </thead>
                <tbody>
                  {inquiryItems.map((item) => {                    
                     return(                    
                      <tr key={item.inquiry_id}>
                        
                        <td data-label="ID">{item.id}</td>
                        <td data-label="Código">{item.costCode}</td>
                        <td data-label="Descripción">
                          <>
                          <div>{item.description}<b>{item.category?"-":""}{item.category}</b></div>
                          {item.notes && <div style={{fontSize:"0.85em", color:"#666"}}>{item.notes}</div>}
                          </>
                        </td>
                        <td data-label="Cantidad" className={item.budgetQuantity < item.inquiryQuantity? "over-budget text-right" : "text-right"}>                          
                          <>
                          {new Intl.NumberFormat("es-MX", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(item.inquiryQuantity)}
                          { item.budgetQuantity < item.inquiryQuantity && <div style={{fontSize:"0.85em", color:"#666"}}>{item.budgetQuantity}</div>}
                          </>
                        </td>
                        <td data-label="Unidad">{units.find((prev) => prev.unit_id === item.unit_id)?.unit_name}</td>
                        <td data-label="Costo Unitario" className="text-right">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.unitCost)}</td>
                        <td data-label="Costo Total" className="text-right">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.inquiryQuantity * item.unitCost)}</td>                  
                        <td data-label="Estatus">{item.status}</td>
                        <td className="action-buttons">
                          {/* Show Approve button only if user has privileges and inquiry is pending */}
                          {item.status === "Pendiente" && userPrivileges?.includes("inquiries:write") && (
                            <>


                            {/* Approve PO Button */}                    
                            <Button
                              variant="success"
                              size="sm"
                              onClick={() => handleApproveInquiry(item.inquiry_id)}
                              className="me-1"
                              
                            >                      
                            <FontAwesomeIcon
                              icon={faCheck}
                              title="Aprobar"
                            />
                            </Button>

                            {/* Reject PO Button */}                    
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleRejectInquiry(item.inquiry_id)}
                              className="me-1"
                              
                            >                      
                            <FontAwesomeIcon
                              icon={faTimes}
                              title="Rechzar"
                            />
                            </Button>                            
                           </>
                          )}
                        </td>                          
                      </tr>
                     );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="6" className="text-end"><strong>Total:</strong></td>
                    <td className="text-right"><strong>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totalAmount)}</strong></td>                
                    <td colSpan="2"></td>
                  </tr>
                </tfoot>
              </Table>
              {/* Pagination Component */}
              <Pagination
              currentPage={currentInqPage}
              totalPages={totalInqPages}
              onPageChange={setCurrentInqPage}
            />
            </>
          )}
        </>
      )}
      {/* Add New Inquiries */}
      {selectedBudgetId && (
        <>
          <h3 className="mt-4">Agregar Nuevas Solicitudes</h3>
          <Button onClick={handleAddRow} className="mb-2">
            Añadir Fila
          </Button>
          {/* Table for New Items */}
          <Table bordered hover className="compressedTable" id="inq-approval-table">
            <thead>
              <tr>
                <th>Elemento</th>                
                <th>Descripción</th>                
                <th>Cantidad Restante</th>
                <th>Cantidad</th>
                <th>P</th>
                <th>Unidad</th>
                <th>Costo Unitario</th>
                <th>Costo Total</th>
                <th></th> 
              </tr>
            </thead>
            <tbody>
              {newInquiryItems.map((row, index) => {
                const isOverQuantity = row.remaining_quantity < 0;
               
                return (
                 <tr
                 key={index}
                 className={isOverQuantity ? "over-quantity" : ""} 
                 >                  
                  <td>
                  <div className="fixed-autocomplete" style={{ display: "flex", alignItems: "center" }}>
                    <Select
                      options={budgetItems.map((inq) => ({
                        value: inq.id,
                        description: inq.description,
                        category: inq.category, // keep this separate
                        costCode : inq.constCode,
                        label: inq.category ? `${inq.description}-${inq.category}` : inq.description
                      }))}
                      formatOptionLabel={(option) => (
                        <div>
                          {option.description}
                          {option.costCode}
                          {option.category && <>-<strong><small>{option.category}</small></strong></>}
                        </div>
                      )}
                      value={
                        (() => {
                          const selectedItem = budgetItems.find((inq) => inq.id === row.item_id);
                          return selectedItem
                            ? {
                                value: row.item_id,
                                costCode: selectedItem.costCode,
                                category: selectedItem.category, 
                                label: selectedItem.category 
                                ? (`${selectedItem.costCode} -  ${selectedItem.category}`) 
                                : (selectedItem.costCode)
                              }
                            : null;
                        })()
                      }
                      onChange={(selectedOption) => {
                        if (!selectedOption) {
                          // Clear the selection when x is pressed
                          handleRowChange(index, "item_id", null, true);
                        } else {
                          // Update with the selected value
                          handleRowChange(index, "item_id", selectedOption.value, true);
                        }
                      }}
                      placeholder="Buscar Requisición..."
                      className="fixed-autocomplete form-control-sm" 
                      menuPortalTarget={document.body} // Renders dropdown at root level
                     
                      isClearable                      
                      styles={{                        
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        menu: (base) => ({ ...base, zIndex: 9999 })
                      }}
                    />
                    </div>                   
                  </td>                 
                  <td className="pur-description" ><div>{row.item_id && (<FontAwesomeIcon style={{marginLeft:"5px", cursor: "pointer",float: "right" }} icon={faPen} title="Notas" onClick= {()=>toggleNotes(index)}/>)}{row.description}</div>
                  
                  { showNotes[index] && (                  
                    <Form.Control                                                                
                      type="text"
                      min={0}
                      
                      placeholder="Notas"
                      className="notes-field"
                      value={row.notes}                     
                      onFocus={(e) => e.target.select()} // Select the value when focused                      
                      onChange={(e) => {                        
                        handleRowChange(index, "notes", e.target.value, false, row.notes);
                      }}
                    />
                  )}
                  </td>              
                  <td data-label="Disponibles"  className="text-right">{new Intl.NumberFormat('es-MX', {minimumFractionDigits: purDECIMALS, maximumFractionDigits: purDECIMALS}).format(!isNaN(parseFloat(row.remaining_quantity))? parseFloat(row.remaining_quantity): 0)}</td>                  
                  <td className="text-right">
                    <Form.Control                    
                      
                      ref={quantityRefs.current[index]} 
                      type="number"
                      min={0}
                      value={row.quantity}
                      onFocus={(e) => e.target.select()} // Select the value when focused                      
                      onChange={(e) => {
                        // Convert the input value to a number
                        const newValue = parseFloat(e.target.value);
                        // Enforce a minimum of 0
                        const validatedValue = newValue < 0 ? 0 : newValue;
                        // Pass the validated value to your handler
                        handleRowChange(index, "quantity", validatedValue, false, row.remaining_quantity);
                      }}
                    />
                  </td>
                  <td data-label="Prioridad">
                    <select
                      value={row.priority ?? 2}
                      onChange={(e) => handleRowChange(index, "priority",e.target.value)}
                    >
                      {[1, 2, 3].map((priority) => (
                        <option key={priority} value={priority}>
                          {priority}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td  data-label="Unidad" className="text-right">
                    {units.find((item) => item.unit_id === row.unitOfMeasure)?.unit_name}                    
                  </td>                  
                  <td data-label="C/U"  className="text-right">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(row.unitCost)}</td>
                  <td data-label="Total" className="text-right">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(!isNaN(row.total_cost)?row.total_cost:0)}</td>
                  <td className="text-center">
                      {isOverQuantity && (<FontAwesomeIcon icon={faExclamationTriangle}
                          color="red"
                          title="La cantidad solicitada excede la cantidad disponible"
                        />
                      )}
                  
                    <Button variant="danger" size="xs" className = "xsmall-icon" onClick={() => handleDeleteRow(index)} title="Eliminar">
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                  
                </tr>
                )}
              )}
            </tbody>           
          </Table>
          <Button disabled={newInquiryItems.length <= 0} variant="success" onClick={handleSubmit}>
            Enviar Solicitudes
          </Button>
        </>        
      )}      
      
      {/* Alert */}
      {alert.message && (
        <Alert variant={alert.variant} onClose={clearAlert} dismissible>
          {alert.message}
        </Alert>
      )}
      <ScrollToTopButton />
  
    </Container>
  );
};

export default InquiriesPage;
