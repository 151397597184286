import React, { useEffect, useState } from "react";
import { Table, Button, Form, Alert } from "react-bootstrap";
import { getPermissions } from "../services/api";
//assignPermission, removePermission
const PermissionsPage = ({ userId }) => {
  const [permissions, setPermissions] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const [allPermissions, assignedPermissions] = await Promise.all([
          getPermissions(),
        //  getUserPrivileges(userId),
        ]);
        setPermissions(allPermissions);
       // setUserPermissions(assignedPermissions);
      } catch (err) {
        setError("Error al cargar permisos");
      }
    };

    fetchPermissions();
  }, [userId]);

  const handleTogglePermission = async (permissionId, isAssigned) => {
    try {
      if (isAssigned) {
      //  await removePermission(userId, permissionId);
        setUserPermissions((prev) =>
          prev.filter((perm) => perm.permission_id !== permissionId)
        );
      } else {
        //await assignPermission(userId, permissionId);
        setUserPermissions((prev) => [...prev, { permission_id: permissionId }]);
      }
    } catch (err) {
      setError("Error al actualizar permisos");
    }
  };

  return (
    <div className="container">
      <h2 className="mt-4">Gestionar Permisos</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Módulo</th>
              <th>Nivel de Permiso</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {permissions.map((permission) => {
              const isAssigned = userPermissions.some(
                (perm) => perm.permission_id === permission.permission_id
              );
              return (
                <tr key={permission.permission_id}>
                  <td>{permission.module_name}</td>
                  <td>{permission.permission_level}</td>
                  <td>
                    <Button
                      variant={isAssigned ? "danger" : "success"}
                      onClick={() => handleTogglePermission(permission.permission_id, isAssigned)}
                    >
                      {isAssigned ? "Eliminar" : "Asignar"}
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        
    </div>
  );
};

export default PermissionsPage;
